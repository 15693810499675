import React, { useState } from 'react'
import { Box, Checkbox, TableCell, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import { BodyCheckbox } from '../BodyCheckbox/BodyCheckbox'
import SummaryStatisticsDealersStoreInstance from '../../store/SummaryStatisticsDealersStore'
import ParamsFilterStore from '../../store/ParamsFilterStore'
import { getComp } from '../../../../utils/utils/DI'

export const BodyTableCell = observer(({ column, children, row }) => {
    const { propName, label } = column
    const { activeToggleBlockItem, setDealerName, openRequest, closeRequest, ExpiredFrom, ExpiredTo } =
        SummaryStatisticsDealersStoreInstance
    const { todayParams, periodParams, totalParams } = ParamsFilterStore
    const dealersStore = getComp('DealersStore')
    const statusStore = getComp('StatusesStore')
    const dealerName = dealersStore.getDealer(row.dealerId)?.displayName
    const link = ['expiredAssigns', 'expiredInstalls'].includes(propName) && children !== 0
    const statusesToday = propName === 'expiredAssigns' ? [2, 4, 5] : [6, 12, 13, 14, 15, 16]
    const statusesPeriod = propName === 'expiredAssigns' ? ['all'] : [6, 12, 13, 14, 15, 16]

    const expiredAssignsTooltip = row.expiredAssignsTooltip
    const expiredInstallsTooltip = row.expiredInstallsTooltip

    const [isHovered, setIsHovered] = useState(false)

    const handleMouseEnter = () => {
        setIsHovered(true)
    }

    const handleMouseLeave = () => {
        setIsHovered(false)
    }

    const handleClick = (row) => {
        setDealerName(dealerName)

        switch (activeToggleBlockItem) {
            case 'today':
                todayParams(row.dealerId, statusesToday)
                openRequest()
                break
            case 'period':
                periodParams(
                    row.dealerId,
                    propName === 'expiredAssigns',
                    propName === 'expiredInstalls',
                    ExpiredFrom,
                    ExpiredTo,
                    statusesPeriod
                )
                openRequest()
                break
            case 'total':
                totalParams(row.dealerId, propName === 'expiredAssigns', propName === 'expiredInstalls', statusesPeriod)

                openRequest()
                break
            default:
                closeRequest()
        }
    }

    return propName === 'checkbox' ? (
        <BodyCheckbox key={propName} propName={propName} row={row} />
    ) : (
        <TableCell
            key={propName}
            sx={{
                color: link ? 'rgba(22, 104, 185, 1)' : 'rgba(0, 0, 0, 1)',
                textAlign: label === 'Исполнитель' ? 'left' : 'center',
                position: 'relative',
            }}
        >
            <Typography
                onClick={link ? () => handleClick(row) : null}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                sx={{
                    cursor: link ? 'pointer' : 'default',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '19,2px',
                    '&:hover': {
                        color: link && '#ea2020',
                    },
                }}
            >
                {children}
            </Typography>
            {isHovered && children !== 0 && ['expiredAssigns', 'expiredInstalls'].includes(propName) && (
                <Box
                    sx={{
                        background: '#fff',
                        position: 'absolute',
                        left: '50%',
                        top: '-80%',
                        transform: 'translateX(-50%)',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        borderRadius: '4px',
                        padding: '8px',
                        zIndex: 10,
                        color: '#000',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '16.8px',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <Typography
                        sx={{ fontSize: '14px', fontWeight: '500', lineHeight: '16.8px', whiteSpace: 'nowrap' }}
                    >
                        Текущее состояние заявок:
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '4px' }}>
                        {propName === 'expiredAssigns' &&
                            expiredAssignsTooltip.map((el) => (
                                <Box key={el.status} sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                    <img
                                        src={statusStore.getStatusImage(el.status)}
                                        alt=''
                                        style={{ width: '16px', height: '16px' }}
                                    />
                                    <Typography>{el.count}</Typography>
                                </Box>
                            ))}
                        {propName === 'expiredInstalls' &&
                            expiredInstallsTooltip.map((el) => (
                                <Box key={el.status} sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                    <img
                                        src={statusStore.getStatusImage(el.status)}
                                        alt=''
                                        style={{ width: '16px', height: '16px' }}
                                    />
                                    <Typography>{el.count}</Typography>
                                </Box>
                            ))}
                    </Box>
                </Box>
            )}
        </TableCell>
    )
})
