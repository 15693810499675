import React from "react"
import {
    Page,
    Text,
    View,
    StyleSheet,
    Font,
} from "@react-pdf/renderer"
import PTRootUIBold from "../../../../assets/fonts/Arimo/Arimo-Bold.ttf"
import PTRootUI from "../../../../assets/fonts/Arimo/Arimo-Regular.ttf"
import {formatName} from "src/helpers/ordersUtiles"
import {agreementPDFStyles} from "src/views/Agreements/components/AgreementPDF/agreementPDFStyles"
import {ParagraphZero} from "src/views/Agreements/components/AgreementPDF/components/ParagraphZero"
import {FirstParagraph} from "src/views/Agreements/components/AgreementPDF/components/FirstParagraph"
import {ParagraphOne} from "src/views/Agreements/components/AgreementPDF/components/ParagraphOne"
import {ParagraphTwo} from "src/views/Agreements/components/AgreementPDF/components/ParagraphTwo"
import {ParagraphThree} from "src/views/Agreements/components/AgreementPDF/components/ParagraphThree"
import {ParagraphFour} from "src/views/Agreements/components/AgreementPDF/components/ParagraphFour"
import {ParagraphFive} from "src/views/Agreements/components/AgreementPDF/components/ParagraphFive"
import {ParagraphSix} from "src/views/Agreements/components/AgreementPDF/components/ParagraphSix"
import {Underline} from "src/views/Agreements/components/AgreementPDF/components/Underline"

Font.register({
    family: "PT Root UI",
    fonts: [
        {src: PTRootUI},
        {src: PTRootUIBold, fontWeight: 700}
    ]
})


const styles = StyleSheet.create(agreementPDFStyles())

const AgreementPDF = ({order,data}) => {
    const {id,equipmentId} = order

    return (
        <Page size="A4" style={styles.page}>
            <View style={styles.idBlock}>
                <Text style={[styles.textBold,styles.text]}>
                    Заявка №{id}
                </Text>
            </View>
            <View style={styles.header}>
                <Text style={styles.title}>
                    {"АБОНЕНТСКИЙ ДОГОВОР № "}
                    <Text style={styles.textUnderline}>
                        {data.title}_{equipmentId}
                    </Text>
                </Text>
            </View>
            <View style={styles.subtitleContainer}>
                <Text style={styles.text}>
                    г. Луганск
                </Text>
                <View style={styles.withUnderlineContainer}>
                    <Text style={styles.text}>«</Text>
                    <Underline width={"10px"}/>
                    <Text style={styles.text}>»</Text>
                    <Underline width={"50px"}/>
                    <Text style={styles.text}>
                        202
                    </Text>
                    <Underline width={"6px"}/>
                    <Text style={styles.text}>
                        г.
                    </Text>
                </View>
            </View>
            <FirstParagraph
                operatorName={data.operatorName}
                styles={styles}
                name={formatName(order)}
            />
            <ParagraphZero
                styles={styles}
            />
            <ParagraphOne
                styles={styles}
            />
            <ParagraphTwo
                styles={styles}
            />
            <ParagraphThree
                styles={styles}
            />
            <ParagraphFour
                styles={styles}
            />
            <ParagraphFive
                styles={styles}
            />
            <ParagraphSix
                operatorName={data.footerName}
                operatorPosition={data.operatorPosition}
                styles={styles}
                order={order}
            />
        </Page>
    )
}

export default AgreementPDF