import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { SelectAllTransferList } from './components/SelectAllTransferList/SelectAllTransferList'
import { Box } from '@mui/material'
import TransactionConfirmation from 'src/components/TransactionConfirmation/TransactionConfirmation'
import {store} from './store/EditorStore'

export const Editor = observer(() => {
    useEffect(() => {
        store.resetStore() 
    },[])
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <SelectAllTransferList />
            <TransactionConfirmation />
        </Box>
    )
})
