import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Box, Paper, Portal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { HeadTableCell } from './HeadTableCell/HeadTableCell'
import { BodyTableCell } from './BodyTableCell/BodyTableCell'
import { PageSwitch } from './PageSwitch/PageSwitch'
import { SumStatistics } from './SumStatistics/SumStatistics'
import { TablePagination } from './TablePagination/TablePagination'
import summaryStatisticsDealersStoreInstance from '../store/SummaryStatisticsDealersStore'
import { getComp } from '../../../utils/utils/DI'
import { FormattedMessage } from 'react-intl'
import { IntervalPanel } from './IntervalPanel/IntervalPanel'
import { TableBodyRow } from './TableBodyRow/TableBodyRow'
import useScrollToggle from 'src/hooks/useScrollToggle'

export const TableSummaryStatisticsDealers = observer(() => {
    const { isLoading, rows, activeToggleBlockItem, subpage } = summaryStatisticsDealersStoreInstance
    const columns = [
        { propName: 'checkbox', label: 'checkbox' },
        { propName: 'dealerId', label: 'Исполнитель' },
        { propName: 'assigns', label: 'Назначено' },
        { propName: 'expiredAssigns', label: 'Просрочено' },
        { propName: 'installs', label: 'Установлено' },
        { propName: 'expiredInstalls', label: 'Из них просрочено' },
        { propName: 'improvement', label: 'На Доработку' },
        { propName: 'duplicates', label: 'В Дубликат' },
        { propName: 'impossible', label: 'В Невозможно' },
        { propName: 'averageProcessingTimeHours', label: 'Среднее время установки' },

    ]
    const scroll = useScrollToggle(300,500)

    return (
        <Box>
            {/* <Portal> */}

            <TableContainer sx={{  
                    opacity: scroll ? 1 : 0,
                    height: scroll ? 'auto' : 0,
                    visibility: scroll ? 'visible' : 'hidden',
                    transition: 'opacity 0.3s ease-in-out',
                    borderRadius: '0px',
                    padding: '12px',
                    paddingTop: '0',
                    paddingBottom: '0',
                    position: 'sticky',
                    top: '38px',
                    zIndex: 100,
                 }} component={Paper}>
                <IntervalPanel />
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => {
                                const { propName, label } = column
                                return <HeadTableCell key={propName}>{label}</HeadTableCell>
                            })}
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
                            {/* </Portal> */}
            <TableContainer sx={{ padding: '12px' }} component={Paper}>
                <IntervalPanel />
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => {
                                const { propName, label } = column
                                return <HeadTableCell key={propName}>{label}</HeadTableCell>
                            })}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {rows.map((row, index) => (
                            <TableBodyRow key={row.dealerId} row={row} columns={columns} index={index} />
                        ))}
                        {!!rows.length && <SumStatistics columns={columns} />}
                    </TableBody>
                </Table>
                {false && <TablePagination size={[10, 20, 50]} />}

                {isLoading && (
                    <Box className={'jst-center'} width={1} p={4.5} sx={{ background: 'rgba(217, 217, 217, 0.20)' }}>
                        <Typography sx={{ fontWeight: '500', color: '#A9A9A9', fontSize: '13px' }}>
                            {rows.length ||
                                (isLoading ? (
                                    <FormattedMessage id={'загрузка..'} />
                                ) : (
                                    <FormattedMessage id={'нет данных для отображения'} />
                                ))}
                        </Typography>
                    </Box>
                )}
            </TableContainer>
        </Box>
    )
})
