import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Checkbox, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { store } from '../../store/EditorStore'

export const ListItem = observer(({ value, labelId, white }) => {
    const { toggleCheck, isWhitelistedItems, whiteList } = store
    useEffect(() => {
        isWhitelistedItems()
    }, [whiteList])
    return (
        <ListItemButton
            disabled={value.isWhitelisted}
            key={value}
            role='listitem'
            onClick={() => toggleCheck(white, value.Id)}
        >
            <ListItemIcon>
                <Checkbox
                    checked={value.checked}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                        'aria-labelledby': labelId,
                    }}
                />
            </ListItemIcon>
            <ListItemText id={labelId} primary={`${value.Name}`} />
        </ListItemButton>
    )
})
