import React from "react"
import {ToastContainer} from "react-toastify"

const MyToastifyContainer = () => {
    return (
        <ToastContainer
            position="bottom-left"
            style={{
                width: "fit-content",
                maxWidth: "624px",
            }}
        />
    )
}

export default MyToastifyContainer
