import {Text,View} from "@react-pdf/renderer"
import React from "react"

export function ParagraphFive({styles}){

    return(
        <View style={styles.paragraph}>
            <Text style={styles.title}>
                5. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ.
            </Text>
            <Text style={styles.text}>
                5.1. За ненадлежащее исполнение Сторонами своих обязательств по Договору, Стороны несут ответственность в соответствии с действующим законодательством Российской Федерации и Договором.
            </Text>
            <Text style={styles.text}>
                5.2. В процессе заключения Договора Абонент обязуется предоставить Оператору следующие достоверные (документально подтвержденные) сведения, необходимые для обеспечения правомочности оказания Услуг, а также технического и сервисного сопровождения Абонента в течение всего периода оказания Услуг: фамилия, имя, отчество; дата рождения; наименование и реквизиты документа, удостоверяющего личность; адрес установки Приёмного оборудования, находящийся в пределах Территории; адрес места регистрации, находящийся в пределах Территории; действующие контактные номера телефонов (по которым Оператор будет иметь возможность оперативно связаться с Абонентом); e-mail.
            </Text>
            <Text style={styles.text}>
                5.3. Заключая настоящий Договор, Абонент дает свое согласие:
            </Text>
            <Text style={styles.text}>
                5.3.1 на обработку Оператором персональных данных, указанных при заключении Договора (п. 5.2 настоящего Договора), включая сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, распространение (в том числе передачу), обезличивание, блокирование, уничтожение персональных данных Абонента с использованием средств автоматизации или без использования таковых, в целях оказания Услуг и продвижения их на рынке, в том числе при сотрудничестве с третьими лицами. Согласие предоставляется в целях заключения и исполнения Договора, в том числе для целей сервисного и справочно-информационного обслуживания Абонента, оказания ему Услуг, формирования специальных предложений, проведения маркетинговых и рекламных активностей, в том числе при сотрудничестве с третьими лицами, взыскания дебиторской задолженности, переписки и взаимодействия с Абонентом.            </Text>
            <Text style={styles.text}>
                5.3.2 на передачу Оператором персональных данных Абонента третьим лицам (в том числе Дилеру), в целях  надлежащего оказания Услуг, формирования специальных предложений, проведения маркетинговых и рекламных активностей, а также по запросу органов государственной власти.
            </Text>
            <Text style={styles.text}>
                5.3.3 на передачу результатов его взаимодействия с Оператором (в том числе аудиозаписей, текстовых записей, разговоров, переписки в любом виде) третьим лицам, которые привлекаются Оператором к оказанию Услуг, а также органам государственной власти по их запросу.
            </Text>
            <Text style={styles.text}>
                5.3.4 на получение рекламной информации, в том числе в процессе оказания Услуг.
            </Text>
            <Text style={styles.text}>
                5.3.5 на проведение опросов (телефонных, фокус-групп и т. д.) об условиях оказания Абоненту Услуг, в том числе опросы могут проводиться Дилером или третьими лицами, привлеченными Оператором.
                Указанные согласия Абонента действуют в течение всего срока действия Договора или до отзыва Абонентом данного согласия в порядке, установленном действующим законодательством.
            </Text>
            <Text style={styles.text}>
                5.4. Заключая настоящий Договор, Абонент подтверждает, что до подписания Договора ознакомился с его текстом, в том числе с текстом Правил оказания услуг, что размещены на Сайте, с Тарифами на Услуги, и выражает безусловное согласие с его условиями и обязуется эти условия выполнять, а также не заблуждается в понимании условий Договора, мотивы и последствия заключения Договора ему понятны.
            </Text>
        </View>
    )
}