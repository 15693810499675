import { gState } from '../../../utils/controllers/GlobalStateController'
import CustomTable from '../../../utils/components/CustomTable/CustomTable'
import withWidth from '@mui/material/Hidden/withWidth'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import statisticsSubsidiesStore from '../stores/StatisticsSubsidiesStore'
import TableSortIcon from '../../../components/customElements/TableSortIcon/TableSortIcon'
function StatisticsSubsidiesTable() {
    const intl = gState['intl']
    const { rows, setRows, isLoading, getRows } = statisticsSubsidiesStore
    const [revers, setRevers] = useState([false, false, false])
    const [revers1, setRevers1] = useState([false, false, false])
    useEffect(() => {
        getRows()
    }, [])

    const columns = [
        {
            propName: 'territory',
            headerName: intl.formatMessage({ id: 'Регион' }),
            renderCellValue: (params) => params.territory,
            justifyContent: 'flex-start',
            textAlign: 'left',
            headerStart: true,
            fontWeight: 500,
        },
        {
            propName: 'district',
            headerName: intl.formatMessage({ id: 'Округ' }),
            minWidth: '100px',
            renderCellValue: (params) => params.district,
            justifyContent: 'flex-start',
            textAlign: 'left',
            headerStart: true,
            fontWeight: 500,
        },
        {
            propName: 'locality',
            headerName: intl.formatMessage({ id: 'Населенный пункт' }),
            minWidth: '100px',
            renderCellValue: (params) => params.locality,
            justifyContent: 'flex-start',
            headerStart: true,
            fontWeight: 500,
        },
        {
            propName: 'author',
            headerName: intl.formatMessage({ id: 'Автор' }),
            renderCellValue: (params) => params.author,
            justifyContent: 'flex-start',
            headerStart: true,
            fontWeight: 500,
            textAlign: 'left',
        },
        {
            propName: 'financialSources',
            headerName: intl.formatMessage({ id: 'Полная дотация' }),
            minWidth: '100px',
            handleClick: () => handleSort(rows, 1, revers[1]),
            icon: <TableSortIcon isReverse={revers[1]} />,
            renderCellValue: (params) => params.financialSources.find((el) => el.id === 1).count,
            fontWeight: 500,
        },
        {
            propName: 'confirmedFinancialSources',
            headerName: (
                <div>
                    Подтверждена
                    <br />
                    Полная дотация
                </div>
            ),
            minWidth: '100px',
            handleClick: () => handleSortConfirm(rows, 1, revers1[1]),
            icon: <TableSortIcon isReverse={revers1[1]} />,
            renderCellValue: (params) => params.confirmedFinancialSources.find((el) => el.id === 1).count,
            fontWeight: 500,
        },
        {
            propName: 'financialSources',
            headerName: intl.formatMessage({ id: 'Частичная дотация' }),
            minWidth: '100px',
            handleClick: () => handleSort(rows, 2, revers[2]),
            icon: <TableSortIcon isReverse={revers[2]} />,
            renderCellValue: (params) => params.financialSources.find((el) => el.id === 2).count,
            fontWeight: 500,
        },
        {
            propName: 'confirmedFinancialSources',
            headerName: (
                <div>
                    Подтверждена
                    <br />
                    Частичная дотация
                </div>
            ),
            minWidth: '100px',
            handleClick: () => handleSortConfirm(rows, 2, revers1[2]),
            icon: <TableSortIcon isReverse={revers1[2]} />,
            renderCellValue: (params) => params.confirmedFinancialSources.find((el) => el.id === 2).count,
            fontWeight: 500,
        },
        {
            propName: 'financialSources',
            headerName: intl.formatMessage({ id: 'За счёт Абонента' }),
            minWidth: '100px',
            handleClick: () => handleSort(rows, 3, revers[3]),
            icon: <TableSortIcon isReverse={revers[3]} />,
            renderCellValue: (params) => params.financialSources.find((el) => el.id === 3).count,
            fontWeight: 500,
        },
        {
            propName: 'confirmedFinancialSources',
            headerName: (
                <div>
                    Подтверждена
                    <br />
                    За счёт Абонента
                </div>
            ),
            minWidth: '100px',
            handleClick: () => handleSortConfirm(rows, 3, revers1[3]),
            icon: <TableSortIcon isReverse={revers1[3]} />,
            renderCellValue: (params) =>  params.confirmedFinancialSources.find((el) => el.id === 3).count,
            fontWeight: 500,
        },
    ]

    const handleSort = (rows, index, direction) => {
        setRows(sortRows(rows, index, direction))
        setRevers((prevRevers) => {
            const newRevers = [...prevRevers]
            newRevers[index] = !newRevers[index]
            return newRevers
        })
    }
    const sortRows = (rows, sourceIndex, direction) => {
        return [...rows].sort((a, b) => {
            const aValue = a.financialSources.find((el) => el.id === sourceIndex).count
            const bValue = b.financialSources.find((el) => el.id === sourceIndex).count

            if (aValue < bValue) {
                return direction ? 1 : -1
            }
            if (aValue > bValue) {
                return direction ? -1 : 1
            }
            return 0
        })
    }

    const handleSortConfirm = (rows, index, direction) => {
        setRows(sortRowsConfirm(rows, index, direction))
        setRevers1((prevRevers) => {
            const newRevers = [...prevRevers]
            newRevers[index] = !newRevers[index]
            return newRevers
        })
    }
    const sortRowsConfirm = (rows, sourceIndex, direction) => {
        return [...rows].sort((a, b) => {
            const aValue = a.confirmedFinancialSources.find((el) => el.id === sourceIndex).count
            const bValue = b.confirmedFinancialSources.find((el) => el.id === sourceIndex).count

            if (aValue < bValue) {
                return direction ? 1 : -1
            }
            if (aValue > bValue) {
                return direction ? -1 : 1
            }
            return 0
        })
    }

    return (
        <CustomTable
            rows={rows}
            columns={columns}
            showPagination={false}
            total={rows.length}
            isLoading={isLoading}
            handleSettings={false}
            showAcceptBulkMobileBtn={true}
        />
    )
}
export default withWidth()(observer(StatisticsSubsidiesTable))
