import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import calendar from "../../assets/images/layout/calendar.svg"
import { Typography } from "@mui/material"
import moment from "moment"
import { getStyles } from "../../utils/utils/commonUtils"
import dateAndTimeStyles from "./styles/dateAndTimeStyles"
import { css } from "@emotion/css"

const DateAndTime = () => {
    const classes = getStyles(dateAndTimeStyles)
    const [currentMinutes, setCurrentMinutes] = useState(moment().format("mm"))
    const [currentHours, setCurrentHours] = useState(moment().format("HH"))

    useEffect(() => {
        const time = setInterval(() => {
            if (moment().format("mm") !== currentMinutes) {
                setCurrentMinutes(moment().format("mm"))
                setCurrentHours(moment().format("HH"))
            }
        }, 1000)

        return () => {
            clearInterval(time)
        }
    }, [])

    const getWeekDay = () => {
        let weekDay = moment().locale("ru").format("dd")
        weekDay = weekDay.split("")
        const firstLetter = weekDay[0].toUpperCase()
        return firstLetter + weekDay[1]
    }

    return (
        <Box sx={classes.wrapper}>
            <Typography sx={classes.text}>{getWeekDay()}</Typography>
            <Typography sx={classes.text}>{moment().format("DD.MM.YYYY")}</Typography>
            <Typography sx={{ ...classes.text, marginLeft: "10px" }}>
                {currentHours}
                <span className={css(classes.colon)}>:</span>
                {currentMinutes}
            </Typography>
        </Box>
    )
}

export default DateAndTime
