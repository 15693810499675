import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import Box from '@mui/material/Box'
import MainMenu from '../MainMenu/MainMenu'
import Appbar from '../Appbar/Appbar'
import withWidth, { isWidthUp } from '@mui/material/Hidden/withWidth'
import AddRequestDrawer from '../AddRequestDrawer/AddRequestDrawer'
import { disposeModal, isModalOpen, regModal } from '../../utils/controllers/ModalController'
import RequestDetailsDrawer from '../../views/RequestsManagement/components/RequestDetailsDrawer/RequestDetailsDrawer'
import ProgressBackdrop from '../ProgressBackdrop/ProgressBackdrop'
import PageFooter from '../customElements/PageFooter'
import PhotoGalleryDialog from '../PhotoGalleryDialog/PhotoGalleryDialog'
import arrow from '../../assets/images/common/arrow-down-custom.svg'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material'
import CustomTooltip from 'src/components/CustomTooltip/CustomTooltip'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import {
    isAdmin,
    isDealer,
    isDealerOperator,
    isOperator,
    isDataCustomizer,
    isAdministration,
    isLooker,
    isDashBoardAvailable,
} from 'src/services/AccountService'
import { gState, setGState } from 'src/utils/controllers/GlobalStateController'
import { when } from 'mobx'
import { getComp } from 'src/utils/utils/DI'
import { useAuth } from 'src/services/AuthProvider'
import { getUserRoles } from 'src/services/ApiService'
import PrivateContent from 'src/components/PrivateContent'
import { getStyles } from 'src/utils/utils/commonUtils'
import layoutStyles from 'src/components/Layout/styles/layoutStyles'
import classNames from 'classnames'
import commonStyles from '../../styles/commonStyles'
import stackActionsSelectStyles from '../../utils/components/StackActions/stackActionsSelectStyles'
import TransactionConfirmation from '../TransactionConfirmation/TransactionConfirmation'
import { isAdminLabelAA } from '../../services/AccountService'

function Layout() {
    const classes = { ...getStyles(layoutStyles), ...getStyles(commonStyles), ...getStyles(stackActionsSelectStyles) }
    const { logout } = useAuth()
    const intl = useIntl()
    const navigate = useNavigate()
    const statusStore = getComp('StatusesStore')
    const routesData = [
        {
            route: '/additional-summary',
            visible: isDashBoardAvailable,
            name: intl.formatMessage({ id: 'Инфографика' }),
        },
        {
            route: '/requests',
            visible: () => true,
            name: intl.formatMessage({ id: 'Заявки на установку' }),
        },

        {
            route: '/reports',
            visible: () => isAdmin() || isDealer() || isDealerOperator(),
            name: isAdmin() ? intl.formatMessage({ id: 'Проверка отчётов' }) : intl.formatMessage({ id: 'Отчеты' }),
        },
        {
            route: '/service',
            visible: () => !isLooker() && !isOperator(),
            name: intl.formatMessage({ id: 'Заявки на обслуживание' }),
        },
        // {
        //     route: '/add-requests',
        //     visible: () => !isAdmin() && !isLooker(),
        //     name: !isAdmin() ? intl.formatMessage({ id: 'Загрузка файла' }) : '',
        // },
        // {
        //     route: '/add-dealer-requests',
        //     visible: () => !isAdmin() && !isLooker(),
        //     name: !isAdmin() ? intl.formatMessage({ id: 'Загрузка файла дилера' }) : '',
        // },
        {
            route: '/data-settings',
            visible: isDataCustomizer,
            name: intl.formatMessage({ id: 'Ввод оперативных данных' }),
        },
        {
            route: '/statistics',
            visible: isAdmin,
            name: intl.formatMessage({ id: 'Статистика заявок по добавлению' }),
        },
        {
            route: '/operational-summary',
            visible: ()=> isAdminLabelAA(),
            name: intl.formatMessage({ id: 'Статистика статусов заявок' }),
        },
        {
            route: '/installation-summary',
            visible: isAdmin,
            name: intl.formatMessage({ id: 'Статистика установок по регионам' }),
        },
        // {
        //     route: '/created-summary',
        //     visible: isAdmin,
        //     name: intl.formatMessage({ id: 'Статистика заявок по регионам' }), // попросили удалить, но возможно пригодиться в будущем
        // },
        {
            route: '/reports-summary',
            visible: isAdmin,
            name: intl.formatMessage({ id: 'Статистика по отчётам дилеров' }),
        },
        {
            route: '/agreements',
            visible: () => (isDataCustomizer() || isDealer()) && !isLooker(),
            name: intl.formatMessage({ id: 'Подтверждение договоров' }),
        },
        {
            route: '/test',
            visible: () => false,
            name: intl.formatMessage({ id: 'Cтраница для тестов' }),
        },
        {
            route: '/finances',
            visible: () => false,
            name: intl.formatMessage({ id: 'Сводная статистика по дотациям' }),
        },
        {
            route: '/finances-summary',
            visible: () => isDataCustomizer(),
            name: intl.formatMessage({ id: 'Статистика установок по дотациям' }),
        },

        {
            route: '/activity',
            visible: () => isDataCustomizer(),
            name: intl.formatMessage({ id: 'Cтатистика активности' }),
        },
        {
            route: '/summary-statistics-dealers',
            visible: () => isDataCustomizer(),
            name: intl.formatMessage({ id: 'Сводная статистика установок по дилерам' }),
        },
        {
            route: '/editor',
            visible: () => isAdminLabelAA(),
            name: intl.formatMessage({ id: 'Редактор дотационных населенных пунктов' }),
        },

        {
            onClick: logout,
            visible: () => true,
            name: intl.formatMessage({ id: 'Выход' }),
        },
    ]

    useEffect(() => {
        const loadAction = () => {
            if (isAdmin() || isAdministration()) {
                getComp('DealersStore').load()
            }
            if (isAdmin()) {
                getComp('AuthorsStore').load()
                getComp('SourcesStore').load()
            }
            if (isDealer()) {
                getComp('DealerContractStore').reLoad()
            }
            getComp('TerritoryStore').load()
            getComp('TerritoryStoreOld').load()
            getComp('SendersStore').load()
            getComp('CategoriesStore').load()
            getComp('InstallationReasonStore').load()
            getComp('PhotoSettingsStore').load()
            getComp('ProjectVersionStore').load()
            getComp('SourcesStore').load()
            getComp('UserInfoStore').load()
        }

        getUserRoles()
            .then((result) => {
                setGState('roles', result)
                loadAction()
            })
            .catch(() => {
                navigate('/login')
            })
        setGState('navigate', navigate)
    }, [])

    useEffect(() => {
        if (gState['roles']) {
            const link = document.getElementById('favicon')
            if (isAdmin()) {
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    link.href = '/adminsDarkFavicon.svg'
                    return
                }
                link.href = '/adminsFavicon.svg'
            } else {
                link.href = '/favicon.png'
            }
        }
    }, [gState['roles']])
    const [showTopBtn, setShowTopBtn] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const modals = ['add-request', 'request-details', 'photo-gallery', 'custom-tooltip', 'transaction-confirmation']
    useEffect(() => {
        modals.forEach(regModal)
        return () => {
            modals.forEach(disposeModal)
        }
    }, [])

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 250) {
                setShowTopBtn(true)
            } else {
                setShowTopBtn(false)
            }
        }
        window.addEventListener('scroll', onScroll)

        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [])
    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }
    const closeMenu = () => {
        setMenuOpen(false)
    }

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }
    useEffect(() => {
        statusStore.getFinancialSourceBack()
    }, [])

    return gState['roles'] ? (
        <Box>
            <MainMenu open={menuOpen} toggleMenu={closeMenu} routesData={routesData} />
            <Appbar toggleMenu={toggleMenu} routesData={routesData} />
            <Box sx={classes.contentContainer}>
                <PrivateContent />
            </Box>
            <PageFooter />
            <AddRequestDrawer />
            <PhotoGalleryDialog />
            <ProgressBackdrop />
            <CustomTooltip />
            <Button
                onClick={goToTop}
                color={'primary'}
                variant={'contained'}
                sx={{
                    ...classes.topButton,
                    ...classes.buttonActive,
                    ...(showTopBtn ? classes.topButtonShow : {}),
                    ...(isDealer() ? classes.hideOnMobile : {}),
                }}
            >
                <img draggable='false' src={arrow} alt={''} style={classes.topButtonImage} />
            </Button>
        </Box>
    ) : (
        <></>
    )
}

export default withWidth()(observer(Layout))
