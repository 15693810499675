import React from "react"
import {BrowserRouter, Route, Routes} from "react-router-dom"
import PublicContent from "src/components/PublicContent"
import Layout from "src/components/Layout/Layout"
import Login from "src/views/Login/Login"
import {AuthProvider} from "src/services/AuthProvider"
import {ProtectedRoute} from "src/hocs/ProtectedRoute"
import {regModal} from "src/utils/controllers/ModalController"


function App() {

    regModal("progress-backdrop")
    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route path={"/login"} element={
                        <PublicContent>
                            <Login/>
                        </PublicContent>
                    }/>
                    <Route path={"/*"} element={<Layout/>}/>
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    )
}

export default App