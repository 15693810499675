import React from 'react'
import RightDrawer from '../../../components/customElements/RightDrawer/RightDrawer'
import { observer } from 'mobx-react'
import { FormattedMessage } from 'react-intl'
import { Box } from '@mui/system'
import { FormControl, MenuItem, Select } from '@mui/material'
import { getStyles } from '../../../utils/utils/commonUtils'
import dataSettingsStyles from '../styles/dataSettingsStyles'
import CountAccordion from './drawerComponents/CountAccordion'
import InitialCountAccordion from './drawerComponents/InitialCountAccordion'

const DataSettingsDrawer = ({ store }) => {
    const { isDrawerOpen, closeDrawer, showInitialCount, fullfinanceSource, showCount, drawerTitle } = store
    const classes = getStyles(dataSettingsStyles)

    const title = drawerTitle ? (
        drawerTitle
    ) : showInitialCount ? (
        store.getCurrentInitialCountInfo().count ? (
            <FormattedMessage
                id={
                    fullfinanceSource
                        ? 'Редактирование входящего остатка, полная дотация'
                        : 'Редактирование входящего остатка, частичная дотация'
                }
            />
        ) : (
            <FormattedMessage
                id={
                    fullfinanceSource
                        ? 'Ввод входящего остатка, полная дотация'
                        : 'Ввод входящего остатка, частичная дотация'
                }
            />
        )
    ) : (
        <FormattedMessage
            id={
                fullfinanceSource
                    ? 'Редактирование оперативных данных, полная дотация'
                    : 'Редактирование оперативных данных, частичная дотация'
            }
        />
    )

    return (
        <RightDrawer open={isDrawerOpen} onClose={closeDrawer} title={title}>
            <Box sx={classes.drawerContent}>
                <FormControl sx={{ width: '100%', padding: '20px' }}>
                    <Select disabled value={store.currentDealerName}>
                        <MenuItem value={store.currentDealerName}>{store.currentDealerName}</MenuItem>
                    </Select>
                </FormControl>
                <CountAccordion store={store} />
                <InitialCountAccordion store={store} />
            </Box>
        </RightDrawer>
    )
}

export default observer(DataSettingsDrawer)
