import React, {useEffect, useState} from "react"
import {observer} from "mobx-react"
import AgreementsStore from "src/views/Agreements/stores/AgreementsStore"
import RequestsTable from "src/components/RequestsTable/RequestsTable"
import {Box} from "@mui/system"
import NewRequestFilters from "src/views/RequestsManagement/components/NewRequestFilters/NewRequestFilters"
import {useIntl} from "react-intl"
import printIcon from "../../assets/images/common/print-agreement.svg"
import printIconGray from "../../assets/images/common/print-agreement-gray.svg"
import {pdf} from "@react-pdf/renderer"
import {isAdmin, isDealer} from "src/services/AccountService"
import RequestDetailsDrawer from "src/views/RequestsManagement/components/RequestDetailsDrawer/RequestDetailsDrawer"
import {isModalOpen} from "src/utils/controllers/ModalController"
import AgreementPDFConstructor from "src/views/Agreements/components/AgreementPDF/AgreementPDFConstructor"

function Agreements() {
    const [agreementsStore] = useState(() => new AgreementsStore())
    const {ordersStore, selectOrdersStore,filterStore,createAgreements,
        openDetailsDrawer, printAgreementCallback} = agreementsStore
    const intl = useIntl()

    const region = ["Херсонская область (ХО)", "Запорожская область (ЗО)", "ДНР", "ЛНР"];
    const generatePDFDocument = (orders,fileName) => {
        
        return pdf(
            <AgreementPDFConstructor orders={orders} fileName={fileName} callback={printAgreementCallback}/>
        ).toBlob()
    }
    useEffect(() => {
        ordersStore.load()
    }, [])
    useEffect(() => {
        selectOrdersStore.clearSelectOrders()
    }, [ordersStore.collection])
    return(
        <>
            <NewRequestFilters
                filtersStore={filterStore}
                ordersStore={ordersStore}
                totalCollectionLength={ordersStore.total}
                isAgreement={true}
                actionButtonProps={isDealer() && {
                    name: intl.formatMessage({id: "Сформировать договоры"}),
                    onClick: () => createAgreements(generatePDFDocument),
                    icon: selectOrdersStore.isOrdersSelected ? printIcon : printIconGray,
                    disabled: !selectOrdersStore.isOrdersSelected
                }}
            />

            <Box name={"table"} mt={2}>
                <RequestsTable
                    store={ordersStore}
                    selectOrdersStore={selectOrdersStore}
                    filterStore={filterStore}
                    rowsPerPageOptions={[50,100]}
                    isAgreement={true}
                    doublePagination={true}
                    onRowClick={isAdmin() && openDetailsDrawer}
                />
            </Box>
            {
                isModalOpen["request-details"] ?
                    <RequestDetailsDrawer
                        filterStore={filterStore}
                        ordersStore={ordersStore}
                        isAgreement={true}
                    /> : null
            }
        </>
    )
}

export default observer(Agreements)