const carouselStyles = (theme) => ({
    wrapper: {
        display: 'flex',
        height: '100%',
        gap: '32px',
        width: '100%',
        overflow: 'hidden',
        [theme.breakpoints.down(1200)]: {
            flexDirection: 'column-reverse',
        },
    },
    slide: {
        width: '80%',
        height: '100%',
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        alignItems: 'center',
        background: '#EAEAEA',
        borderRadius: '16px',
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.15)',
        border: '1px solid #E0DFDF',
        flexGrow: 1,
        boxSizing: 'borderBox',
        [theme.breakpoints.down(1200)]: {
            width: '100%',
        },
    },
    indicatorsWrapper: {
        paddingLeft: '8px',
        paddingRight: '24px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        // overflow: "scroll",
        overflow: 'auto',
        minWidth: '168px',
        height: '100%',
        [theme.breakpoints.down(1200)]: {
            flexDirection: 'row',
            height: '20%',
            overflowY: 'hidden',
        },
    },

    indicatorsImg: {
        cursor: 'pointer',
        width: '128px',
        minHeight: '170px',
        objectFit: 'cover',
    },
    slideImage: {
        objectFit: 'contain',
        width: '100%',
        height: ' 100%',
        transition: 'transform 600ms',
    },
})

export default carouselStyles
