import React from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import {FormattedMessage} from "react-intl"
import {useTheme} from "@mui/material"
import moment from "moment"
import withWidth, {isWidthUp} from "@mui/material/Hidden/withWidth"

function PageFooter({width}) {
    const theme = useTheme()
    return isWidthUp("sm", width) ?
        <Box component={"footer"} width={1} px={4} className={"footer jst-sb aln-center"}>
            <Typography variant={"caption"} color={theme.palette.text.graySemidark}>
                <FormattedMessage id={"Все права защищены"}/> © 2003 - {`${moment().format("YYYY")}`}
            </Typography>

            <Typography variant={"caption"} color={theme.palette.text.graySemidark}>
                <FormattedMessage id={"Автоматизированная система управления подписками"}/> SAT2022
            </Typography>
        </Box> : <></>
}

export default withWidth()(PageFooter)