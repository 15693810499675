import {Box, Typography} from "@mui/material"
import React from "react"
import {useIntl} from "react-intl"
import {gState} from "../../utils/controllers/GlobalStateController"
import {useEffect} from "react"
import {getUserName} from "../../services/ApiService"
import {useState} from "react"
import {getComp} from "src/utils/utils/DI"
import {observer} from "mobx-react"


const AppbarUserInfo = () => {
    const intl = useIntl()
    const [name, setName] = useState("")

    useEffect(() => {
        getUserName().then((res) => {
            if (res.displayName) {
                setName(res.displayName)
            }
        })
    }, [])

    const getRoleToShow = () => {
        switch (true) {
            case gState.roles.includes("superadmin"):
                return intl.formatMessage({id: "Администратор"})
            case gState.roles.includes("manager"):
                return intl.formatMessage({id: "Сотрудник офиса"})
            case gState.roles.includes("dealer"):
                return intl.formatMessage({id: "Дилер"})
            case gState.roles.includes("callcenter"):
                return intl.formatMessage({id: "Оператор"})
            case gState.roles.includes("looker"):
                return intl.formatMessage({id: "Наблюдатель"})
            case gState.roles.includes("administration"):
                return intl.formatMessage({id: "Администрация"})
            case gState.roles.includes("installer"):
                return intl.formatMessage({id: "Установщик"})
            default:
                return null
        }
    }
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: "10px",
                    fontWeight: "400",
                    lineHeight: "10px",
                    textTransform: "uppercase"
                }}
            >
                {getRoleToShow()}
            </Typography>
            <Typography sx={{fontSize: "14px", fontWeight: "700", lineHeight: "15px"}}>{name}</Typography>
        </Box>
    )
}

export default observer(AppbarUserInfo)
