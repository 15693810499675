import {Text,View} from "@react-pdf/renderer"
import React from "react"

export function ParagraphThree({styles}){

    return(
        <View style={styles.paragraph}>
            <Text style={styles.title}>
                3. УСЛОВИЯ ОКАЗАНИЯ УСЛУГ.
            </Text>
            <Text style={styles.text}>
                3.1. Оказание Услуг Абоненту производится при наличии технической возможности у Оператора оказывать Абоненту Услуги, а также при наличии у Абонента исправного активированного Приёмного оборудования, соответствующего техническим условиям вещания Оператора.
            </Text>
            <Text style={styles.text}>
                3.2. В силу постоянного совершенствования технологий оказания Услуг Абонент соглашается с правом Оператора  в любое время полностью или частично изменять условия, применяемые к порядку оказания Услуг, прекращать или приостанавливать оказание Услуг, по своему усмотрению и/или в связи с изменением или прекращением договорных отношений с правообладателями теле-, радиоканалов или по требованию последних прекращать, приостанавливать вещание теле-, радиоканалов, заменять одни телеканалы и/или радиоканалы другими, а также изменять действующие Тарифы и/или вводить новые Тарифы.
            </Text>
            <Text style={styles.text}>
                3.3. Оказание Услуг может быть приостановлено по инициативе каждой из Сторон в случаях и порядке, предусмотренных настоящим Договором (в том числе Правилами оказания услуг), а также действующим законодательством Российской Федерации.
            </Text>
            <Text style={styles.text}>
                3.4. Абонент вправе отказаться от дальнейшего исполнения Договора в целом, произведя все расчеты с Оператором и направив Оператору письменное заявление о расторжении Договора. Договор будет считаться расторгнутым по истечении 3 (трех) рабочих дней с момента получения Оператором оригинала заявления о расторжении Договора.
            </Text>
            <Text style={styles.text}>
                3.5. Оператор вправе в одностороннем порядке отказаться от исполнения Договора (в этом случае Договор считается расторгнутым) без предварительного уведомления или какой-либо компенсации в случаях, указанных в Правилах оказания Услуг.
            </Text>
        </View>
    )
}