import AgreementPDF from "src/views/Agreements/components/AgreementPDF/AgreementPDF"
import {
    Document
} from "@react-pdf/renderer"
import React from "react"
import InventoryPDF from "src/views/Agreements/components/AgreementPDF/InventoryPDF/InventoryPDF"
import { notifyError } from "../../../../services/ToastifyService/ToastifyService"

function AgreementPDFConstructor({orders, callback,fileName}) {
    const patternData = {
        "Херсонская область (ХО)": {
            title: "Х",
            operatorName: "менеджера Акимова А.М.",
            footerName: "А.М. Акимов",
            operatorPosition: "Менеджер",
        },
        "Запорожская область (ЗО)": {
            title: "З",
            operatorName: "менеджера Акимова А.М.",
            footerName: "А.М. Акимов",
            operatorPosition: "Менеджер",
        },
        "ДНР": {
            title: "Д",
            operatorName: "специалиста Базан А.О.",
            footerName: "А.О. Базан",
            operatorPosition: "Специалист",
        },
        "ЛНР": {
            title: "Л",
            operatorName: "заместителя исполнительного директора Гришана Д.Н.",
            footerName: "Д.Н. Гришан",
            operatorPosition: "Заместитель исполнительного директора",
        }
    }
    let notValidOrders = []
    let validOrders = []
    orders.forEach((order, index) => {
        const data = patternData[order.territory]
        if (data) {
            validOrders.push(order)
        } else {
            notValidOrders.push(order)
        }
        if (validOrders.length && index === orders.length - 1) {
            callback(validOrders.map(el => el.id))
        }
    })


    if(notValidOrders.length > 0){
        validOrders.push(orders[0])
        callback(validOrders.map(el => el.id))
        return notifyError(`В выбранных заявках неправильное значение региона`)
    }

    return <Document>
        <InventoryPDF orders={validOrders} fileName={fileName}/>
        {validOrders.map((order) => {
            return <AgreementPDF order={order} data={patternData[order.territory]}/>
        })}
    </Document>
}

export default AgreementPDFConstructor