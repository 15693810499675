import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { observer } from 'mobx-react'
import RequestsManagement from '../views/RequestsManagement/RequestsManagement'
import {
    isAdmin,
    isDealer,
    isDealerOperator,
    isDataCustomizer,
    isLooker,
    isAdminLabelAA,
} from '../services/AccountService'

import Statistics from '../views/Statistics/Statistics'
import Reports from '../views/Reports/Reports'
import OperationalSummary from '../views/OperationalSummary/OperationalSummary'
import InstallationSummary from '../views/InstallationSummary/InstallationSummary'

import { useIntl } from 'react-intl'
import AdditionalSummary from '../views/AdditionalSummary/AdditionalSummary'
import ReportsSummery from '../views/ReportsSummery/ReportsSummery'
import DataSettings from '../views/DataSettings/DataSettings'
import Agreements from 'src/views/Agreements/Agreements'
import Service from '../views/Service/Service'
import NotFound from '../views/NotFound/NotFound'

import StatisticsSubsidies from '../views/StatisticsSubsidies/StatisticsSubsidies'
import StatisticsActivity from '../views/StatisticsActivity/StatisticsActivity'
import { SummaryStatisticsDealers } from '../views/SummaryStatisticsDealers/SummaryStatisticsDealers'

import { StatisticsInstallationsSubsidies } from '../views/StatisticsInstallationsSubsidies/StatisticsInstallationsSubsidies'
import { Editor } from '../views/Management/Editor'

const PrivateContent = () => {
    const intl = useIntl()
    const routesData = [
        {
            component: <DataSettings />,
            path: '/data-settings',
            visible: isDataCustomizer,
            pageTitle: intl.formatMessage({ id: 'Ввод оперативных данных' }),
        },
        {
            component: <AdditionalSummary />,
            path: '/additional-summary',
            visible: isAdmin,
            pageTitle: intl.formatMessage({ id: 'Инфографика' }),
        },
        {
            component: <Service />,
            path: '/service',
            visible: () => !isLooker(),
            pageTitle: intl.formatMessage({ id: 'Заявки на обслуживание' }),
        },
        {
            component: <RequestsManagement />,
            path: '/requests',
            visible: () => true,
            pageTitle: intl.formatMessage({ id: 'Управление заявками' }),
        },
        {
            component: <Reports />,
            path: '/reports/*',
            visible: () => isAdmin() || isDealer() || isDealerOperator(),
            pageTitle: isAdmin()
                ? intl.formatMessage({ id: 'Проверка отчётов' })
                : intl.formatMessage({ id: 'Отчеты' }),
        },
        // {
        //     component: <AddRequests />,
        //     path: '/add-requests',
        //     visible: () => !isAdmin(),
        //     pageTitle: intl.formatMessage({ id: 'Загрузка файла' }),
        // },
        // {
        //     component: <AddRequests dealer />,
        //     path: '/add-dealer-requests',
        //     visible: () => !isAdmin(),
        //     pageTitle: intl.formatMessage({ id: 'Загрузка файла дилера' }),
        // },
        {
            component: <Statistics />,
            path: '/statistics',
            visible: isAdmin,
            pageTitle: intl.formatMessage({ id: 'Статистика заявок по добавлению' }),
        },
        {
            component: <OperationalSummary />,
            path: '/operational-summary',
            visible: () => isAdminLabelAA(),
            pageTitle: intl.formatMessage({ id: 'Статистика статусов заявок' }),
        },
        {
            component: <InstallationSummary />,
            path: '/installation-summary',
            visible: isAdmin,
            pageTitle: intl.formatMessage({ id: 'Статистика установок по регионам' }),
        },
        // {
        //     component: <CreatedSummary />,
        //     path: '/created-summary',
        //     visible: isAdmin,
        //     pageTitle: intl.formatMessage({ id: 'Статистика заявок по регионам' }),
        // },
        {
            component: <ReportsSummery />,
            path: '/reports-summary',
            visible: isAdmin,
            pageTitle: intl.formatMessage({ id: 'Статистика по отчётам дилеров' }),
        },
        {
            component: <Agreements />,
            path: '/agreements',
            visible: () => isAdmin() || isDealer(),
            pageTitle: intl.formatMessage({ id: 'Статистика по отчётам дилеров' }),
        },
        // {
        //     component: <TestView />,
        //     path: '/test',
        //     visible: () => false,
        //     pageTitle: intl.formatMessage({ id: 'Cтраница для тестов' }),
        // },
        {
            path: '/',
            component: <Navigate to={'/requests'} />,
            visible: () => true,
        },
        {
            component: <StatisticsSubsidies />,
            path: '/finances',
            visible: () => false,
            pageTitle: intl.formatMessage({ id: 'Сводная статистика по дотациям' }),
        },
        {
            component: <StatisticsInstallationsSubsidies />,
            path: '/finances-summary',
            visible: () => isDataCustomizer(),
            pageTitle: intl.formatMessage({ id: 'Статистика установок по дотациям' }),
        },
        {
            component: <StatisticsActivity />,
            path: '/activity',
            visible: () => isDataCustomizer(),
            pageTitle: intl.formatMessage({ id: 'Cтатистика активности' }),
        },
        {
            component: <SummaryStatisticsDealers />,
            path: '/summary-statistics-dealers',
            visible: () => isDataCustomizer(),
            pageTitle: intl.formatMessage({ id: 'Сводная статистика установок по дилерам' }),
        },
        {
            component: <Editor />,
            path: '/editor',
            visible: () => isAdminLabelAA(),
            pageTitle: intl.formatMessage({ id: 'Редактор дотационных населенных пунктов' }),
        },
        {
            path: '*',
            component: <NotFound />,
            visible: () => true,
        },
    ]
    const setContent = ({ component, path, visible }) => {
        return visible() ? <Route exact path={path} element={component} /> : <></>
    }
    return <Routes>{routesData.map((data) => setContent(data))}</Routes>
}

export default observer(PrivateContent)
