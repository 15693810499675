import React from 'react'
import { List, Paper, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import { store } from '../../store/EditorStore'
import { ListItem } from '../ListItem/ListItem'
import { HeaderList } from '../HeaderList/HeaderList'

export const CustomList = observer(({ title, white = false }) => {
    const { whiteList, allList } = store
    const items = white ? whiteList : allList

    const selectedItems = items.filter((item) => item.checked)
    return (
        <Paper sx={{ width: 400, overflow: 'auto' }}>
            <HeaderList all={items.length} available={items.filter((item) => !item.isWhitelisted).length}  selected={selectedItems.length} white={white} items={items}>{title}</HeaderList>
            
            <List dense component='div' role='list' sx={{ height: '610px', overflow: 'auto' }}>
                {items?.map((value) => {
                    const labelId = `transfer-list-item-${value}-label`
                    return <ListItem key={value} role='listitem' value={value} labelId={labelId} white={white} />
                })}
            </List>
        </Paper>
    )
})
