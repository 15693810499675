import React, { useEffect, useState } from 'react'
import { closeModalFn, isModalOpen, modalData, openModalFn } from '../../../../utils/controllers/ModalController'
import RightDrawer from '../../../../components/customElements/RightDrawer/RightDrawer'
import { Box, Button, IconButton, Typography, css } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import StatusInfoAndEdit from './DrawersCommonComponents/StatusInfoAndEdit'
import AssignedDealer from '../../../../components/customElements/RequestDataAccordions/AssignedDealer'
import { isAdmin } from '../../../../services/AccountService'
import EquipmentDataAccordion from './DrawersCommonComponents/EquipmentDataAccordion'
import InformationAccordion from './DrawersCommonComponents/InformationAccordion'
import SubscriberData from './DrawersCommonComponents/SubscriberData'
import AcceptBtn from './DrawersCommonComponents/Buttons/AcceptBtn'
import DuplicateBtn from './DrawersCommonComponents/Buttons/DuplicateBtn'
import { inject, observer } from 'mobx-react'
import StartServiceBtn from './DrawersCommonComponents/Buttons/StartServiceBtn'
import ProblemSolvedRemotely from './DrawersCommonComponents/Buttons/ProblemSolvedRemotely'
import ReturnForRevisionBtn from './DrawersCommonComponents/Buttons/ReturnForRevisionBtn'
import ServiceIsNotPossible from './DrawersCommonComponents/Buttons/ServiceIsNotPossible'
import OnSiteRepairBtn from './DrawersCommonComponents/Buttons/OnSiteRepairBtn'
import TransferToServiceCenterBtn from './DrawersCommonComponents/Buttons/TransferToServiceCenterBtn'
import CommentsAccordion from './DrawersCommonComponents/CommentsAccordion'
import CustomAccordion from '../../../../components/customElements/RequestDataAccordions/utils/CustomAccordion'
import FileInput from '../../../../components/customElements/FileInput/FileInput'
import FormInput from '../../../../components/customElements/FormInput'
import crossGrayIcon from '../../../../assets/images/common/cross_gray.svg'
import { notifyError } from '../../../../services/ToastifyService/ToastifyService'
import AddPhotosBlock from './DrawersCommonComponents/AddPhotosBlock'
const IntermediateDrawer = ({ serviceStore }) => {
    const intl = useIntl()
    const {
        currentServiceData,
        intermediateDrawerFormStore,
        solvewithoutvisitFunc,
        improvementRequiredFunc,
        serviceImpossibleFunc,
        markServiceOrderAsDuplicateFunc,
        uploadServicePhotoSettings,
        guaranteeRepairFunc,
        notGuaranteeRepairFunc,
    } = serviceStore

    // определяю тип текущего дровера в зависимости от активного модального окна
    const currentDrawerType = modalData['intermediate-drawer']?.type || null

    // стейт для контроля отображаемых фотографий при загрузке их в поле для фотографий
    const [showImages, setShowImages] = useState([])

    // текущий заголовок аккордеона
    const getCurrentSummary = () => {
        switch (currentDrawerType) {
            case 'problem-solved-remotely':
                return 'Проблема решена дистанционно'
            case 'guarantee-repair':
                return 'Гарантийный ремонт'
            case 'not-guarantee-repair':
                return 'Негарантийный ремонт'
            case 'return-for-revision':
                return 'Вернуть на доработку'
            case 'service-is-not-possible':
                return 'Обслуживание невозможно'
            case 'transfer-to-duplicate':
                return 'Перевод в дубликат'
            default:
                return null
        }
    }

    // текущий текст кнопки
    const getCurrentBtnText = () => {
        switch (currentDrawerType) {
            case 'problem-solved-remotely':
                return 'Завершить обслуживание'
            case 'guarantee-repair':
                return 'Подтвердить'
            case 'not-guarantee-repair':
                return 'Подтвердить'
            case 'return-for-revision':
                return 'Вернуть на доработку'
            case 'service-is-not-possible':
                return 'обслуживание невозможно'
            case 'transfer-to-duplicate':
                return 'Перевести в дубликат'
            default:
                return null
        }
    }

    // текущий функционал кнопки
    const getCurrentBtnAction = () => {
        switch (currentDrawerType) {
            case 'problem-solved-remotely':
                return solvewithoutvisitFunc()
            case 'guarantee-repair':
                return guaranteeRepairFunc()
            case 'not-guarantee-repair':
                return notGuaranteeRepairFunc()
            case 'return-for-revision':
                return improvementRequiredFunc()
            case 'service-is-not-possible':
                return serviceImpossibleFunc()
            case 'transfer-to-duplicate':
                return markServiceOrderAsDuplicateFunc({top:false})
            default:
                return null
        }
    }

    // название поля для выбора настроек загрузки фотографий
    const getPhotoUploadSettingField = () => {
        switch (currentDrawerType) {
            case 'service-is-not-possible':
                return 'MaintenanceImpossible'
            case 'return-for-revision':
                return 'ImprovementRequired'
            default:
                return null
        }
    }

    // лейбл для текстового поля
    const getFormInputLabel = () => {
        switch (currentDrawerType) {
            case 'problem-solved-remotely':
                return intl.formatMessage({ id: 'Основание для завершения обслуживания' })
            case 'guarantee-repair':
                return intl.formatMessage({ id: '№ заявки в сервис-центре' })
            case 'not-guarantee-repair':
                return intl.formatMessage({ id: '№ заявки в сервис-центре' })
            case 'return-for-revision':
                return intl.formatMessage({ id: 'Причина для передачи на доработку' })
            case 'service-is-not-possible':
                return intl.formatMessage({ id: 'Причина невозможности обслуживания' })
            case 'transfer-to-duplicate':
                return intl.formatMessage({ id: 'Причина для перевода в дубликат' })
            default:
                return null
        }
    }

    // при открытии дровера устанавливаю orderId в формсторе
    useEffect(() => {
        if (isModalOpen['intermediate-drawer']) {
            intermediateDrawerFormStore.data.orderId = currentServiceData?.id
        }
    }, [currentServiceData?.id, isModalOpen['intermediate-drawer']])

    // обновление списка отображаемых фотографий. Срабатывает когда в сервисСторе очищаю поле файлов формсторы
    useEffect(() => {
        if (!intermediateDrawerFormStore.data['files']) {
            setShowImages([])
        }
    }, [intermediateDrawerFormStore.data['files']])

    // закрытие дровера
    const handleCloseDrawer = () => {
        closeModalFn['intermediate-drawer']()
        intermediateDrawerFormStore.clear()
        setShowImages([])
    }

    // настройка дизейбла кнопки в зависимости от полученных настроек загрузки фотографий с сервера
    let isDisabled
    const photosListLength = intermediateDrawerFormStore.data.files?.length || 0
    const settings = uploadServicePhotoSettings?.find((item) => item.key === getPhotoUploadSettingField()) || {}
    if (settings) {
        const { maxLimitPerLoad, minLimitPerLoad, optional } = settings
        isDisabled = optional ? false : photosListLength < minLimitPerLoad || photosListLength > maxLimitPerLoad
    }

    return (
        <RightDrawer
            open={isModalOpen['intermediate-drawer']}
            onClose={handleCloseDrawer}
            titleComp={
                <Box className={'fx-col'}>
                    <Typography variant={'subtitle1'} color={'common.white'}>
                        <FormattedMessage
                            id={`Заявка на обслуживание №${String(currentServiceData?.id).padStart(6, '0')}`}
                        />
                    </Typography>
                </Box>
            }
        >
            <CustomAccordion summary={getCurrentSummary()} defaultOpenState={true} preventHandleOpen={false}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '14px', marginTop: '12px' }}>
                    {getPhotoUploadSettingField() ? (
                        <AddPhotosBlock
                            settingsField={getPhotoUploadSettingField()}
                            formStore={intermediateDrawerFormStore}
                            serviceStore={serviceStore}
                        />
                    ) : null}
                    {currentDrawerType === 'guarantee-repair' || currentDrawerType === 'not-guarantee-repair' ? (
                        <FormInput
                            api={intermediateDrawerFormStore}
                            type={'text'}
                            propName={'comment'}
                            fullWidth
                            label={getFormInputLabel()}
                            withoutErrorText={true}
                            // multiline
                            // maxRows={4}
                            ignoreErrorColor={true}
                            validName={'external-tracking-number'}
                        />
                    ) : (
                        <FormInput
                            api={intermediateDrawerFormStore}
                            type={'text'}
                            propName={'comment'}
                            fullWidth
                            label={getFormInputLabel()}
                            withoutErrorText={true}
                            multiline
                            maxRows={4}
                            ignoreErrorColor={true}
                        />
                    )}

                    <Button
                        fullWidth
                        variant='contained'
                        onClick={getCurrentBtnAction}
                        disabled={!isDisabled && intermediateDrawerFormStore.isValid ? false : true}
                    >
                        {getCurrentBtnText()}
                    </Button>
                </Box>
            </CustomAccordion>
        </RightDrawer>
    )
}

export default observer(IntermediateDrawer)
