import React, { FC, ReactNode } from 'react'
import { observer } from 'mobx-react'
import { Checkbox, TableCell } from '@mui/material'
import SummaryStatisticsDealersStoreInstance from '../../store/SummaryStatisticsDealersStore'



export const HeadTableCell = observer(({ children }) => {
    const {allCheckedCheckbox, isIndeterminate, isAllChecked} = SummaryStatisticsDealersStoreInstance
    return children === 'checkbox' ? (
        <TableCell key={children} sx={{ width: '2%',  padding: '0' }}>
            <Checkbox checked={isAllChecked()} indeterminate={isIndeterminate()} onClick={allCheckedCheckbox}  />
        </TableCell>
    ) : (
        <TableCell
            key={children}
            sx={{
                width: children === 'Исполнитель' ? '18%':  '10%',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '16.8px',
                color: 'rgba(95, 95, 95, 1)',
                textAlign: children === 'Исполнитель' ? 'left' : 'center',
            //    height: '30px',
                padding: '0',
                paddingLeft: children === 'Исполнитель' ? '16px': '0',
                position: 'relative',
                '&:not(:last-child)::after': {
                    content: '""',
                    position: 'absolute',
                    right: 0,
                    top: '50%',
                    height: '50%',
                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                    transform: 'translateY(-50%)',
                },
            }}
        >
            {children}
        </TableCell>
    )
})
