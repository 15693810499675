const typographySettings = {
    h1: {
        fontSize: "2rem",
        lineHeight: "2.5rem",
        fontFamily: "'PT Root UI'",
        fontWeight: 500,
    },
    h2: {
        fontSize: "2rem",
        lineHeight: "2.5rem",
        fontFamily: "'PT Root UI'",
        fontWeight: 700,
    },
    h3: {
        fontSize: "1.5rem",
        lineHeight: "2rem",
        fontFamily: "'PT Root UI'",
        fontWeight: 500,
    },
    h4: {
        fontSize: "1.5rem",
        lineHeight: "2rem",
        fontFamily: "PT Root UI",
        fontWeight: 700,
    },
    h5: {
        fontSize: "1rem",
        lineHeight: "1.25rem",
        fontFamily: "'PT Root UI'",
        fontWeight: 500,
    },
    h6: {
        fontFamily: "'PT Root UI'",
        fontSize: "1rem",
        lineHeight: "1.25rem",
        fontWeight: 700,
    },
    subtitle1: {
        fontFamily: "'PT Root UI'",
        fontSize: "1rem",
        lineHeight: "1.25rem",
        fontWeight: 500,
    },
    subtitle2: {
        fontFamily: "'PT Root UI'",
        fontSize: "0.875rem",
        lineHeight: "1.125rem",
        fontWeight: 700,
    },
    body1: {
        fontFamily: "'PT Root UI'",
        fontWeight: 400,
        fontSize: "1rem",
        lineHeight: "1.25rem",
    },
    body2: {
        fontFamily: "'PT Root UI'",
        fontSize: "0.875rem",
        lineHeight: "1.125rem",
        fontWeight: 500,
    },
    button: {
        fontFamily: "'PT Root UI'",
        fontSize: "1rem",
        fontWeight: 700,
        lineHeight: "1.25rem",
        textTransform: "none",
        letterSpacing: "2%",
    },
    caption: {
        fontFamily: "'PT Root UI'",
        fontSize: "0.75rem",
        lineHeight: "1rem",
        fontWeight: 400,
    },
    overline: {
        fontFamily: "'PT Root UI'",
        fontSize: "0.75rem",
        lineHeight: "0.875rem",
        fontWeight: 400,
        textTransform: "uppercase",
        letterSpacing: 0,
    },
    mainMenu: {
        fontFamily: "'PT Root UI'",
        fontSize: "1.25rem",
        lineHeight: "1.5rem",
        fontWeight: 500,
        letterSpacing: 0,
    },

}

export default typographySettings
