import React from "react"
import {getStyles} from "../../utils/utils/commonUtils"
import carouselStyles from "./style/carouselStyle"

const SlideImage = ({imageSrc, zoom, active, coords, i}) => {
    const classes = getStyles(carouselStyles)
    return (
        <img
            id={`slide-image-${i}`}
            style={{
                ...classes.slideImage,
                transform: zoom && active ? "scale(2.5)" : "",
                transformOrigin: `${coords.x}% ${coords.y}%`
            }}
            src={imageSrc}
            alt={"slide"}
        />
    )
}

export default SlideImage
