const mainMenuItemStyles = theme => ({
    menuLink: {
        "&:hover": {
            cursor: "pointer",
            backgroundColor: theme.palette.blue.menuHover
        }
    },
    selectedLink: {
        backgroundColor: theme.palette.blue.menuSelected
    }
})

export default mainMenuItemStyles
