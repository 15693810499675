import React, {useEffect, useLayoutEffect, useRef} from "react"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import cross from "../../assets/images/common/cross_dark_thin.svg"
import {observer} from "mobx-react"
import {closeModalFn, isModalOpen, modalData} from "src/utils/controllers/ModalController"
import questionMarkBlue from "src/assets/images/common/questionMarkBlue.svg"
import questionMark from "src/assets/images/common/questionMark.svg"
import Typography from "@mui/material/Typography"
import customTooltipStyles from "src/components/CustomTooltip/styles/customTooltipStyles"
import {getStyles} from "src/utils/utils/commonUtils"
import withWidth from "@mui/material/Hidden/withWidth"

function CustomTooltip({width}) {
    const modalRef = useRef()
    const classes = getStyles(customTooltipStyles)

    useEffect(() => {
        if (width === "sm" && isModalOpen["custom-tooltip"]) {
            closeModalFn["custom-tooltip"]()
        }
    },[width])

    useEffect(() => {
        if (isModalOpen["custom-tooltip"]) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.removeAttribute("style")
        }
    },[isModalOpen["custom-tooltip"]])

    useEffect(() => {
        if (isModalOpen["custom-tooltip"]) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.removeAttribute("style")
        }
    },[isModalOpen["custom-tooltip"]])

    useLayoutEffect(() => {
        if (modalRef.current) {
            if (isModalOpen["custom-tooltip"]) {
                requestAnimationFrame(() => modalRef.current.style.top = "50%")
            }
        }
    }, [isModalOpen["custom-tooltip"]])

    return isModalOpen["custom-tooltip"] ?
        <Box sx={classes.tooltipBackdrop}
             onClick={closeModalFn["custom-tooltip"]}
        >
            <Box
                ref={modalRef}
                sx={classes.tooltipContainer}
            >
                <Box sx={classes.tooltipTitleContainer}>
                    <Box className={"aln-center"}>
                        {
                            <Box mr={1}>
                                <img src={modalData["custom-tooltip"].iconColor === "blue" ? questionMarkBlue : questionMark}
                                     alt=""/>
                            </Box>
                        }
                        {
                            modalData["custom-tooltip"].title &&
                            <Typography variant={"h2"} sx={classes.tooltipTitle}>
                                { modalData["custom-tooltip"].title}
                            </Typography>
                        }
                    </Box>
                    <IconButton onClick={closeModalFn["custom-tooltip"]}>
                        <img src={cross}/>
                    </IconButton>
                </Box>
                <Box sx={classes.tooltipContent}>
                    {modalData["custom-tooltip"].content}
                </Box>
            </Box>
        </Box>
        : <></>
}

export default withWidth()(observer(CustomTooltip))