import { Box, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import arrowIcon from 'src/assets/images/pageSwitch/chevron-right.svg'
import arrowInvertIcon from 'src/assets/images/pageSwitch/chevron.svg'

export const PageSwitch = observer(({ setPeriod }) => {
    const [countWeek, setCountWeek] = React.useState(1)

    const weekNext = () => {
        if (countWeek < 2) {
            setCountWeek(countWeek + 1)
            setPeriod(1)
        }
    }

    const weekPrev = () => {
        if (countWeek > 1) {
            setCountWeek(countWeek - 1)
            setPeriod(0)
        }
    }
    return (
        <Box sx={{ display: 'flex',  marginLeft: '50px', alignItems: 'center', gap: '4px' }}>
            <Box
                sx={{
                    cursor: countWeek !== 1 ? 'pointer' : 'default',
                    '&:hover': countWeek !== 1 ? { transform: 'scale(1.2)' } : null,
                    transition: 'all 0.3s',
                }}
                component='img'
                src={arrowIcon}
                onClick={weekPrev}
                alt='Влево'
            />

            <Typography sx={{ width: '8.4px', textAlign: 'center' }}>{countWeek}</Typography>
            <Typography sx={{ textAlign: 'center' }}>из</Typography>
            <Typography sx={{ textAlign: 'center' }}>{2}</Typography>

            <Box
                sx={{
                    cursor: countWeek !== 2 ? 'pointer' : 'default',
                    '&:hover': countWeek !== 2 ? { transform: 'scale(1.2)' } : null,
                    transition: 'all 0.3s',
                }}
                component='img'
                src={arrowInvertIcon}
                onClick={weekNext}
                alt='Вправо'
            />
        </Box>
    )
})
