import { closeModalFn, isModalOpen, modalData } from '../../utils/controllers/ModalController'
import { FormattedMessage } from 'react-intl'
import UploadFileForm from './components/UploadFileForm'
import AddRequestForm from './components/AddRequestForm'
import RightDrawer from '../customElements/RightDrawer/RightDrawer'
import React from 'react'
import { observer } from 'mobx-react'

function AddRequestDrawer() {
    const { uploadFile, filterStore, ordersStore } = modalData['add-request'] || {}
    return (
        <RightDrawer
            open={isModalOpen['add-request']}
            onClose={closeModalFn['add-request']}
            title={
                uploadFile ? (
                    <FormattedMessage id={'Загрузка списка'} />
                ) : (
                    <FormattedMessage id={'Заявка на установку'} />
                )
            }
            backButtonClick={() => closeModalFn['add-request']()}
        >
            {isModalOpen['add-request'] ? (
                uploadFile ? (
                    <UploadFileForm />
                ) : (
                    <AddRequestForm filterStore={filterStore} ordersStore={ordersStore} />
                )
            ) : null}
        </RightDrawer>
    )
}

export default observer(AddRequestDrawer)
