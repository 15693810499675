import {Typography} from "@mui/material"
import React from "react"
import {getStyles} from "../../../../utils/utils/commonUtils"
import dataSettingsStyles from "../../styles/dataSettingsStyles"

const DrawerInfoElement = ({subtitle, info}) => {
    const classes = getStyles(dataSettingsStyles)
    return (
        <>
            <Typography sx={classes.drawerSubtitle}>{subtitle}</Typography>
            <Typography sx={classes.drawerInfo}>{info}</Typography>
        </>
    )
}

export default DrawerInfoElement
