const photoGalleryDialogStyles = (theme) => ({
    topPanel: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 0",
        [theme.breakpoints.down(720)]: {
            padding: "8px 16px"
        }
    },
    topPanelText: {
        fontSize: "16px",
        fontWeight: 500,
        color: "black"
    },
    closeBtn: {
        padding: "0"
    },
    customDialog: {
        height: "90vh",
        width: "65%",
        position: "fixed",
        right: "370px",

        padding: " 0 24px 35px 24px",
        [theme.breakpoints.down(1200)]: {
            width: "58%",
            left: "10px"
        },
        [theme.breakpoints.down(1020)]: {
            width: "50%",
            right: "365px",
            left: "-20px"
        },
        [theme.breakpoints.down(800)]: {
            width: "46%",
            right: "350px"
        },
        [theme.breakpoints.down(720)]: {
            width: "100%",
            padding: " 0 0 16px 0",
            margin: "0",
            position: "static",
            right: "0"
        },
        [theme.breakpoints.down("sm")]: {
            width: "100vw"
        }
    },
    photoTools: {
        position: "absolute",
        right: "40px",
        top: "55px",
        borderRadius: "8px",
        boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
        background: "#FFF",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down(720)]: {
            gap: "8px",
            right: "14px"
        },
        [theme.breakpoints.down("sm")]: {
            top: "80px",
            right: "7px"
        }
    },
    toolWrapper: {
        transition: "all 0.2s",
        height: "100%",
        padding: "8px",
        borderRadius: "0 0 0 0"
    }
})

export default photoGalleryDialogStyles
