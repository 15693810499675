import { makeAutoObservable } from 'mobx'
import { getColumnsForStatisticsActivity, getDataForStatisticsActivity } from '../../../services/ApiService'

class StatisticsActivityTableStore {
    rows = []
    data = []
    columns = []
    constructor() {
        makeAutoObservable(this)
    }
    getColumns = () => {
        getColumnsForStatisticsActivity().then((res) => {
            this.setColumns(res)
        })
    }
    getData = (params) => {
        getDataForStatisticsActivity(params).then((res) => {
            this.setData(res)
        })
    }
    setRows = (rows) => {
        this.rows = rows
    }

    setColumns = (columns) => {
        this.columns = columns
    }

    setData = (data) => {
        this.data = data
    }

}

const statisticsActivityTableStore = new StatisticsActivityTableStore()
export default statisticsActivityTableStore