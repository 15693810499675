import React, { useEffect } from 'react'
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Box } from '@mui/material'
import moment from 'moment'
import { getComp } from '../../../utils/utils/DI'
import { observer } from 'mobx-react'
import statisticsActivityTableStore from '../stores/StatisticsActivityTableStore'

const StatisticsActivityTable = () => {
    const { rows, setRows, getColumns, getData, columns, data } = statisticsActivityTableStore
    const authorsStore = getComp('AuthorsStore')
    const { getNameReel } = authorsStore

    useEffect(() => {
        setRows(newData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        getColumns()
        getData({ markerRoleList: 4 })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClick = (event, id) => {}

    const getUniqueGroups = (data) => {
        // чтобы понять какие группы колонок есть
        const uniqueGroups = new Set(data.map((item) => item.group))
        return Array.from(uniqueGroups)
    }

    const groupByField = (data, field) => {
        // сортирует колонки по своим группам
        return data.reduce((acc, item) => {
            const group = item[field]
            if (!acc[group]) {
                acc[group] = []
            }
            acc[group].push(item)
            return acc
        }, {})
    }

    const uniqueGroups = getUniqueGroups(columns)
    const groupedColumn = groupByField(columns, 'group')

    const countElementsInGroup = (nameGrup) => {
        // подсчёт колличества элементов в группе это нужно для того чтобы ровно разместить название группы над колонками
        return groupedColumn[nameGrup].length
    }

    const mapColumns = (groups) => {
        return Object.values(groups).flatMap((group) =>
            group.map((column, i) => (
                <TableCell
                    sx={{
                        color: '#A9A9A9',
                        padding: '10px',
                        borderLeft: i !== 0 ? 'none' : '1px solid #E0E0E0',
                        borderRight: i !== group.length - 1 ? 'none' : '1px solid #E0E0E0',
                    }}
                    key={column.id}
                    align='center'
                >
                    {column.title}
                </TableCell>
            ))
        )
    }

    const groupBySubjectId = (data) => {
        // собирает массив с данными для отрисовки в таблицы из каши пришедшей с бека
        const groupedData = data.reduce((acc, item) => {
            const { subjectId, trackingActivityId, count, lastTime } = item

            if (!acc[subjectId]) {
                acc[subjectId] = { subjectId, counts: {}, lastTime: lastTime }
            }

            if (!acc[subjectId].counts[trackingActivityId]) {
                acc[subjectId].counts[trackingActivityId] = 0
            }

            acc[subjectId].counts[trackingActivityId] += count
            if (new Date(lastTime) > new Date(acc[subjectId].lastTime)) {
                acc[subjectId].lastTime = lastTime
            }

            return acc
        }, {})

        return Object.values(groupedData).map(({ subjectId, counts, lastTime }) => {
            const result = { subjectId, lastTime }
            for (const trackingActivityId in counts) {
                result[trackingActivityId] = counts[trackingActivityId]
            }
            return result
        })
    }

    const newData = groupBySubjectId(data)
   
    return (
        <Box
            sx={{
                background: '#FFF',
                borderRadius: '10px',
                boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.15)',
                padding: '0 8px 24px',
            }}
        >
            <TableContainer sx={{ boxShadow: 'none', border: 'none' }} component={Paper}>
                <Table aria-label='simple table'>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ padding: '10px' }} />
                            {uniqueGroups.map((group, i) => (
                                <TableCell
                                    sx={{
                                        color: '#A9A9A9',
                                        padding: '10px',
                                        borderLeft: '1px solid #E0E0E0',
                                        borderRight: '1px solid #E0E0E0',
                                    }}
                                    key={group}
                                    align='center'
                                    colSpan={countElementsInGroup(group)}
                                >
                                    {group}
                                </TableCell>
                            ))}
                            <TableCell sx={{ padding: '10px' }} />
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ color: '#A9A9A9', padding: '10px', paddingLeft: '14px' }}>Администрация</TableCell>
                            {mapColumns(groupedColumn)}
                            <TableCell sx={{ color: '#A9A9A9', padding: '10px', paddingLeft: '14px' }}>Последняя активность</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {rows.map((row, i) => {
                            return (
                                <TableRow
                                    key={i}
                                    hover
                                    onClick={(event) => handleClick(event, row.id)}
                                    role='checkbox'
                                    tabIndex={-1}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        background: i % 2 !== 0 ? 'rgba(217, 217, 217, 0.20)' : null,
                                        '&:hover': {
                                            backgroundColor: 'rgba(93, 175, 239, 0.20) !important',
                                        },
                                    }}
                                >
                                    <TableCell sx={{ padding: '14px' }}>{getNameReel(row['subjectId'])}</TableCell>
                                    {columns.map((column, i) => (
                                        <TableCell
                                            sx={{
                                                padding: '14px',
                                                textAlign: 'center',
                                            }}
                                            key={column.propName}
                                            align={column.numeric ? 'center' : 'center'}
                                        >
                                            {column.title !== 'lastTime'
                                                ? row[column.id] || '-'
                                                : moment(row[column.title]).add(3, 'hours').format('DD.MM.YY, HH:mm')}
                                        </TableCell>
                                    ))}
                                    <TableCell sx={{ padding: '14px' }}>
                                        {moment(row['lastTime']).add(3, 'hours').format('DD.MM.YY, HH:mm')}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default observer(StatisticsActivityTable)
