import {makeAutoObservable} from "mobx"
import {getSources} from "../services/ApiService"

class SourcesStore {
    constructor() {
        makeAutoObservable(this)
    }

    sources = []

    getSourceName = (id) => {
        const source = this.sources.find((el) => el.id === id)
        return source ? source.name : ""
    }

    load = () => {
        getSources().then((res) => {
            this.sources = res
        })
    }
}

export default SourcesStore
