import commonStyles from "../../styles/commonStyles"

const componentsOverrides = {
    MuiTypography: {
        styleOverrides: {
            root: ({theme}) => ({
                color: theme.palette.text.main,
            }),
        },
    },
    MuiTooltip: {
        styleOverrides: {
            tooltip: {
                background: "#F6F6F6",
                boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.40)",
                backdropFilter: "blur(2px)",
                color: "inherit",
                borderRadius: "8px",
            },
        },
    },
    MuiPaper: {
        styleOverrides: {
            root: ({theme, ownerState}) =>
                ownerState.type === "container"
                    ? {
                        padding: "24px 40px",
                        borderRadius: "8px",
                        boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
                        backgroundColor: ownerState.color ? ownerState.color : theme.palette.background.grayLight,
                    }
                    : ownerState.type === "row" || ownerState.type === "outlineRow" || ownerState.type === "hoverRow"
                        ? {
                            padding: "24px",
                            boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.15)",
                            borderRadius: "20px",
                            background: theme.palette.background.block,
                            border: ownerState.type === "outlineRow" ? "1px solid #FF7426" : null,
                            "&:hover":
                                ownerState.type === "hoverRow"
                                    ? {
                                        boxShadow: "0px 0px 6px rgba(254, 106, 41, 0.8)",
                                        cursor: "pointer",
                                    }
                                    : null,
                        }
                        : null,
        },
    },
    MuiDrawer: {
        styleOverrides: {
            root: {height: "calc(100% - 56px) !important"},
        },
    },
    MuiDialog: {
        styleOverrides: {
            paper: ({theme}) => ({
                background: theme.mode === "dark" ? theme.palette.background.paper : theme.palette.background.default,
                boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.51)",
                borderRadius: "12px",
                width: 636,
            }),
        },
    },
    MuiFormLabel: {
        styleOverrides: {
            root: {
                lineHeight: 1,
                top: "-3px !important",
                maxWidth: "calc(100% - 50px) !important",
            },
        },
    },
    MuiSelect: {
        styleOverrides: {
            root: (props) => {
                const {size, color, theme} = props
                const classes = commonStyles(theme)
                let result = classes.selectField
                if (size === "small") {
                    result = {...result, ...classes.selectFieldSmall}
                } else {
                    result = {...result, ...classes.selectFieldMedium}
                }
                if (color === "secondary") {
                    result = {...result, ...classes.selectFieldSecondary}
                }
                if (props.inputProps?.multiple) {
                    result = {...result, height: "auto"}
                }

                return result
            },
            icon: {
                top: "auto",
            },
            select: {
                border: "none",
            },
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: ({_, theme}) => commonStyles(theme).menuItem,
        },
    },
    MuiMenu: {
        styleOverrides: {
            list: ({_, theme}) => {
                return {
                    backgroundColor: theme.palette.background.white,
                    padding: "4px 8px",
                }
            },
        },
    },
    MuiButton: {
        defaultProps: {
            disableRipple: true,
            disableTouchRipple: true,
            disableFocusRipple: true,
            disableElevation: true,
        },
        styleOverrides: {
            root: ({ownerState, theme}) => {
                const classes = commonStyles(theme)
                let result = {...classes.button}
                if (ownerState.size === "small") {
                    result = {...result, ...classes.smallButton}
                } else if (ownerState.size === "large") {
                    result = {...result, ...classes.largeButton}
                } else {
                    result = {...result, ...classes.mediumButton}
                }
                if (ownerState.color === "primary" && ownerState.variant === "contained") {
                    result = {...result, ...classes.containedPrimaryButton}
                }
                return result
            },
            startIcon: {
                margin: 0,
            },
        },
    },
    MuiInputBase: {
        styleOverrides: {
            root: ({theme, ownerState, ...props}) => {
                const classes = commonStyles(theme)
                return ownerState.multiline ? {} : {...classes.input}
            },
            // input: {
            //     padding: "9px 14px"
            // }
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {background: "#fff"},
            input: {padding: "9px 14px"},
            notchedOutline: ({theme}) => {
                const classes = commonStyles(theme)
                return {...classes.outlinedInput}
            },
        },
    },
}

export default componentsOverrides
