import newRequest from '../assets/images/orderStatuses/new.svg'
import assigned from '../assets/images/orderStatuses/assigned.svg'
import accepted from '../assets/images/orderStatuses/accepted.svg'
import installation from '../assets/images/orderStatuses/installation.svg'
import installed from '../assets/images/orderStatuses/installed.svg'
import incomplete from '../assets/images/orderStatuses/incomplete.svg'
import duplicate from '../assets/images/orderStatuses/duplicate.svg'
import forRevision from '../assets/images/orderStatuses/clarify_request.svg'
import uploadByAdmin from '../assets/images/orderStatuses/uploadByAdmin.svg'
import { gState } from '../utils/controllers/GlobalStateController'
import { isAdmin, isAdministration, isDealer, isDealerOperator, isOperator } from '../services/AccountService'
import formed from '../assets/images/reportStatuses/formed.svg'
import dataChecking from '../assets/images/reportStatuses/dataChecking.svg'
import dataClarifying from '../assets/images/reportStatuses/dataClarifying.svg'
import dataCheckFinished from '../assets/images/reportStatuses/dataCheckingFinished.svg'
import sent from '../assets/images/reportStatuses/sent.svg'
import received from '../assets/images/reportStatuses/received.svg'
import dataRechecking from '../assets/images/reportStatuses/dataRechecking.svg'
import paying from '../assets/images/reportStatuses/paying.svg'
import closed from '../assets/images/reportStatuses/closed.svg'
import createDateIcon from '../assets/images/common/creationDate.svg'
import installationDateIcon from '../assets/images/common/installationDate.svg'
import agreementStatus0 from '../assets/images/agreementCheckingStatuses/agreement-checking-status-0.svg'
import agreementStatus1 from '../assets/images/agreementCheckingStatuses/agreement-checking-status-1.svg'
import agreementStatus2 from '../assets/images/agreementCheckingStatuses/agreement-checking-status-2.svg'
import agreementStatus3 from '../assets/images/agreementCheckingStatuses/agreement-checking-status-3.svg'
import moment from 'moment'
import { action, computed, makeObservable, observable } from 'mobx'
import requested from '../assets/images/serviceOrderStatuses/requested.svg'
import dealerAssigned from '../assets/images/serviceOrderStatuses/dealerAssigned.svg'
import inProgress from '../assets/images/serviceOrderStatuses/inProgress.svg'
import solvedWithoutVisitIcon from '../assets/images/serviceOrderStatuses/solvedWithoutVisit.svg'
import acceptedByDealerIcon from '../assets/images/serviceOrderStatuses/acceptedByDealer.svg'
import completedIcon from '../assets/images/serviceOrderStatuses/completed.svg'
import impossibleIcon from '../assets/images/serviceOrderStatuses/impossible.svg'
import duplicateIcon from '../assets/images/serviceOrderStatuses/duplicate.svg'
import improvementRequiredIcon from '../assets/images/serviceOrderStatuses/improvementRequired.svg'
import onSiteRepairIcon from '../assets/images/serviceOrderStatuses/onSiteRepair.svg'
import transferToServiceCenterIcon from '../assets/images/serviceOrderStatuses/transferToServiceCenter.svg'
import repairIsDoneIcon from '../assets/images/serviceOrderStatuses/repairIsDone.svg'
import diagnosticAndRepairIcon from '../assets/images/serviceOrderStatuses/diagnosticAndRepair.svg'
import notGuaranteeRepairIcon from '../assets/images/serviceOrderStatuses/notGuaranteeRepair.svg'
import guaranteeRepairIcon from '../assets/images/serviceOrderStatuses/guaranteeRepair.svg'
import { getIconFinancialSource } from '../helpers/switchFinancialSource'
import { getFinancialSources } from '../services/ApiService'

class StatusesStore {
    constructor() {
        makeObservable(this, {
            orderStatuses: computed,
            financialSources: computed,
            finSourceId: observable,
        })
    }
    finSourceId = null
    intl = gState['intl']

    financialSourcesName = []
    allOrderStatuses = {
        created: 1,
        assigned: 2,
        accepted: 4,
        working: 5,
        installed: 6,
        impossible: 7,
        loadedByOperator: 9,
        duplicate: 10,
        uncompleted: 11,
        reporting: 12,
        dataChecking: 13,
        clarifying: 14,
        reported: 15,
        closed: 16,
        createdAtSummaryTime: 98,
        installedAtSummaryTime: 99,
    }

    allServiceOrderStatuses = {
        requested: 1,
        dealerAssigned: 2,
        solvedWithoutVisit: 3,
        acceptedByDealer: 4,
        InProgress: 5,
        // completed: 6,
        impossible: 7,
        duplicate: 10,
        improvementRequired: 11,
        assignedAfterCompleted: 100,

        // комплексные статусы
        repairIsDone: 6.2,
        diagnosticAndRepair: 6.3,

        // воображаемые статусы
        onSiteRepair: 6.105,
        transferToServiceCenter: 6.106,
        guaranteeRepair: 6.4,
        noGuaranteeRepair: 6.5,
    }

    summaryStatuses = [
        {
            id: this.allOrderStatuses.createdAtSummaryTime,
            name: this.intl.formatMessage({ id: 'Дата создания' }),
            imageSrc: createDateIcon,
            summaryTooltip: this.intl.formatMessage(
                {
                    id: 'Количество заявок, у которых Дата создания попадает в выбранный временной период (по данным на {timeStamp})',
                    defaultMessage:
                        'Количество заявок, у которых Дата создания попадает в выбранный временной период (по данным на {timeStamp})',
                },
                {
                    timeStamp: moment().format('DD.MM.YYYY'),
                }
            ),
        },
        {
            id: this.allOrderStatuses.installedAtSummaryTime,
            name: this.intl.formatMessage({ id: 'Дата установки' }),
            imageSrc: installationDateIcon,
            summaryTooltip: this.intl.formatMessage(
                {
                    id: 'Количество заявок, у которых Дата установки попадает в выбранный временной период (по данным на {timeStamp})',
                    defaultMessage:
                        'Количество заявок, у которых Дата установки попадает в выбранный временной период (по данным на {timeStamp})',
                },
                {
                    timeStamp: moment().format('DD.MM.YYYY'),
                }
            ),
        },
    ]

    get orderStatuses() {
        return [
            {
                id: this.allOrderStatuses.created,
                name: this.intl.formatMessage({ id: 'Создана' }),
                imageSrc: newRequest,
                summaryTooltip: this.intl.formatMessage({
                    id: 'Количество заявок, заведённых в систему в статусе "Создана" вне зависимости от фактической Даты создания заявки',
                }),
            },
            {
                id: this.allOrderStatuses.assigned,
                name: this.intl.formatMessage({ id: 'Назначена' }),
                imageSrc: assigned,
                summaryTooltip: this.intl.formatMessage({
                    id: 'Количество заявок, переведённых за указанный период в статус "Назначена"',
                }),
            },
            {
                id: this.allOrderStatuses.accepted,
                name: this.intl.formatMessage({ id: 'Принята' }),
                imageSrc: accepted,
                summaryTooltip: this.intl.formatMessage({
                    id: 'Количество заявок, переведённых за указанный период в статус "Принята"',
                }),
            },
            {
                id: this.allOrderStatuses.working,
                name: this.intl.formatMessage({ id: 'Установка' }),
                imageSrc: installation,
                summaryTooltip: this.intl.formatMessage({
                    id: 'Количество заявок, переведённых за указанный период в статус "Установка"',
                }),
            },
            {
                id: this.allOrderStatuses.installed,
                name: this.intl.formatMessage({ id: 'Установлено' }),
                imageSrc: installed,
                summaryTooltip: this.intl.formatMessage({
                    id: 'Количество заявок, переведённых за указанный период в статус "Установлено" вне зависимости от фактической Даты установки (даты подписания Акта с Абонентом)',
                }),
            },
            {
                id: this.allOrderStatuses.impossible,
                name: this.intl.formatMessage({ id: 'Невозможно' }),
                imageSrc: incomplete,
                summaryTooltip: this.intl.formatMessage({
                    id: 'Количество заявок, переведённых за указанный период в статус "Невозможно"',
                }),
            },
            {
                id: this.allOrderStatuses.loadedByOperator,
                name: isDealer()
                    ? this.intl.formatMessage({ id: 'Установленные ранее' })
                    : this.intl.formatMessage({ id: 'Добавлена администратором' }),
                imageSrc: uploadByAdmin,
                summaryTooltip: this.intl.formatMessage({
                    id: 'Количество заявок, переведённых за указанный период в статус "Добавлена администратором"',
                }),
            },
            {
                id: this.allOrderStatuses.duplicate,
                name: this.intl.formatMessage({ id: 'Дубликат' }),
                imageSrc: duplicate,
                summaryTooltip: this.intl.formatMessage({
                    id: 'Количество заявок, переведённых за указанный период в статус "Дубликат"',
                }),
            },
            {
                id: this.allOrderStatuses.uncompleted,
                name: this.intl.formatMessage({ id: 'На доработку' }),
                imageSrc: forRevision,
                summaryTooltip: this.intl.formatMessage({
                    id: 'Количество заявок, переведённых за указанный период в статус "На доработку"',
                }),
            },
        ]
    }

    get financialSources() {
        return [
            {
                id: 1,
                name: this.financialSourcesName[0] || this.intl.formatMessage({ id: 'Полная дотация' }),
                imageSrc: getIconFinancialSource(1),
            },
            {
                id: 2,
                name: this.financialSourcesName[1] || this.intl.formatMessage({ id: 'Частичная дотация' }),
                imageSrc: getIconFinancialSource(2),
            },
            {
                id: 3,
                name: this.financialSourcesName[2] || this.intl.formatMessage({ id: 'За счет абонента' }),
                imageSrc: getIconFinancialSource(3),
            },
            {
                id: 4,
                name: this.financialSourcesName[3] || this.intl.formatMessage({ id: 'Не выбрана' }),
                imageSrc: getIconFinancialSource(4),
            },
        ]
    }
    getFinancialSourceElement = (value) => {
        return this.financialSources.find((item) => item.id === value)
    }

    getFinancialSourceBack = () => {
        getFinancialSources().then((res) => {
            this.financialSourcesName = res.map((item) => item.name)
        })
    }

    setFinSourceId = (value) => {
        this.finSourceId = value
    }

    reportOrderStatuses = [
        {
            id: this.allOrderStatuses.reporting,
            name: this.intl.formatMessage({ id: 'Добавлена в отчет' }),
            imageSrc: formed,
            summaryTooltip: this.intl.formatMessage({
                id: 'Количество заявок, переведённых за указанный период в статус "Добавлена в отчет"',
            }),
        },
        {
            id: this.allOrderStatuses.dataChecking,
            name: this.intl.formatMessage({ id: 'Проверка данных' }),
            imageSrc: dataChecking,
            summaryTooltip: this.intl.formatMessage({
                id: 'Количество заявок, переведённых за указанный период в статус "Проверка данных"',
            }),
        },
        {
            id: this.allOrderStatuses.clarifying,
            name: this.intl.formatMessage({ id: 'Уточнение данных' }),
            imageSrc: dataClarifying,
            summaryTooltip: this.intl.formatMessage({
                id: 'Количество заявок, переведённых за указанный период в статус "Уточнение данных"',
            }),
        },
        {
            id: this.allOrderStatuses.reported,
            name: this.intl.formatMessage({ id: 'Проверка завершена' }),
            imageSrc: dataCheckFinished,
            summaryTooltip: this.intl.formatMessage({
                id: 'Количество заявок, переведённых за указанный период в статус "Проверка завершена"',
            }),
        },
        {
            id: this.allOrderStatuses.closed,
            name: this.intl.formatMessage({ id: 'Завершена' }),
            imageSrc: closed,
            summaryTooltip: this.intl.formatMessage({
                id: 'Количество заявок, переведённых за указанный период в статус "Завершена"',
            }),
        },
    ]

    reportServiceOrderStatuses = [
        {
            id: this.allServiceOrderStatuses.requested,
            name: this.intl.formatMessage({ id: 'Создана' }),
            imageSrc: requested,
            summaryTooltip: this.intl.formatMessage({
                id: 'Создана',
            }),
        },
        {
            id: this.allServiceOrderStatuses.dealerAssigned,
            name: this.intl.formatMessage({ id: 'Назначена' }),
            imageSrc: dealerAssigned,
            summaryTooltip: this.intl.formatMessage({
                id: 'Назначена',
            }),
        },
        {
            id: this.allServiceOrderStatuses.solvedWithoutVisit,
            name: this.intl.formatMessage({ id: 'Выполнена дистанционно' }),
            imageSrc: solvedWithoutVisitIcon,
            summaryTooltip: this.intl.formatMessage({
                id: 'Выполнена дистанционно',
            }),
        },
        {
            id: this.allServiceOrderStatuses.acceptedByDealer,
            name: this.intl.formatMessage({ id: 'Принята' }),
            imageSrc: acceptedByDealerIcon,
            summaryTooltip: this.intl.formatMessage({
                id: 'Принята',
            }),
        },
        {
            id: this.allServiceOrderStatuses.InProgress,
            name: this.intl.formatMessage({ id: 'В работе' }),
            imageSrc: inProgress,
            summaryTooltip: this.intl.formatMessage({
                id: 'В работе',
            }),
        },
        // {
        //     id: this.allServiceOrderStatuses.completed,
        //     name: this.intl.formatMessage({ id: 'Выполнено' }),
        //     imageSrc: completedIcon,
        //     summaryTooltip: this.intl.formatMessage({
        //         id: 'Выполнено',
        //     }),
        // },
        {
            id: this.allServiceOrderStatuses.impossible,
            name: this.intl.formatMessage({ id: 'Обслуживание невозможно' }),
            imageSrc: impossibleIcon,
            summaryTooltip: this.intl.formatMessage({
                id: 'Обслуживание невозможно',
            }),
        },
        {
            id: this.allServiceOrderStatuses.duplicate,
            name: this.intl.formatMessage({ id: 'Дубликат' }),
            imageSrc: duplicateIcon,
            summaryTooltip: this.intl.formatMessage({
                id: 'Дубликат',
            }),
        },
        {
            id: this.allServiceOrderStatuses.improvementRequired,
            name: this.intl.formatMessage({ id: 'На доработку' }),
            imageSrc: improvementRequiredIcon,
            summaryTooltip: this.intl.formatMessage({
                id: 'На доработку',
            }),
        },
        //  Комплексные статусы

        {
            id: this.allServiceOrderStatuses.repairIsDone,
            name: this.intl.formatMessage({ id: 'Ремонт завершен' }),
            imageSrc: repairIsDoneIcon,
            summaryTooltip: this.intl.formatMessage({
                id: 'Ремонт завершен',
            }),
        },

        {
            id: this.allServiceOrderStatuses.diagnosticAndRepair,
            name: this.intl.formatMessage({ id: 'Диагностика и ремонт' }),
            imageSrc: diagnosticAndRepairIcon,
            summaryTooltip: this.intl.formatMessage({
                id: 'Диагностика и ремонт',
            }),
        },
        // воображаемые статусы
        {
            id: this.allServiceOrderStatuses.onSiteRepair,
            name: this.intl.formatMessage({ id: 'Ремонт на месте' }),
            imageSrc: diagnosticAndRepairIcon,
            summaryTooltip: this.intl.formatMessage({
                id: 'Ремонт на месте',
            }),
        },
        {
            id: this.allServiceOrderStatuses.transferToServiceCenter,
            name: this.intl.formatMessage({ id: 'Передача в сервис-центр' }),
            imageSrc: transferToServiceCenterIcon,
            summaryTooltip: this.intl.formatMessage({
                id: 'Передача в сервис-центр',
            }),
        },
        {
            id: this.allServiceOrderStatuses.guaranteeRepair,
            name: this.intl.formatMessage({ id: 'Гарантийный ремонт' }),
            imageSrc: guaranteeRepairIcon,
            summaryTooltip: this.intl.formatMessage({
                id: 'Гарантийный ремонт',
            }),
        },
        {
            id: this.allServiceOrderStatuses.noGuaranteeRepair,
            name: this.intl.formatMessage({ id: 'Негарантийный ремонт' }),
            imageSrc: notGuaranteeRepairIcon,
            summaryTooltip: this.intl.formatMessage({
                id: 'Негарантийный ремонт',
            }),
        },
    ]

    reportStatuses = [
        { id: 1, name: this.intl.formatMessage({ id: 'Создан' }), imageSrc: formed },
        { id: 2, name: this.intl.formatMessage({ id: 'Проверка данных' }), imageSrc: dataChecking },
        { id: 3, name: this.intl.formatMessage({ id: 'Уточнение данных' }), imageSrc: dataClarifying },
        { id: 4, name: this.intl.formatMessage({ id: 'Проверка завершена' }), imageSrc: dataCheckFinished },
        { id: 5, name: this.intl.formatMessage({ id: 'Отправлен' }), imageSrc: sent },
        { id: 100, name: this.intl.formatMessage({ id: 'Получен' }), imageSrc: received },
        { id: 101, name: this.intl.formatMessage({ id: 'Проверка отчёта' }), imageSrc: dataRechecking },
        { id: 102, name: this.intl.formatMessage({ id: 'На оплату' }), imageSrc: paying },
        { id: 200, name: this.intl.formatMessage({ id: 'Закрыт' }), imageSrc: closed },
    ]

    allDataCheckingStatuses = {
        none: 0,
        checked: 1,
        needClarification: 2,
        clarified: 3,
    }

    dataCheckingStatuses = [
        {
            id: this.allDataCheckingStatuses.none,
            name: this.intl.formatMessage({ id: 'Не указан' }),
            color: '#FFF',
        },
        {
            id: this.allDataCheckingStatuses.checked,
            name: this.intl.formatMessage({ id: 'Проверено' }),
            color: 'rgba(22,176,37,0.2)',
        },
        {
            id: this.allDataCheckingStatuses.needClarification,
            name: this.intl.formatMessage({ id: 'Требуется уточнение' }),
            color: 'rgba(224,23,23,0.1)',
        },
        {
            id: this.allDataCheckingStatuses.clarified,
            name: this.intl.formatMessage({ id: 'Уточнено' }),
            color: 'rgba(224,96,23,0.2)',
        },
    ]

    agreementCheckingStatuses = [
        {
            id: 0,
            name: 'Договор не предоставлен',
            imageSrc: agreementStatus0,
        },
        {
            id: 1,
            name: 'Договор сформирован',
            imageSrc: agreementStatus1,
        },
        // {
        //     id: 3,
        //     name: "Договор принят",
        //     imageSrc: agreementStatus3
        // },
        // {
        //     id: 2,
        //     name: "Договор отклонён",
        //     imageSrc: agreementStatus2
        // }
    ]
    //покак нет стора для приоритетов
    priorityRequestList = [
        { value: 'Без приоритета', key: 'Без приоритета' },
        { value: '0', key: '0' },
        { value: '1', key: '1' },
        { value: '2', key: '2' },
        { value: '3', key: '3' },
        { value: '4', key: '4' },
        { value: '5', key: '5' },
    ]

    getDataCheckingStatusName = (id) => {
        const statusData = this.dataCheckingStatuses.find((el) => el.id === id)
        if (statusData) {
            return statusData.name
        } else {
            return ''
        }
    }

    getDataCheckingStatuses = (options = {}) => {
        switch (true) {
            case isAdmin():
                return this.dataCheckingStatuses
            case isDealer() || isDealerOperator():
                if (options.dealerReportsFilters) {
                    return this.dataCheckingStatuses.filter((status) => {
                        return status.id === 0 || status.id === 3
                    })
                }
                if (options.reportsFrontFilters) {
                    return this.dataCheckingStatuses
                }
                return this.dataCheckingStatuses

            default:
                return []
        }
    }

    getAgreementStatusData = (id) => {
        return this.agreementCheckingStatuses.find((data) => data.id === id)
    }

    getDealerStatusName = (id) => {
        let status
        switch (id) {
            case 2:
                status = this.intl.formatMessage({ id: 'Назначена установщику' })
                break
            case 4:
                status = this.intl.formatMessage({ id: 'Принята дилером' })
                break
            case 5:
                status = this.intl.formatMessage({ id: 'Установка' })
                break
            case 6:
                status = this.intl.formatMessage({ id: 'Установка завершена' })
                break
        }
        if (!status) {
            status = this.getStatusName(id)
        }
        return status
    }

    getStatusName = (id, report) =>
        (report
            ? this.reportStatuses
            : isAdmin() || isAdministration() || isDealer() || isOperator()
            ? [...this.orderStatuses, ...this.reportOrderStatuses]
            : this.orderStatuses
        ).find((el) => +el.id === +id)?.name

    getStatusImage = (id, report) =>
        (report
            ? this.reportStatuses
            : isAdmin() || isAdministration() || isDealer() || isOperator()
            ? [...this.orderStatuses, ...this.reportOrderStatuses]
            : this.orderStatuses
        ).find((el) => +el.id === +id)?.imageSrc

    getServiceOrderStatusName = (id) => this.reportServiceOrderStatuses.find((el) => +el.id === +id)?.name
    getServiceOrderStatusImage = (id) => this.reportServiceOrderStatuses.find((el) => +el.id == +id)?.imageSrc

    getReportOrderStatusName = (id) => this.reportOrderStatuses.find((el) => +el.id === +id)?.name
    getReportOrderStatusImage = (id) => this.reportOrderStatuses.find((el) => +el.id === +id)?.imageSrc
    getReportStatusName = (id) => this.reportStatuses.find((el) => +el.id === +id)?.name
    getReportStatusImage = (id) => this.reportStatuses.find((el) => +el.id === +id)?.imageSrc

    getStatusesByRole(arg) {
        switch (true) {
            case isAdministration() && (arg !== 'DEV') :
                return [...this.orderStatuses, ...this.reportOrderStatuses].filter((el) => [1,7,11].includes(el.id))
            case isAdmin() || (arg === 'DEV'):
                return [...this.orderStatuses, ...this.reportOrderStatuses]
            case isDealer() && (arg !== 'DEV') || isDealerOperator() && (arg !== 'DEV'):
                const intersectionForRole = new Set([0, 2, 4, 5, 6,7, 12, 13, 14, 15, 16])
                return [...this.orderStatuses, ...this.reportOrderStatuses].filter((el) =>
                    intersectionForRole.has(el.id)
                )
            default:
                return []
        }
    }

    getServiceStatuses() {
        let result = [...this.reportServiceOrderStatuses]
        result = result.filter((status) => {
            if (status.id === 6.105 || status.id === 6.106) {
                return false
            }
            return true
        })
        if (isDealer()) {
            result = result.filter((status) => {
                if (status.id !== 1 && status.id !== 7 && status.id !== 10 && status.id !== 11) {
                    return true
                }
            })
        }
        if (isAdministration()) {
            result = result.filter((status) => ![7, 10].includes(status.id))
        }
        return result
    }
}

export default StatusesStore
