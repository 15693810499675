import {
    getOpeningBalanced,
    getPromiseTableData,
    postEditableCountCreate,
    postEditableCountUpdate,
    getEditableCountTotal,
} from '../../../services/ApiService'
import { makeAutoObservable } from 'mobx'
import DataSettingsFilterStore from './DataSettingsFilterStore'
import { getComp } from '../../../utils/utils/DI'
import moment from 'moment'
import { notifySuccess } from '../../../services/ToastifyService/ToastifyService'
import { closeModalFn } from '../../../utils/controllers/ModalController'

class DataSettingsStore {
    constructor() {
        this.filterStore = new DataSettingsFilterStore()
        this.authorsStore = getComp('AuthorsStore')
        this.dealersStore = getComp('DealersStore')
        makeAutoObservable(this)
    }

    // данные для дровера
    // установки по ОД - count
    // входящие остатки - initialCount
    fullfinanceSource = true
    commonData = {
        count: null,
        initialCount: null,
    }
    // переменная для запоминания предыдущего значения commonData
    rememberPrevCount = null
    // входящие данные
    openingBalance = null

    // контроль открытия дровера
    isDrawerOpen = false
    //режим изменения. аккордеон установок по ОД.
    countEditMode = false
    //режим изменения. аккордеон входящего остатка.
    initialValueEditMode = false

    // данные для таблицы
    tableData = []

    // параметры таблицы
    pageSize = 50
    pageNumber = 0
    totalSize = 0

    // состояние стора во время асинхронных запросов
    isSearch = false
    isReady = false
    isLoading = false

    // обновленный список дилеров с новым дилером "Все дилеры"
    updatedDealers = []
    // айди дилера "Все дилеры" стоит по умолчанию на странице
    allDealerId = '00000000-0000-0000-0000-000000000001'
    //Имя дилера по умолчанию
    currentDealerName = 'Все дилеры'
    // айди текущего дилера
    currentDataId = this.allDealerId

    showCount = false
    showInitialCount = false

    // контроль активности кнопок на странице
    disableSaveBtn = true

    // данные из строки таблицы. Отправляются в дровер при клике на строку таблицы
    tableRowData = null

    rememberCurrentData = null
    // стейт и функция для контроля инпута count в компоненте

    countInput = ''

    setFullfinanceSource = (value) => {
        this.fullfinanceSource = value
    }
    setCount = (e) => {
       
        let inputValue = e.target.value;
    
        
        inputValue = inputValue.replace(/[^0-9]/g, '');
    
       
        e.target.value = inputValue;
    
        if (inputValue != this.rememberCurrentData && inputValue > 0) {
            this.disableSaveBtn = false;
        } else {
            this.disableSaveBtn = true;
        }
    
        if (inputValue > 0 || inputValue === '') {
            this.countInput = inputValue;
        }
    };
    

    //изменение данных ОД
    handleChangeCommonData = ({ update }) => {
        if (update) {
            this.updateOrdersPromise({
                count: Number(this.countInput),
                promiseDate: moment().format('YYYY-MM-DD'),
                dealerId: this.currentDataId,
                financialSourceId: this.fullfinanceSource ? 1 : 2,
            })
        } else {
            this.createOrdersPromise({
                count: Number(this.countInput),
                promiseDate: moment().format('YYYY-MM-DD'),
                dealerId: this.currentDataId,
                financialSourceId: this.fullfinanceSource ? 1 : 2,
            })
        }
        this.countInput('')
        this.closeDrawer()
    }

    //изменение данных входящего остатка
    handleEditInitialCount = () => {
        if (this.getCurrentInitialCountInfo().count) {
            this.toggleInitialValueEditMode(false)
        }
        this.updateOrdersPromise({
            count: Number(this.countInput),
            promiseDate: null,
            dealerId: this.currentDataId,
            financialSourceId: this.fullfinanceSource ? 1 : 2,
        })
        // исправление Bug #27065
        // } else {
        //     this.createOrdersPromise({
        //         count: Number(this.countInput),
        //         promiseDate: null,
        //         dealerId: this.currentDataId,
        //         financialSourceId: this.fullfinanceSource? 1: 2
        //     })
        // }
        this.countInput = ''
    }

    // получение count ОД
    getTotalCount = async (params) => {
        this.isLoading = true
        return await getEditableCountTotal(params).then((res) => {
            this.isLoading = false

            this.commonData = {
                count: res.count,
                initialCount: res.initialCount,
            }

            this.rememberPrevCount = { count: res.count, initialCount: res.initialCount }
            closeModalFn['progress-backdrop']()
        })
    }

    // получение входящего остатка
    getInitialCount = async () => {
        return await getOpeningBalanced().then((res) => {
            this.openingBalance = res.records
            closeModalFn['progress-backdrop']()
        })
    }

    // получение данных для таблицы
    getTableData = async (params) => {
        return await getPromiseTableData(params)
            .then((res) => {
                this.tableData = res.content.map(this.normalizeResponse)
                this.pageSize = res.size
                this.pageNumber = res.page
                this.totalSize = res.totalSize
            })
            .then(() => {
                if (!this.updatedDealers.length) {
                    this.getUpdatedDealers()
                }
                closeModalFn['progress-backdrop']()
            })
    }

    // создание count ОД или создание входящих данных
    createOrdersPromise = async (params) => {
        await postEditableCountCreate(params)
            .then((res) => {
                notifySuccess('Данные обновлены')
                this.setCurrentRowFromResponse(res)
            })
            .catch(() => {
                this.disableSaveBtn = true
            })
            .finally(() => {
                this.getTotalCount({ FinancialSourceIds: this.fullfinanceSource ? 1 : 2 })
                this.getInitialCount()
                this.onSearch()
                this.disableSaveBtn = true
                closeModalFn['progress-backdrop']()
            })
    }

    // обновление count ОД или обновление входящих данных
    updateOrdersPromise = async (params) => {
        return await postEditableCountUpdate(params)
            .then((res) => {
                notifySuccess('Данные обновлены')
                this.setCurrentRowFromResponse(res)
            })
            .finally(() => {
                this.getTotalCount({ FinancialSourceIds: this.fullfinanceSource ? 1 : 2 })
                this.onSearch()
                this.disableSaveBtn = true
                this.getInitialCount()
                this.closeDrawer()
                closeModalFn['progress-backdrop']()
            })
    }

    getCurrentInitialCountInfo() {
        let initialCountInfo = this.openingBalance?.find((el) => {
            return this.fullfinanceSource ? el.financialSourceId === 1 : el.financialSourceId === 2
        })
        let normalizedInfo = {
            ...initialCountInfo,
            dealer: this.dealersStore.collection.find((dealer) => dealer.subjectId === initialCountInfo?.dealerId)
                ?.displayName,
            author: this.authorsStore.authors.find((author) => author.id === initialCountInfo?.authorId)?.name,
            updatedAt: moment(initialCountInfo?.updatedAt).format('DD.MM.YYYY, HH:mm'),
        }

        return normalizedInfo
    }

    // добавление в список дилеров Дилера с именем "Все дилеры"
    getUpdatedDealers = () => {
        //  копирую массив чтобы не изменять массив в дилерСторе
        const dealers = Object.assign([], this.dealersStore.collection)

        if (!dealers.find((el) => el.subjectId === this.currentDataId)) {
            dealers.unshift({
                displayName: 'Все дилеры',
                subjectId: this.allDealerId,
            })
        }

        this.updatedDealers = dealers
    }

    get selectedCollection() {
        return this.tableData
    }

    get currentFilters() {
        return this.filterStore.data
    }

    onSearch = () => {
        this.getTableData({
            month: this.filterStore.data.reportMonth,
            year: this.filterStore.data.reportYear,
            dealerid: this.filterStore.data.dealer?.subjectId,
            page: this.pageNumber,
            size: this.pageSize,
            FinancialSourceIds: this.fullfinanceSource ? 1 : 2,
        })
        return Promise.resolve().then(() => {closeModalFn['progress-backdrop']()})
    }

    setCurrentRowFromResponse = (response) => {
        this.tableRowData = this.normalizeResponse(response)
    }

    normalizeResponse = (response) => {
            return {
                ...response,
                author: this.authorsStore.authors.find((author) => author.id === response.authorId)?.name || 'Неизвестно',
                dealer: this.dealersStore.collection.find((dealer) => dealer.subjectId === response.dealerId)?.displayName || 'Все дилеры',
                updatedAt: moment(response.updatedAt).format('DD.MM.YYYY HH:mm'), //.add(3, 'hours'),
                promiseDate: moment(response.promiseDate).format('DD.MM.YYYY'),
            }
    }

    openDrawer = (e) => {
        this.toggleCountEditMode(false)
        this.toggleInitialValueEditMode(false)
        // если клик по таблице значит в событии есть данные. Устанавливаю их в качестве текущих для отображения в дровере
        if (e.data) {
            this.tableRowData = e.data
            this.currentDealerName = e.data.dealer
            this.showCount = true

            this.countInput = e.data.count
            this.rememberCurrentData = e.data.count
        }
        this.isDrawerOpen = true
    }

    closeDrawer = () => {
        this.currentDealerName = 'Все дилеры'
        this.tableRowData = null
        this.disableSaveBtn = true
        this.isDrawerOpen = false
        this.showInitialCount = false
        this.showCount = false
        this.countInput = ''

        this.drawerTitle = null
        this.disableRedWarningText = false
        this.updateMode = true
    }

    toggleCountEditMode = (value) => {
        this.countEditMode = value
    }

    toggleInitialValueEditMode = (value) => {
        this.initialValueEditMode = value
    }

    onPageChange = (e, number) => {
        this.pageNumber = number
    }

    onPageSizeChange = (e) => {
        this.pageNumber = 0
        this.pageSize = e.target.value
    }

    onClear = () => {
        this.filterStore.clear()
        this.isSearch = false
        this.onSearch()
    }

    drawerTitle = null
    disableRedWarningText = false
    updateMode = true
    openCountDrawer = (e) => {
        // this.countInput = this.commonData.count
        this.rememberCurrentData = this.commonData.count
        this.showCount = true

        // загловок для дровера открывающегося через кнопку Ввести данные.
        this.drawerTitle = this.fullfinanceSource
            ? 'Ввод оперативных данных, полная дотация'
            : 'Ввод оперативных данных, частичная дотация'
        this.disableRedWarningText = true // отключение отображениея красного текста в дровере
        if (this.tableData[0]?.promiseDate === moment().format('DD.MM.YYYY')) {
            this.tableRowData = this.tableData[0]
            this.countInput = this.tableRowData ? this.tableRowData.count : 0
        }
        this.updateMode = false

        this.openDrawer(e)
    }

    openInitialCountDrawer = (e) => {
        this.countInput = this.commonData.initialCount
        this.rememberCurrentData = this.commonData.initialCount

        this.showInitialCount = true
        this.openDrawer(e)
    }
}

export default DataSettingsStore
