import PagedCollectionStore from "../utils/stores/PagedCollectionStore"
import {getDealers} from "../services/ApiService"
import {makeObservable} from "mobx"

class DealersStore extends PagedCollectionStore {
    constructor() {
        super({
            action: getDealers,
        })
        makeObservable(this, {})
    }

    setData(data) {
        function deleteSubstr(str, substr1, substr2) {
            return str.toLowerCase().replace(substr1, "").replace(substr2, "").trim()
        }

        const sorted = data.sort((a, b) => {
            let aName = deleteSubstr(a.displayName, "ип", "ооо")
            let bName = deleteSubstr(b.displayName, "ип", "ооо")

            if (aName < bName) {
                return -1
            }
            if (aName > bName) {
                return 1
            }
            return 0
        })

        super.setData(sorted)
    }

    getDealer = (subjectId) => {
        
        return this.collection.find((el) => el.subjectId === subjectId)
    }
    getDealerName = (subjectId) => {
        const dealer = this.collection.find((el) => el.subjectId === subjectId)
        return dealer ? dealer.displayName : subjectId
    }
    getDealerBuyAid = (aid) => {
        return this.collection.find((el) => el.aid === aid)
    }
}

export default DealersStore
