import React from 'react'
import { Checkbox, TableCell } from '@mui/material'
import { observer } from 'mobx-react'
import SummaryStatisticsDealersStoreInstance from '../../store/SummaryStatisticsDealersStore'

export const BodyCheckbox = observer(({ row, propName }) => {
    const { toggleChecked } = SummaryStatisticsDealersStoreInstance
    const { checked } = row

    const handleClickCheckbox = (event) => {
        event.stopPropagation()
        toggleChecked(row)
    }

    return (
        <TableCell key={propName} sx={{ padding: '0' }}>
            <Checkbox checked={checked} onClick={handleClickCheckbox} />
        </TableCell>
    )
})
