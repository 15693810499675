import React, {useState} from "react"
import {observer} from "mobx-react"
import LoginStore from "./stores/LoginStore"
import FormInput from "../../components/customElements/FormInput"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import eye from "../../assets/images/common/eye.svg"
import "./styles/loginStyles.css"
import {FormattedMessage, useIntl} from "react-intl"
import moment from "moment/moment"
import {signIn, signUp} from "src/services/AccountService"
import {useAuth} from "src/services/AuthProvider"
import {getStyles} from "../../utils/utils/commonUtils"
import commonStyles from "../../styles/commonStyles"

function Login() {
    const classes = getStyles(commonStyles)
    const intl = useIntl()
    const auth = useAuth()
    const [store] = useState(() => new LoginStore({signIn: auth.login}))
    const [showPassword, setShowPassword] = useState("false")

    const {invite, toggleInvite, data, login, handleEnterPress} = store

    return (
        <Box onKeyPress={handleEnterPress}>
            <Typography variant={"h4"} color={"primary"}>
                <FormattedMessage id={"Вход"} />
            </Typography>

            <Stack name={"fields"} mt={8} spacing={3}>
                <FormInput api={store} propName={"login"} label={intl.formatMessage({id: "Логин"})} fullWidth />

                <FormInput
                    api={store}
                    propName={"password"}
                    label={intl.formatMessage({id: "Пароль"})}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <img
                                alt={""}
                                src={eye}
                                onClick={() => setShowPassword(!showPassword)}
                                className={`pointer ${!showPassword ? "eye-opacity" : ""}`}
                            />
                        ),
                        type: !showPassword ? "text" : "password"
                    }}
                />

                {/*TODO SignUp process*/}
                {/*{invite ? (*/}
                {/*    <>*/}
                {/*        <FormInput*/}
                {/*            api={store}*/}
                {/*            propName={'inviteId'}*/}
                {/*            label={'Invitation code'}*/}
                {/*            fullWidth*/}
                {/*        />*/}

                {/*        <Box width={1} mt={1.5} className={'jst-center'}>*/}
                {/*            <IconButton*/}
                {/*                onClick={toggleInvite}*/}
                {/*            >*/}
                {/*                <img alt={''} src={crossIcon} className={'opacity50'} />*/}
                {/*            </IconButton>*/}
                {/*        </Box>*/}
                {/*    </>*/}
                {/*) : (*/}
                {/*    <Button*/}
                {/*        variant={'text'}*/}
                {/*        color={'primary'}*/}
                {/*        size={'large'}*/}
                {/*        fullWidth*/}
                {/*        onClick={toggleInvite}*/}
                {/*    >*/}
                {/*        Enter invitation code*/}
                {/*    </Button>*/}
                {/*)}*/}
            </Stack>

            <Box name={"button"} mt={8}>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    fullWidth
                    onClick={login}
                    sx={classes.buttonActive}
                    disabled={!data.login || !data.password}
                >
                    <FormattedMessage id={"Войти"} />
                </Button>
            </Box>
        </Box>
    )
}

export default observer(Login)
