import React, {useEffect} from "react"
import {observer} from "mobx-react"
import Box from "@mui/material/Box"
import {getStyles} from "../utils/utils/commonUtils"
import commonStyles from "../styles/commonStyles"
import AppBar from "@mui/material/AppBar"
import logo from "../assets/images/layout/spelled_logo.svg"
import PageFooter from "./customElements/PageFooter"
import Paper from "@mui/material/Paper"
import {useNavigate} from "react-router-dom"
import {setGState} from "../utils/controllers/GlobalStateController"

function PublicContent({children}) {
    const classes = getStyles(commonStyles)
    const navigate = useNavigate()
    useEffect(() => {
        setGState("navigate", navigate)
    }, [])

    return (
        <Box width={1} className={"fx-center fx-col public-page"}>
            <AppBar sx={classes.appbar}/>

            <Box className={"fx-center fx-col"} sx={{height: "calc(100vh - 32px)"}}>
                <Paper sx={classes.publicContentPaper}>
                    {children}
                </Paper>
            </Box>

            <PageFooter/>
        </Box>
    )
}

export default observer(PublicContent)