import { Button } from '@mui/material'
import { observer } from 'mobx-react'
import React from 'react'

const CompleteServiceBtn = ({ action, disabled }) => {
    const handleAction = () => {
        action()
    }
    return (
        <Button onClick={handleAction} fullWidth variant='contained' disabled={disabled}>
            Завершить обслуживание
        </Button>
    )
}

export default observer(CompleteServiceBtn)
