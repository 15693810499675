import React from 'react'
import { observer } from 'mobx-react'
import { Box, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material'
import { store } from '../../store/EditorStore'
import selectArrow from 'src/assets/images/common/selectArrow.svg'

export const SearchForm = observer(() => {
    const { territoryStore, territory, setTerritory, district, setDistrict, getDistricts } = store
    const setSelectArrow = (e) => <img className={e.className} alt={''} src={selectArrow} />
    return (
        <Box
            sx={{
                display: 'flex',
                gap: '24px',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: '10px',
            }}
        >
            <FormControl fullWidth key={1}>
                <InputLabel>Регион</InputLabel>
                <Box sx={{ position: 'relative' }}>
                    <Select
                        name={'Регион'}
                        value={territory}
                        label={'Регион'}
                        fullWidth
                        IconComponent={setSelectArrow}
                        onChange={(event) => setTerritory(event.target.value)}
                        SelectDisplayProps={{
                            onKeyDown: (event) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    onSearch(filter)
                                }
                            },
                        }}
                    >
                        {territoryStore.collection.map((el) => (
                            <MenuItem key={el} value={el}>
                                {el}
                            </MenuItem>
                        ))}
                    </Select>
                    {territory && (
                        <IconButton
                            onClick={() => setTerritory('')}
                            sx={{
                                position: 'absolute',
                                right: '45px', 
                                top: '48%',
                                transform: 'translateY(-50%)',
                                color: '#000',
                                padding: '0px',
                                '&:hover': {
                                    backgroundColor: "transparent",
                                },
                            }}
                            size='small'
                            aria-label='clear selection'
                        >
                            &#x2715;
                        </IconButton>
                    )}
                </Box>
            </FormControl>
            <FormControl fullWidth key={1}>
                <InputLabel>{'Округ'}</InputLabel>
                <Select
                    name={'Округ'}
                    value={district}
                    label={'Округ'}
                    fullWidth
                    disabled={!territory}
                    IconComponent={!territory ? null : setSelectArrow}
                    onChange={(event) => setDistrict(event.target.value)}
                    SelectDisplayProps={{
                        onKeyDown: (event) => {
                            if (event.key === 'Enter') {
                                event.preventDefault()
                                event.stopPropagation()
                                onSearch(filter)
                            }
                        },
                    }}
                >
                    {getDistricts()?.map((el) => (
                        <MenuItem key={el} value={el}>
                            {el}
                        </MenuItem>
                    ))}
                </Select>
                {district && (
                        <IconButton
                            onClick={() => setDistrict('')}
                            sx={{
                                position: 'absolute',
                                right: '45px', 
                                top: '48%',
                                transform: 'translateY(-50%)',
                                color: '#000',
                                padding: '0px',
                                '&:hover': {
                                    backgroundColor: "transparent",
                                },
                            }}
                            size='small'
                            aria-label='clear selection'
                        >
                            &#x2715;
                        </IconButton>
                    )}
            </FormControl>
        </Box>
    )
})
