import CustomDialog from "../CustomDialog/CustomDialog"
import Carousel from "../Carousel/Carousel"
import React, {useEffect} from "react"
import {isModalOpen, modalData} from "../../utils/controllers/ModalController"
import {observer} from "mobx-react-lite"
import {checkMobileDevice, getStyles} from "../../utils/utils/commonUtils"
import photoGalleryDialogStyles from "./styles/photoGalleryDialogStyles"
import CarouselCustomBtn from "./CarouselCustomBtn"
import CustomTopPanel from "./CustomTopPanel"
import PhotoTools from "./PhotoTools"
import {useState} from "react"
import {notifyError, notifySuccess} from "../../services/ToastifyService/ToastifyService"
const PhotoGalleryDialog = () => {
    const data = modalData["photo-gallery"]
    const classes = getStyles(photoGalleryDialogStyles)
    const collectionLength = data?.collection?.length
    const collectionName = data?.name
    const [zoom, setZoom] = useState(false)
    const [moveMode, setMoveMode] = useState(false)
    const [coords, setCoords] = useState({x: 50, y: 50})
    const [photoToDownloadIndex, setPhotoToDownloadIndex] = useState(null)
    const downloadFunc = () => {
        const photo = data?.collection[photoToDownloadIndex]
        // const photoId = data?.collection[photoToDownloadIndex].id
        // const photoUrl = window.SAT.config.apiUrl + `/images/getimage?imageId=${photoId}`
        let img = document.querySelector(".carousel-slide.active-slide > img")

        const link = document.createElement("a")
        link.href = photo.url
        link.download = `photo_${collectionName}_${photoToDownloadIndex}.jpg`
        link.target = "_blank"
        // document.body.appendChild(link)
        link.click()
        // document.body.removeChild(link)

        // let link = document.createElement("a")
        // link.download = "hello.txt"
        // let blob = new Blob(["Yo!"], {type: "text/plain"})
        // link.href = URL.createObjectURL(blob)
        // link.click()
        // URL.revokeObjectURL(link.href)
    }

    useEffect(() => {
        setPhotoToDownloadIndex(data?.initialIndex)
    }, [data])

    return (
        <CustomDialog
            open={isModalOpen["photo-gallery"]}
            styles={classes.customDialog}
            customTopPanel
            hideBackdrop={!checkMobileDevice()} // на мобильных бекдроп не должен скрываться
        >
            <CustomTopPanel collectionLength={collectionLength} collectionName={collectionName} />
            {isModalOpen["photo-gallery"] ? (
                <>
                    <Carousel
                        slides={data?.collection.map((el) => el.url)}
                        initialIndex={data?.initialIndex}
                        customBtn={<CarouselCustomBtn />}
                        zoom={zoom}
                        setZoom={setZoom}
                        setMoveMode={setMoveMode}
                        moveMode={moveMode}
                        coords={coords}
                        setCoords={setCoords}
                        setPhotoToDownloadIndex={setPhotoToDownloadIndex}
                    />
                    <PhotoTools
                        setZoom={setZoom}
                        setMoveMode={setMoveMode}
                        moveMode={moveMode}
                        zoom={zoom}
                        downloadFunc={downloadFunc}
                    />
                </>
            ) : null}
        </CustomDialog>
    )
}

export default observer(PhotoGalleryDialog)
