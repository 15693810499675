import React, { useEffect } from 'react'
import CustomAccordion from '../../../../components/customElements/RequestDataAccordions/utils/CustomAccordion'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { Box, Button, TextField, Typography } from '@mui/material'
import DrawerInfoElement from './DrawerInfoElement'
import { observer } from 'mobx-react'
import { getStyles } from '../../../../utils/utils/commonUtils'
import dataSettingsStyles from '../../styles/dataSettingsStyles'

const CountAccordion = ({ store }) => {
    const {
        tableRowData,
        onEdit = true,
        countEditMode,
        toggleCountEditMode,
        commonData,
        countInput,
        setCount,
        disableSaveBtn,
        handleChangeCommonData,
        handleEditInitialCount,
        showCount,
        tableData,
        disableRedWarningText,
        updateMode,
    } = store
    
    
    const intl = useIntl()
    const classes = getStyles(dataSettingsStyles)
    if (showCount) {
        return (
            <CustomAccordion
                summary={intl.formatMessage({ id: 'Количество установок ОД' })}
                defaultOpenState={true}
                onEdit={
                    (onEdit && !tableRowData) ||
                    // (onEdit && !tableRowData && store.getCurrentInitialCountInfo().count) ||
                    (onEdit &&
                        tableRowData &&
                        moment(tableRowData?.updatedAt, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY') ===
                            moment().format('DD.MM.YYYY') &&
                        store.getCurrentInitialCountInfo().count)
                        ? () => toggleCountEditMode(!countEditMode)
                        :  store.getCurrentInitialCountInfo().count === 0?  () => toggleCountEditMode(!countEditMode) : null
                }
            >
                <DrawerInfoElement
                    subtitle={'Оперативные данные на дату'}
                    info={tableRowData ? tableRowData.promiseDate : moment().format('DD.MM.YYYY')}
                />
                {countEditMode ? (
                    <TextField
                        // type={'number'}
                        value={countInput}
                        onChange={setCount}
                        placeholder={'Количество установок'}
                        sx={{ width: '100%', marginTop: '16px' }}
                    />
                ) : (
                    <DrawerInfoElement subtitle={'Количество установок'} info={tableRowData?.count || '-'} />
                )}
                <DrawerInfoElement subtitle={'Дата внесения / редактирования'} info={tableRowData?.updatedAt || '-'} />
                <DrawerInfoElement subtitle={'Автор'} info={tableRowData?.author || '-'} />

                {countEditMode ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
                        <Button
                            disabled={disableSaveBtn}
                            onClick={() => handleChangeCommonData({ update: updateMode })}
                            sx={{ width: '100%' }}
                            variant='contained'
                        >
                            Сохранить
                        </Button>
                    </Box>
                ) : null}

                {!disableRedWarningText && (
                    <Typography sx={classes.warningText}>
                        {/* {store.getCurrentInitialCountInfo().count
                            ? 'Внимание! Для ввода оперативных данных для текущего дилера необходимо сначала ввести данные Входящего остатка.'
                            : 'Внимание! Редактирование оперативных данных возможно только в течение того дня, за который они вносятся.'} */}
                        Внимание! Редактирование оперативных данных возможно только в течение того дня, за который они
                        вносятся.
                    </Typography>
                )}
            </CustomAccordion>
        )
    }
}

export default observer(CountAccordion)
