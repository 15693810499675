import { Box, MenuItem } from '@mui/material'
import { useIntl } from 'react-intl'
import NewFilters from '../../../components/NewFilters/NewFilters'
import { closeModalFn, openModalFn } from '../../../utils/controllers/ModalController'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import statisticsActivityFilterStore from '../stores/StatisticsActivityFiltersStore'
import statisticsActivityStore from '../stores/StatisticsActivityTableStore'
import { getComp } from '../../../utils/utils/DI'
import RequestFilterChip from '../../RequestsManagement/components/NewRequestFilters/components/RequestFilterChip'
const StatisticsActivityFilters = () => {
    const authorsStore = getComp('AuthorsStore')
    const intl = useIntl()
    const { rows, getData } = statisticsActivityStore
    useEffect(() => {
        statisticsActivityFilterStore.clear()
    },[])
    
    const filterData = [
        {
            type: 'select',
            propName: 'subjectList',
            name: intl.formatMessage({ id: 'Автор' }),
            collection: authorsStore.authors,
            multiple: true,
            setMenuItem: (data) => {
                return (
                    <MenuItem key={data.id} value={data.id}>
                        {data.name}
                    </MenuItem>
                )
            },
        },
        [
            { type: 'date', propName: 'from', name: intl.formatMessage({ id: 'Дата активности от' }) },
            { type: 'date', propName: 'to', name: intl.formatMessage({ id: 'Дата активности до' }) },
        ],
    ]

    const getFiltersData = () => {
        let availableFilters = [
            
            ['subjectList', 'from'],
        ]

        const setFilterData = () =>
            availableFilters.reduce((acc, filtersArr, i) => {
                const newFilters = filtersArr.map((propName) => {
                    return filterData.find((el) => {
                        if (Array.isArray(el)) {
                            return el[0].propName === propName
                        }
                        return el.propName === propName
                    })
                })
                acc.push(newFilters)
                return acc
            }, [])
        return setFilterData()
    }

    const onSearch = async () => {
        openModalFn['progress-backdrop']()
        const param = Object.entries(statisticsActivityFilterStore.data)
            .filter(([key, value]) => value !== '' && value !== null && value !== undefined)
            .reduce((acc, [key, value]) => {
                if (['from', 'to'].includes(key)) {
                    acc[key] = value.format('YYYY-MM-DDTHH:mm:ssZ')
                } else {
                    acc[key] = value
                }
                return acc
            }, {})
        getData({markerRoleList: 4, ...param})
        statisticsActivityFilterStore.setSelectedFilters()
        statisticsActivityFilterStore.makeHighlightTrue()
        closeModalFn['progress-backdrop']()
    }

    const handleDeleteChip = (propName, multiselectValue) => {
        if (propName === 'from') {
            statisticsActivityFilterStore.deleteFilter('to', multiselectValue)
        }
        statisticsActivityFilterStore.deleteFilter(propName, multiselectValue)
        onSearch()
    }
    const setChip = (params, withAction) => <RequestFilterChip {...params} onClick={withAction && handleDeleteChip} />

    return (
        <Box mb={2}>
            <NewFilters
                title={intl.formatMessage(
                    {
                        id: 'Найдено записей {reports}',
                        defaultMessage: 'Найдено записей {reports}',
                    },
                    {
                        reports: rows.length,
                    }
                )}
                setChip={setChip}
                filtersStore={statisticsActivityFilterStore}
                filtersData={getFiltersData()}
                onSearch={onSearch}
                filtersContainerHeight={'150px'}
            />
        </Box>
    )
}

export default observer(StatisticsActivityFilters)
