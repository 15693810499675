import FormStore from '../../../utils/stores/FormStore'

const initState = {
    dealerId: '',
    equipmentId: '',
    territory: '',
    district: '',
    locality: '',
    addressType: '',
    address: '',
    house: '',
    building: '',
    apartment: '',
    created: '',
    surname: '',
    name: '',
    patronymic: '',
    phone: '',
    preferentialCategory: '',
    senderId: '',
    installed: '',
    categories: [],
    reasonId: '',
}

const validateData = {
    dealerId: { required: true },
    equipmentId: { required: true },
    territory: { required: true },
    district: { required: true },
    locality: { required: true },
    address: { required: true },
    surname: { required: true },
    name: { required: true },
    phone: { required: true },
    senderId: { required: true },
}

class AdminAddFormStore extends FormStore {
    constructor() {
        super({ initState, validateData })
        this.showErrors = true
        this.isValid = false
    }
}

export default AdminAddFormStore
