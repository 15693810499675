import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import moment from 'moment'
import { getComp } from '../../../utils/utils/DI'
import { gState } from '../../../utils/controllers/GlobalStateController'
import Box from '@mui/material/Box'
import FormInput from '../../customElements/FormInput'
import MultipleSelect from '../../customElements/MultipleSelect/MultipleSelect'
import { placeNames } from 'src/helpers/globalVariables'
import CustomAccordion from '../../customElements/RequestDataAccordions/utils/CustomAccordion'

const AddAdminForm = ({ adminAddFormStore, baseInputsFormStore }) => {
    const intl = gState['intl']
    const { shownSenders, defaultSenders } = getComp('SendersStore')
    const territoryStore = getComp('TerritoryStore')
    const categoriesStore = getComp('CategoriesStore')
    const dealersStore = getComp('DealersStore')

    // // меняет стили обязательных полей
    // useEffect(() => {
    //     adminAddFormStore.showErrors = !baseInputsFormStore.data['reasonId'] ? false : true
    // }, [baseInputsFormStore.data['reasonId']])

    const alternativeEditData = [
        {
            type: 'select',
            
            label: intl.formatMessage({ id: 'Дилер' }),
            validName: 'dealerId',
            propName: 'dealerId',
            items: dealersStore.collection.map((dealer) => {
                return { value: dealer.subjectId, key: dealer.displayName }
            }),
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        {
            type: 'text',
            label: intl.formatMessage({ id: 'Id оборудования' }),
            propName: 'equipmentId',
            validName: 'equipmentId',
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        {
            propName: 'territory',
            validName: 'territory',
            label: intl.formatMessage({ id: 'Регион' }),
            items: territoryStore.collection,
            type: 'select',
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        {
            propName: 'district',
            validName: 'district',
            label: intl.formatMessage({ id: 'Округ' }),
            autocompleteOptions: { territory: adminAddFormStore.data['territory'], type: 'district' },
            readOnly: adminAddFormStore.data.territory === '',
            disabled: adminAddFormStore.data.territory === '',
            territorySelect: true,
            type: 'autocomplete',
        },
        {
            propName: 'locality',
            validName: 'locality',
            label: intl.formatMessage({ id: 'Населенный пункт' }),
            territorySelect: true,
            autocompleteOptions: {
                territory: adminAddFormStore.data['territory'],
                district: adminAddFormStore.data['district'],
                type: 'locality',
            },
            readOnly: adminAddFormStore.data.district === '',
            disabled: adminAddFormStore.data.district === '',
            type: 'autocomplete',
        },
        {
            propName: 'addressType',
            validName: 'addressType',
            label: intl.formatMessage({ id: 'Топоним (улица / переулок и т.д.)' }),
            type: 'autocomplete',
            items: placeNames,
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        {
            propName: 'address',
            validName: 'address',
            label: intl.formatMessage({ id: 'Наименование топонима (улицы / переулка и т.д.)' }),
            type: 'text',
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        {
            propName: 'created',
            validName: 'created',
            label: intl.formatMessage({ id: 'Дата обращения' }),
            mask: '99-99-9999, 99:99',
            placeholder: 'DD-MM-YYYY, HH:mm',
            type: 'text',
            defaultValue: moment().format('DD-MM-YYYY, HH:mm'),
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        {
            propName: 'surname',
            validName: 'surname',
            label: intl.formatMessage({ id: 'Фамилия' }),
            type: 'text',
            capitalizeFirstLetter: true,
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        {
            propName: 'name',
            validName: 'name',
            label: intl.formatMessage({ id: 'Имя' }),
            type: 'text',
            capitalizeFirstLetter: true,
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        {
            propName: 'patronymic',
            validName: 'patronymic',
            label: intl.formatMessage({ id: 'Отчество' }),
            type: 'text',
            capitalizeFirstLetter: true,
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        {
            propName: 'phone',
            mask:"+7 (999)-999-99-99",
            label: intl.formatMessage({ id: 'Телефон' }),
            type: 'text',
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        {
            propName: 'categories',
            validName: 'categories',
            label: intl.formatMessage({ id: 'Льготные категории' }),
            items: categoriesStore.collection.map((el) => {
                return { key: el.name, value: el.id }
            }),
            multiple: true,
            type: 'multiple',
            onClear: adminAddFormStore.onClearMultiple,
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        {
            propName: 'senderId',
            validName: 'senderId',
            label: intl.formatMessage({ id: 'Источник данных о заявке' }),
            items: shownSenders.map((el) => ({ key: el.name, value: el.id })),
            defaultValue:
                defaultSenders && shownSenders.find((sender) => sender.id === defaultSenders) ? defaultSenders : '',
            type: 'select',
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        {
            propName: 'installed',
            validName: 'installed',
            label: intl.formatMessage({ id: 'Дата установки' }),
            mask: '99-99-9999, 99:99',
            placeholder: 'DD-MM-YYYY, HH:mm',
            type: 'text',
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
            
        },
    ]

    const validateFilterInputs = (event, propName) => {
        if (propName === 'phoned') {
            const phonePattern = /^[0-9()-]+$/
            return phonePattern.test(event.target.value) || event.target.value === '' ? true : false
        }
        if (propName === 'equipmentId') {
            if (event) {
                if (event.target.value.length > 14) return false
                const idPattern = /^[0-9]+$/
                return idPattern.test(event.target.value) || event.target.value === '' ? true : false
            }
        }
        return true
    }
    
    return (
        <CustomAccordion summary={'Данные установки'} defaultOpenState={true} preventHandleOpen={true}>
            {alternativeEditData.map((el, i) => {
                const {
                    propName,
                    label,
                    items,
                    mask,
                    defaultValue,
                    autocompleteOptions,
                    multiple,
                    readOnly,
                    disabled,
                    type,
                    visible,
                    territorySelect,
                    onClear,
                    capitalizeFirstLetter,
                    styles,
                    multiline,
                    rows,
                    
                } = el

                return (
                    <Box mt={i ? 1.5 : 0} key={i + 'createOrderInput'} sx={{ marginTop: '12px' }}>
                        {multiple ? (
                            <MultipleSelect
                                items={items}
                                value={adminAddFormStore.data.categories}
                                onChange={adminAddFormStore.onChangeMultiple}
                                label={label}
                                propName={propName}
                                readOnly={readOnly}
                                disabled={disabled}
                                onClear={onClear}
                            />
                        ) : (
                            <Box mt={i ? 1.5 : 0} key={i + ''}>
                                <FormInput
                                    api={adminAddFormStore}
                                    type={type}
                                    autocompleteOptions={autocompleteOptions}
                                    items={!!items ? items : null}
                                    propName={propName}
                                    fullWidth
                                    defaultValue={defaultValue}
                                    mask={mask}
                                    label={label}
                                    withoutErrorText
                                    readOnly={readOnly}
                                    disabled={disabled}
                                    visible={visible}
                                    territorySelect={territorySelect}
                                    capitalizeFirstLetter={capitalizeFirstLetter}
                                    styles={styles}
                                    multiline={multiline}
                                    validateFilterInputs={validateFilterInputs}
                                />
                            </Box>
                        )}
                    </Box>
                )
            })}
        </CustomAccordion>
    )
}

export default observer(AddAdminForm)
