import {getBackendVersion} from "../services/ApiService"
import {gState} from "../utils/controllers/GlobalStateController"
import {makeAutoObservable} from "mobx"

class ProjectVersionStore {
    constructor() {
        this.intl = gState["intl"]
        this.versionData = null
        makeAutoObservable(this)
    }

    load = async () => {
        await Promise.all([
            getBackendVersion(),
            fetch(`${window.location.origin}/version.json`).then((res) => res.json())
        ]).then((res) => {
            let version = " "
            res.forEach((ver, index) => {
                if (index > 0) {
                    ver.version = "_" + ver.version
                }
                version = version + ver.version
            })
            this.versionData = version
        })
    }
}

export default ProjectVersionStore
