import { MotionPhotosAuto } from '@mui/icons-material'
import { Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import lupe from 'src/assets/images/common/lupeWhite.svg'
import selectArrow from 'src/assets/images/common/selectArrow.svg'
import { store } from '../../store/StatisticsInstallationSubsidiesStore'
import { getComp } from '../../../../utils/utils/DI'

export const BlockFilters = observer(({ setOpenFilters }) => {
    const territoryStore = getComp('TerritoryStore')
    const {
        stateRequest,
        setStateRequest,
        month,
        setMonth,
        year,
        setYear,
        territory,
        setTerritory,
        district,
        setDistrict,
        getRows,
        clear,
    } = store
    const months = [
        { month: 'Январь' },
        { month: 'Февраль' },
        { month: 'Март' },
        { month: 'Апрель' },
        { month: 'Май' },
        { month: 'Июнь' },
        { month: 'Июль' },
        { month: 'Август' },
        { month: 'Сентябрь' },
        { month: 'Октябрь' },
        { month: 'Ноябрь' },
        { month: 'Декабрь' },
    ]
    const years = [{ year: '2023' }, { year: '2024' }]

    const intervalType = 'За отчётный период'
    const statesRequest = ['Созданные', 'Подтвержденные']

    const onSearch = () => {
        getRows(), setOpenFilters(false)
    }

    const onClear = () => {
        clear(), setOpenFilters(false)
    }

    const notPeriod = intervalType !== 'За отчётный период'

    const setSelectArrow = (e) => <img className={e.className} alt={''} src={selectArrow} />

    const getDistricts = () => {
        return territoryStore.territories[territory]?.Territory?.Districts?.map((el) => {
            return el.Name
        })
    }

    const getLocalitiesWithCategories = (territory) => {
        const whiteList = [];
        const nonWhiteList = [];
    
        territoryStore.territories[territory]?.Territory?.Districts?.forEach((district) => {
            district.Localities?.forEach((locality) => {
                if (locality.AllowedFinancialSourceIds?.includes(1)) {
                    whiteList.push(locality.Name);
                } else {
                    nonWhiteList.push(locality.Name);
                }
            });
        });
    
        return { whiteList, nonWhiteList };
    }

    const getLocalities = (territory) => {
        return territoryStore.territories[territory]?.Territory?.Districts?.flatMap((district) => {
            return district.Localities?.map((locality) => locality.Name);
        });
    }
    
    return (
        <Box sx={{ display: 'flex', gap: '16px' }}>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', gap: '8px', marginTop: '16px' }}>
                    <FormControl fullWidth key={1}>
                        <InputLabel>{'Состояние заявки'}</InputLabel>
                        <Select
                            name={'Состояние заявки'}
                            value={stateRequest}
                            label={'Состояние заявки'}
                            fullWidth
                            IconComponent={setSelectArrow}
                            onChange={(event) => setStateRequest(event.target.value)}
                            SelectDisplayProps={{
                                onKeyDown: (event) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault()
                                        event.stopPropagation()
                                        onSearch(filter)
                                    }
                                },
                            }}
                        >
                            {statesRequest.map((el) => (
                                <MenuItem key={el} value={el}>
                                    {el}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth key={1}>
                        <InputLabel>{'Регион'}</InputLabel>
                        <Select
                            name={'Регион'}
                            value={territory}
                            label={'Регион'}
                            fullWidth
                            IconComponent={setSelectArrow}
                            onChange={(event) => setTerritory(event.target.value)}
                            SelectDisplayProps={{
                                onKeyDown: (event) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault()
                                        event.stopPropagation()
                                        onSearch(filter)
                                    }
                                },
                            }}
                        >
                            {territoryStore.collection.map((el) => (
                                <MenuItem key={el} value={el}>
                                    {el}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth key={1}>
                        <InputLabel>{'Округ / район'}</InputLabel>
                        <Select
                            name={'Округ / район'}
                            value={district}
                            label={'Округ / район'}
                            fullWidth
                            disabled={!territory}
                            IconComponent={!territory ? null : setSelectArrow}
                            onChange={(event) => setDistrict(event.target.value)}
                            
                            SelectDisplayProps={{
                                onKeyDown: (event) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault()
                                        event.stopPropagation()
                                        onSearch(filter)
                                    }
                                },
                            }}
                        >
                            {getDistricts()?.map((el) => (
                                <MenuItem key={el} value={el}>
                                    {el}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', gap: '8px', marginTop: '16px' }}>
                    <FormControl fullWidth>
                        <InputLabel>{'Месяц'}</InputLabel>
                        <Select
                            name={'Месяц'}
                            value={month}
                            label={'Месяц'}
                            fullWidth
                            disabled={notPeriod}
                            IconComponent={notPeriod ? null : setSelectArrow}
                            onChange={(event) => setMonth(event.target.value)}
                            // {...props}
                            // inputProps={{ ...inputProps }}
                            // multiple={multiple}
                            SelectDisplayProps={{
                                onKeyDown: (event) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault()
                                        event.stopPropagation()
                                        onSearch(filter)
                                    }
                                },
                            }}
                        >
                            {months.map((el, index) => (
                                <MenuItem key={el.month} value={index + 1}>
                                    {el.month}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth key={1}>
                        <InputLabel>{'Год'}</InputLabel>
                        <Select
                            name={'Год'}
                            value={year}
                            label={'Год'}
                            fullWidth
                            disabled={notPeriod}
                            IconComponent={notPeriod ? null : setSelectArrow}
                            onChange={(event) => setYear(event.target.value)}
                            // {...props}
                            // inputProps={{ ...inputProps }}
                            // multiple={multiple}
                            SelectDisplayProps={{
                                onKeyDown: (event) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault()
                                        event.stopPropagation()
                                        onSearch(filter)
                                    }
                                },
                            }}
                        >
                            {years.map((el) => (
                                <MenuItem key={el.year} value={el.year}>
                                    {el.year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth key={1}>
                        <InputLabel>{'Представление по дням'}</InputLabel>
                        <Select
                            name={'Представление по дням'}
                            value={''}
                            label={'Представление по дням'}
                            fullWidth
                            disabled={true}
                            IconComponent={true ? null : setSelectArrow}
                            onChange={() => {}}
                            // {...props}
                            // inputProps={{ ...inputProps }}
                            // multiple={multiple}
                            SelectDisplayProps={{
                                onKeyDown: (event) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault()
                                        event.stopPropagation()
                                        onSearch(filter)
                                    }
                                },
                            }}
                        >
                            {[].map((el) => (
                                <MenuItem key={el.interval} value={el.interval}>
                                    {el.interval}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column-reverse', gap: '16px', width: '323px' }}>
                <Button
                    variant={'outlined'}
                    color={'primary'}
                    onClick={onClear}
                    sx={{ width: '100%', maxWidth: '323px' }}
                    fullWidth={true}
                >
                    <FormattedMessage id={'Сбросить'} />
                </Button>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    type='submit'
                    disabled={
                        (intervalType === 'За отчётный период' && month && !year) ||
                        (intervalType === 'За отчётный период' && !month && year)
                    }
                    fullWidth={true}
                    onClick={onSearch}
                    sx={{ width: '100%', maxWidth: '323px' }}
                >
                    <img alt={'Поиск'} src={lupe} />
                    <FormattedMessage id={'Hайти'} />
                </Button>
            </Box>
        </Box>
    )
})
