import {gState} from "../utils/controllers/GlobalStateController"
import {makeAutoObservable} from "mobx"
import {getDefaultSenders, getSenders} from "../services/ApiService"

class SendersStore {
    allSenders = []
    shownSenders = []
    defaultSenders = []

    constructor() {
        makeAutoObservable(this)
    }

    load = () => {
        getSenders(true).then((res) => {
            this.allSenders = res
            this.shownSenders = res.filter(el => !el.isHided)
        })
        getDefaultSenders().then(res => {
            this.defaultSenders = res
        })
    }

    getSenderName = (id) => {
        const sender = this.allSenders.find(el => el.id === id)
        return sender ? sender.name : ""
    }
    getSenderId = (name) => {
        const sender = this.allSenders.find(el => el.name === name)
        return sender ? sender.id : ""
    }

}

export default SendersStore