import OrdersStore from "src/stores/OrdersStore"
import SelectOrdersStore from "src/stores/SelectOrdersStore"
import RequestsFilterStore from "src/views/RequestsManagement/stores/RequestsFilterStore"
import {closeModalFn, openModalFn} from "src/utils/controllers/ModalController"
import {getOrders, markAgreementAsPrinted} from "src/services/ApiService"
import {convertId} from "src/helpers/ordersUtiles"


class AgreementsStore {
    constructor() {
        this.ordersStore = new OrdersStore({isAgreement: true})
        this.selectOrdersStore = new SelectOrdersStore({ordersStore: this.ordersStore, from: "agreement"})
        this.filterStore = new RequestsFilterStore()
    }

    createAgreements = (callback) => {
        openModalFn["progress-backdrop"]()
        const collection = Array.from(this.selectOrdersStore.selectedOrders).sort((a,b) => a - b).map(id => {
            return this.ordersStore.collection.find(el => el.id === id)
        })
        const fileName = `Договоры_для_заявок_${collection.length > 1 ? convertId(collection[0].id) + "-" + convertId(collection[collection.length - 1].id) : convertId(collection[0].id)}.pdf`
        callback(collection,fileName)
            .then((result) => {
                const fileUrl = URL.createObjectURL(result)
                let link = document.createElement("a")
                link.href = fileUrl
                link.download = fileName
                link.click()
                link.remove()
            }).finally(() => closeModalFn["progress-backdrop"]())


    }

    printAgreementCallback = (orderIds) => {
        markAgreementAsPrinted({orderIds}).then(() => {
            this.ordersStore.reLoad({...this.ordersStore.loadParams, page:0})
        })
    }

    openDetailsDrawer = (order) => {
        openModalFn["request-details"]({id: order.data.id})
    }
}

export default AgreementsStore