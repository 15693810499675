const dataSettingsStyles = (theme) => ({
    container: { display: 'flex', flexDirection: 'column', gap: '16px' },
    infoPanel: {
        display: 'flex',
        gap: '16px',
    },
    wrapperBase: {
        width: '100%',
        padding: '16px 32px 24px 32px',
        background: '#F6F6F6',
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '8px',
    },
    drawerContent: { display: 'flex', justifyContent: 'space-between', flexDirection: 'column' },
    drawerSubtitle: { color: '#5F5F5F', fontSize: '14px', fontWeight: 400, marginTop: '16px' },
    drawerInfo: { color: '#000', fontSize: '14px', fontWeight: 500 },
    warningText: {
        margin: '0 auto',
        color: '#E01717',
        fontSize: '14px',
        fontWeight: '400',
        textAlign: 'center',
        marginTop: '32px',
        width: '90%',
    },
})

export default dataSettingsStyles
