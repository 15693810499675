import React from 'react'
import { observer } from 'mobx-react'
import { Box, Button, Typography } from '@mui/material'
import searchIcon from 'src/assets/images/common/searchIcon.svg'
import downLoad from 'src/assets/images/common/downloadWhite.svg'
import downLoadGray from 'src/assets/images/common/downloadGray.svg'
import { BlockFilters } from './BlockFilters/BlockFilters'
import { store } from '../store/StatisticsInstallationSubsidiesStore'

export const StatisticsInstallationsSubsidiesFilters = observer(() => {
    const [openFilters, setOpenFilters] = React.useState(false)
    const {
        monthVue,
        yearVue,
        territoryVue,
        districtVue,
        stateRequestVue,
        setMonth,
        setYear,
        getRows,
        setTerritory,
        setDistrict,
        setStateRequest,
    } = store
    const dealerSelect = []

    const monthNames = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ]

    // Предположим, что month содержит значение от 1 до 12
    const monthName = monthVue ? monthNames[monthVue - 1] : ''

    // Теперь используйте monthName вместо month в массиве chips
    const chips =
        monthName && yearVue
            ? [territoryVue, districtVue, stateRequestVue, monthName, yearVue].filter(Boolean)
            : dealerSelect

    const handleRemoveChip = (chip) => {
        const currentDate = new Date()

        if (chip === monthName) {
            setMonth(currentDate.getMonth() + 1)
            setYear(currentDate.getFullYear())
        } else if (chip === yearVue) {
            setMonth(currentDate.getMonth() + 1)
            setYear(currentDate.getFullYear())
        } else if (chip === territoryVue) {
            setTerritory('')
        } else if (chip === districtVue)  {     
            setDistrict('')
        } else if (chip === stateRequestVue) {
            setStateRequest('')
        }

        getRows()
    }

    return (
        <Box sx={{ position: 'relative', display: 'flex', flexWrap: 'nowrap' }}>
            {openFilters && (
                <Box
                    onClick={() => setOpenFilters(false)}
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 999,
                        transition: 'opacity 0.3s ease-in-out',
                        opacity: openFilters ? 1 : 0,
                    }}
                />
            )}
            <Box
                sx={{
                    display: 'flex',

                    position: 'relative',
                    width: '100%',
                    zIndex: 1000,
                }}
            >
                <Box sx={{ width: '80%', position: 'relative' }}>
                    <Box
                        onClick={() => setOpenFilters(!openFilters)}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            height: '40px',
                            width: '100%',
                            padding: '8px 16px',
                            borderRadius: '8px',
                            backgroundColor: '#fff',
                            cursor: 'pointer',
                            zIndex: 1001,
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', width: '98%' }}>
                            <Typography
                                sx={{
                                    fontWeight: '500',
                                    fontSize: '15px',
                                    whiteSpace: 'nowrap',
                                    lineHeight: '18px',
                                }}
                            >
                                {openFilters ? 'Настройка поиска' : `Результаты поиска`}
                            </Typography>

                            <Box sx={{ display: 'flex', width: '99%', overflowX: 'auto' }}>
                                {chips?.map((el, index) => {
                                    return (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                padding: '4px 10px',
                                                borderRadius: '6px',
                                                backgroundColor: 'rgba(235, 232, 232, 1)',

                                                whiteSpace: 'nowrap',

                                                textOverflow: 'ellipsis',
                                                marginRight: '8px',
                                            }}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            {/* <Box sx={{ font: 'PT Root UI', color: 'rgba(0, 0, 0, 1)', fontSize:'15px', fontWeight: '400',  flexGrow: 1, overflow: 'hidden', textOverflow: 'hidden' }}>{el}</Box> */}
                                            <Typography
                                                sx={{
                                                    color: 'rgba(0, 0, 0, 1)',
                                                    fontSize: '15px',
                                                    fontWeight: '400',
                                                    flexGrow: 1,
                                                    overflow: 'hidden',
                                                    textOverflow: 'hidden',
                                                }}
                                            >
                                                {el}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '15px',
                                                    fontWeight: '400',
                                                    flexGrow: 1,

                                                    textOverflow: 'hidden',
                                                    marginLeft: '8px',
                                                    cursor: 'pointer',
                                                    fontSize: '12px',
                                                    color: 'rgba(0, 0, 0, 1)',
                                                    '&:hover': {
                                                        color: '#CC0000',
                                                    },
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    // Останавливаем распространение события
                                                    handleRemoveChip(el) // Удаляем чипс
                                                }}
                                            >
                                                ✕
                                            </Typography>
                                        </Box>
                                    )
                                })}
                            </Box>

                            {/* {chips?.length > 3 && (
                                <Typography
                                    sx={{
                                        color: 'rgba(0, 0, 0, 1)',
                                        fontSize: '15px',
                                        fontWeight: '400',
                                        padding: '4px 10px',
                                        borderRadius: '6px',
                                        backgroundColor: 'rgba(235, 232, 232, 1)',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    +{chips.length - 3} ещё
                                </Typography>
                            )} */}
                        </Box>

                        <Box
                            component='img'
                            src={searchIcon}
                            alt='searchIcon'
                            sx={{
                                '&:hover::before': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                },
                                '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '50%',
                                    backgroundColor: 'transparent',
                                    transition: 'background-color 0.3s ease-in-out',
                                    transform: 'translate(-50%, -50%)',
                                },
                            }}
                        />
                    </Box>
                    {openFilters && (
                        <Box
                            name='filters'
                            sx={{
                                position: 'absolute',
                                top: 'calc(100% + 8px)',
                                left: 0,
                                width: '100%',
                                backgroundColor: 'rgba(246, 246, 246, 1)',
                                padding: '24px 48px 48px 48px ',
                                borderRadius: '6px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
                                opacity: openFilters ? 1 : 0,
                                transform: openFilters ? 'translateY(0)' : 'translateY(-10px)',
                                zIndex: 1001,
                            }}
                        >
                            <BlockFilters setOpenFilters={setOpenFilters} />
                        </Box>
                    )}
                </Box>
                <Box sx={{ marginLeft: '16px', width: '20%' }}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={'downloadRequestsListPDF'}
                        disabled={true}
                        sx={{
                            borderRadius: '4px',

                            width: '100%',
                            zIndex: openFilters ? 1000 : 1,
                            position: openFilters ? 'relative' : 'initial',
                        }}
                        startIcon={<img src={false ? downLoad : downLoadGray} alt='Download Icon' />}
                    >
                        <Box pl={1.5}>
                            <Typography variant='h6' sx={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }}>
                                {'Сохранить в файл'}
                            </Typography>
                        </Box>
                    </Button>
                </Box>
            </Box>
        </Box>
    )
})
