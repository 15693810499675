import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { store } from '../store/StatisticsInstallationSubsidiesStore'
import vector from '../../../assets/images/vector.svg'
import { observer } from 'mobx-react'
import { PageSwitch } from './PageSwitch/PageSwitch'
import useScrollToggle from 'src/hooks/useScrollToggle'

export const TableStatisticsInstallationSubsidies = observer(() => {
    const border2px = '1px solid rgb(224, 224, 224)'
    const bgTerritory = 'rgb(231 228 228)'
    const bgDistrict = 'rgba(246, 246, 246, 1)'
    const bgResults = 'rgba(222, 238, 251, 1)'
    const {
        getDaysArray,
        addServiceProps,
        setOpen,
        rows,
        getRows,
        stateRequestVue,
        activeTab,
        monthVue,
        yearVue,
        month,
        year,
        territoryVue,
        districtVue,
        totalRow,
    } = store
    const [period, setPeriod] = React.useState(0)
    const monthNames = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ]

    useEffect(() => {
        getRows()
    }, [activeTab])

    const getTypeSubsidy = () => {
        switch (activeTab) {
            case '1':
                return 'ПОЛНАЯ ДОТАЦИЯ'
            case '2':
                return 'ЧАСТИЧНАЯ ДОТАЦИЯ'
            case '3':
                return 'ЗА СЧЁТ АБОНЕНТА'
            default:
                return ''
        }
    }
    const getString = () => {
        const values = [getTypeSubsidy(), stateRequestVue, territoryVue, districtVue].filter(Boolean)
        const result = values.join(', ')
        return result
    }

    const scroll = useScrollToggle(333,500)

    return (
        <>
            <TableContainer
                sx={{
                    opacity: scroll ? 1 : 0,
                    height: scroll ? 'auto' : 0,
                    visibility: scroll ? 'visible' : 'hidden',
                    transition: 'opacity 0.3s ease-in-out',
                    borderRadius: '0px',
                    padding: '5px',
                    paddingTop: '0',
                    paddingBottom: '0',
                    position: 'sticky',
                    top: '38px',
                    zIndex: 100,
                }}
                component={Paper}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        height: '40px',
                        padding: '15px 15px 10px 15px',
                    }}
                >
                    <Typography sx={{ fontWeight: '600', fontSize: '16px' }}>{getString()}</Typography>
                    <Typography sx={{ fontWeight: '600', fontSize: '16px' }}>{`${
                        monthNames[monthVue - 1]
                    } ${yearVue}`}</Typography>
                    <PageSwitch setPeriod={setPeriod} />
                </Box>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label='simple table'>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                    padding: '5px',
                                    width: '5%',
                                    borderTop: border2px,
                                }}
                            />
                            <TableCell
                                sx={{
                                    padding: '5px',
                                    width: '15%',
                                    borderTop: border2px,
                                }}
                            >
                                Округ / Населенный пункт
                            </TableCell>
                            {getDaysArray(monthVue, yearVue)[period].map((day, i, arr) => (
                                <TableCell
                                    sx={{
                                        borderRight: i === arr.length - 1 && period !== 1 ? null : border2px,
                                        borderTop: border2px,
                                        padding: '5px',
                                        width: period !== 1 ? `calc(80% / ${arr.length})` : `calc(70% / ${arr.length})`, 
                                    }}
                                    align='right'
                                >
                                    {day}
                                </TableCell>
                            ))}
                            {period === 1 ? (
                                <TableCell
                                    sx={{
                                        padding: '5px',
                                        fontWeight: '700',
                                        fontSize: '14px',
                                        width: '10%',
                                        borderTop: border2px,
                                    }}
                                    align='right'
                                >
                                    ИТОГО
                                </TableCell>
                            ) : null}
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>

            <TableContainer sx={{ borderRadius: '8px', padding: '5px', paddingTop: '0' }} component={Paper}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        height: '40px',
                        padding: '15px 15px 10px 15px',
                    }}
                >
                    <Typography sx={{ fontWeight: '600', fontSize: '16px' }}>{getString()}</Typography>
                    <Typography sx={{ fontWeight: '600', fontSize: '16px' }}>{`${
                        monthNames[monthVue - 1]
                    } ${yearVue}`}</Typography>
                    <PageSwitch setPeriod={setPeriod} />
                </Box>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label='simple table'>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                    borderBottom: border2px,
                                    borderTop: border2px,
                                    padding: '5px',
                                    width: '5%', 
                                }}
                            />
                            <TableCell
                                sx={{
                                    borderBottom: border2px,
                                    borderTop: border2px,
                                    padding: '5px',
                                    width: '15%', 
                                }}
                            >
                                Округ / Населенный пункт
                            </TableCell>
                            {getDaysArray(monthVue, yearVue)[period].map((day, i, arr) => (
                                <TableCell
                                    sx={{
                                        borderBottom: border2px,
                                        borderTop: border2px,
                                        borderRight: i === arr.length - 1 && period !== 1 ? null : border2px,
                                        padding: '5px',
                                        width: period !== 1 ? `calc(80% / ${arr.length})` : `calc(70% / ${arr.length})`,
                                    }}
                                    align='right'
                                >
                                    {day}
                                </TableCell>
                            ))}
                            {period === 1 ? (
                                <TableCell
                                    sx={{
                                        borderBottom: border2px,
                                        borderTop: border2px,
                                        padding: '5px',
                                        fontWeight: '700',
                                        fontSize: '14px',
                                        width: '10%',
                                    }}
                                    align='right'
                                >
                                    ИТОГО
                                </TableCell>
                            ) : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => {
                            return (
                                <>
                                    <TableRow
                                        key={row.name}
                                        sx={{ cursor: 'default', '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell
                                            sx={{ backgroundColor: bgTerritory }}
                                            component='th'
                                            scope='row'
                                        ></TableCell>
                                        <TableCell
                                            sx={{ fontWeight: '700', backgroundColor: bgTerritory }}
                                            component='th'
                                            scope='row'
                                        >
                                            {row.name}
                                        </TableCell>
                                        {getDaysArray(month, year)[period].map((day, i, arr) => (
                                            <TableCell
                                                sx={{
                                                    fontWeight: '700',
                                                    borderRight:
                                                        i === arr.length - 1 && period !== 1 ? null : border2px,
                                                    backgroundColor: bgTerritory,
                                                }}
                                                align='right'
                                            >
                                                {stateRequestVue
                                                    ? stateRequestVue === 'Созданные'
                                                        ? row.days[day]?.createdSum
                                                        : row.days[day]?.confirmedSum
                                                    : row.days[day]
                                                    ? +row.days[day]?.createdSum + +row.days[day]?.confirmedSum
                                                    : ''}
                                            </TableCell>
                                        ))}
                                        {period === 1 ? (
                                            <TableCell
                                                sx={{ fontWeight: '700', backgroundColor: bgTerritory }}
                                                align='right'
                                            >
                                                {stateRequestVue
                                                    ? stateRequestVue === 'Созданные'
                                                        ? row.days.monthly?.createdSum
                                                        : row.days.monthly?.confirmedSum
                                                    : row.days.monthly
                                                    ? +row.days.monthly?.createdSum + +row.days.monthly?.confirmedSum
                                                    : ''}
                                            </TableCell>
                                        ) : null}
                                    </TableRow>
                                    {row.districts?.map((district) => {
                                        return (
                                            <>
                                                <TableRow
                                                    onClick={() => {
                                                        setOpen(district.name)
                                                    }}
                                                    key={district.name}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        '&:last-child td, &:last-child th': { border: 0 },
                                                    }}
                                                >
                                                    <TableCell
                                                        sx={{ backgroundColor: bgDistrict }}
                                                        component='th'
                                                        scope='row'
                                                    >
                                                        <Box
                                                            sx={{
                                                                cursor: 'pointer',
                                                                transition: 'transform 0.3s ease',
                                                                transform: district.open
                                                                    ? 'rotate(90deg)'
                                                                    : 'rotate(0deg)',
                                                            }}
                                                            component='img'
                                                            alt=''
                                                            src={vector}
                                                        />
                                                    </TableCell>

                                                    <TableCell
                                                        sx={{
                                                            fontWeight: '700',

                                                            backgroundColor: bgDistrict,
                                                        }}
                                                        component='th'
                                                        scope='row'
                                                    >
                                                        {district.name}
                                                    </TableCell>
                                                    {getDaysArray(month, year)[period].map((day, i, arr) => (
                                                        <TableCell
                                                            sx={{
                                                                fontWeight: '700',
                                                                borderRight:
                                                                    i === arr.length - 1 && period !== 1
                                                                        ? null
                                                                        : border2px,
                                                                backgroundColor: bgDistrict,
                                                            }}
                                                            align='right'
                                                        >
                                                            {stateRequestVue
                                                                ? stateRequestVue === 'Созданные'
                                                                    ? district.days[day]?.createdSum
                                                                    : district.days[day]?.confirmedSum
                                                                : district.days[day]
                                                                ? +district.days[day]?.createdSum +
                                                                  +district.days[day]?.confirmedSum
                                                                : ''}
                                                        </TableCell>
                                                    ))}
                                                    {period === 1 ? (
                                                        <TableCell
                                                            sx={{
                                                                fontWeight: '700',
                                                                backgroundColor: bgDistrict,
                                                            }}
                                                            align='right'
                                                        >
                                                            {stateRequestVue
                                                                ? stateRequestVue === 'Созданные'
                                                                    ? district.days.monthly?.createdSum
                                                                    : district.days.monthly?.confirmedSum
                                                                : district.days.monthly
                                                                ? +district.days.monthly?.createdSum +
                                                                  +district.days.monthly?.confirmedSum
                                                                : ''}
                                                        </TableCell>
                                                    ) : null}
                                                </TableRow>

                                                {district.open
                                                    ? district.localities.map((locality) => {
                                                          return (
                                                              <TableRow
                                                                  key={locality.name}
                                                                  sx={{
                                                                      transition: 'transform 0.3s ease',
                                                                      cursor: 'default',
                                                                      '&:last-child td, &:last-child th': { border: 0 },
                                                                  }}
                                                              >
                                                                  <TableCell component='th' scope='row'></TableCell>
                                                                  <TableCell sx={{}} component='th' scope='row'>
                                                                      {locality.name}
                                                                  </TableCell>
                                                                  {getDaysArray(month, year)[period].map(
                                                                      (day, i, arr) => (
                                                                          <TableCell
                                                                              sx={{
                                                                                  borderRight:
                                                                                      i === arr.length - 1 &&
                                                                                      period !== 1
                                                                                          ? null
                                                                                          : border2px,
                                                                              }}
                                                                              align='right'
                                                                          >
                                                                              {' '}
                                                                              {stateRequestVue
                                                                                  ? stateRequestVue === 'Созданные'
                                                                                      ? locality.days[day]?.createdSum
                                                                                      : locality.days[day]?.confirmedSum
                                                                                  : locality.days[day]
                                                                                  ? +locality.days[day]?.createdSum +
                                                                                    +locality.days[day]?.confirmedSum
                                                                                  : ''}
                                                                          </TableCell>
                                                                      )
                                                                  )}
                                                                  {period === 1 ? (
                                                                      <TableCell align='right'>
                                                                          {stateRequestVue
                                                                              ? stateRequestVue === 'Созданные'
                                                                                  ? locality.days.monthly?.createdSum
                                                                                  : locality.days.monthly?.confirmedSum
                                                                              : locality.days.monthly
                                                                              ? +locality.days.monthly?.createdSum +
                                                                                +locality.days.monthly?.confirmedSum
                                                                              : ''}
                                                                      </TableCell>
                                                                  ) : null}
                                                              </TableRow>
                                                          )
                                                      })
                                                    : null}
                                            </>
                                        )
                                    })}
                                </>
                            )
                        })}
                        <TableRow sx={{ cursor: 'default' }}>
                            <TableCell sx={{ backgroundColor: bgResults }} component='th' scope='row'></TableCell>
                            <TableCell
                                sx={{ fontWeight: '700', fontSize: '20px', backgroundColor: bgResults }}
                                component='th'
                                scope='row'
                            >
                                ИТОГО
                            </TableCell>
                            {getDaysArray(month, year)[period].map((day, i, arr) => (
                                <TableCell
                                    sx={{
                                        fontWeight: '700',
                                        fontSize: '20px',
                                        borderRight: i === arr.length - 1 && period !== 1 ? null : border2px,
                                        backgroundColor: bgResults,
                                    }}
                                    align='right'
                                >
                                    {' '}
                                    {stateRequestVue
                                        ? stateRequestVue === 'Созданные'
                                            ? totalRow[day]?.createdSum
                                            : totalRow[day]?.confirmedSum
                                        : totalRow[day]
                                        ? +totalRow[day]?.createdSum + +totalRow[day]?.confirmedSum
                                        : ''}
                                </TableCell>
                            ))}
                            {period === 1 ? (
                                <TableCell
                                    sx={{ fontWeight: '700', fontSize: '20px', backgroundColor: bgResults }}
                                    align='right'
                                >
                                    {stateRequestVue
                                        ? stateRequestVue === 'Созданные'
                                            ? totalRow.monthly?.createdSum
                                            : totalRow.monthly?.confirmedSum
                                        : totalRow.monthly
                                        ? +totalRow.monthly?.createdSum + +totalRow.monthly?.confirmedSum
                                        : ''}
                                </TableCell>
                            ) : null}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
})
