import {regComp} from "../utils/utils/DI"
import OrdersStore from "./OrdersStore"
import StatusesStore from "./StatusesStore"
import DealersStore from "./DealersStore"
import FileUploadStore from "../components/AddRequestDrawer/stores/FileUploadStore"
import SendersStore from "./SendersStore"
import TerritoryStore from "./TerritoryStore"
import TerritoryStoreOld from "./TerritoryStoreOld"
import ParseNumberStore from "../utils/utils/ParseNumberStore"
import DealerContractStore from "./DealerContractStore"
import CategoriesStore from "./CategoriesStore"
import UserInfoStore from "./UserInfoStore"
import InstallationReasonStore from "./InstallationReasonStore"
import PhotoSettingsStore from "./PhotoSettingsStore"
import ProjectVersionStore from "./ProjectVersionStore"
import OperatorRequestStore from "./OperatorRequestStore"
import AuthorsStore from "./AuthorsStore"
import SourcesStore from "./SourcesStore"
import DataSettingsStore from "../views/DataSettings/store/DataSettingsStore"

regComp(OrdersStore, "OrdersStore", true)
regComp(StatusesStore, "StatusesStore", true)
regComp(DealersStore, "DealersStore", true)
regComp(FileUploadStore, "FileUploadStore", true)
regComp(SendersStore, "SendersStore", true)
regComp(TerritoryStore, "TerritoryStore", true)
regComp(TerritoryStoreOld, "TerritoryStoreOld", true)
regComp(ParseNumberStore, "ParseNumberStore", true)
regComp(DealerContractStore, "DealerContractStore", true)
regComp(CategoriesStore, "CategoriesStore", true)
regComp(UserInfoStore, "UserInfoStore", true)
regComp(InstallationReasonStore, "InstallationReasonStore", true)
regComp(PhotoSettingsStore, "PhotoSettingsStore", true)
regComp(ProjectVersionStore, "ProjectVersionStore", true)
regComp(OperatorRequestStore, "OperatorRequestStore", true)
regComp(AuthorsStore, "AuthorsStore", true)
regComp(SourcesStore, "SourcesStore", true)
regComp(DataSettingsStore, "DataSettingsStore", true)
