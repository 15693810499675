import React, { useEffect, useState } from 'react'
import dataSettingsStyles from './styles/dataSettingsStyles'
import { getStyles } from '../../utils/utils/commonUtils'
import { observer } from 'mobx-react'
import NewDataSettingsFilters from './components/NewDataSettingsFilters'
import DataSettingsStore from './store/DataSettingsStore'
import DataSettingsDrawer from './components/DataSettingsDrawer'
import { getComp } from '../../utils/utils/DI'
import DataSettingsTable from './components/DataSettingsTable'
import { Box, Button, Typography } from '@mui/material'
import AdditionalHorizontalPlateItem from '../AdditionalSummary/components/AdditionalHorizontalPlate/AdditionalHorizontaPlateItem'
import { isDataCustomizer } from '../../services/AccountService'
import { FormattedMessage } from 'react-intl'
import FullDotation from '../../assets/images/EnteringOperationalData/FullDotation.svg'
import FullDotationGray from '../../assets/images/EnteringOperationalData/FullDotationGray.svg'
import PartialDotation from '../../assets/images/EnteringOperationalData/PartialDotation.svg'
import PartialDotationGray from '../../assets/images/EnteringOperationalData/PartialDotationGray.svg'
import OpeningBalanceBlue from '../../assets/images/EnteringOperationalData/OpeningBalanceBlue.svg'
import SettingsBasedOnEnteredDataBlue from '../../assets/images/EnteringOperationalData/SettingsBasedOnEnteredDataBlue.svg'
import SettingsBasedOnLiveDataBlue from '../../assets/images/EnteringOperationalData/SettingsBasedOnLiveDataBlue.svg'
import OpeningBalanceGreen from '../../assets/images/EnteringOperationalData/OpeningBalanceGreen.svg'
import SettingsBasedOnEnteredDataGreen from '../../assets/images/EnteringOperationalData/SettingsBasedOnEnteredDataGreen.svg'
import SettingsBasedOnLiveDataGreen from '../../assets/images/EnteringOperationalData/SettingsBasedOnLiveDataGreen.svg'

const DataSettings = () => {
    const classes = getStyles(dataSettingsStyles)
    const [dataSettingsStore] = useState(() => new DataSettingsStore())
    const authorsStore = getComp('AuthorsStore')
    

    const { openDrawer, openCountDrawer, openInitialCountDrawer, fullfinanceSource, setFullfinanceSource, onSearch } = dataSettingsStore

    useEffect(() => {
        dataSettingsStore.getTotalCount(fullfinanceSource ? { FinancialSourceIds: 1 } : { FinancialSourceIds: 2 })
        dataSettingsStore.getInitialCount()
    }, [fullfinanceSource])
    const colorNumb = fullfinanceSource ? '#023A66' : '#088778'
    const dotationIcon = fullfinanceSource ? FullDotation : PartialDotation
    
    return (
        <Box sx={classes.container}>
            <Box sx={{ display: 'flex', gap: '4px' }}>
                <Button
                    onClick={() => {
                        setFullfinanceSource(!fullfinanceSource)
                        onSearch()
                    }}
                    sx={{
                        width: 'auto',
                        padding: '0 8px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '4px',
                        fontFamily: 'PT Root UI',
                        fontSize: '15px',
                        fontWeight: 700,
                        lineHeight: '18px',
                        textAlign: 'center',
                        backgroundColor: fullfinanceSource ? '#F6F6F6' : null,
                        boxShadow: fullfinanceSource ? '0px 0px 4px 0px #00000026' : null,
                    }}
                >
                    <img src={fullfinanceSource ? FullDotation : FullDotationGray} alt={''}></img>
                    <Typography
                        sx={{
                            fontFamily: 'PT Root UI',
                            fontSize: '15px',
                            color: fullfinanceSource ? '#000' : '#5F5F5F',
                            fontWeight: 700,
                            lineHeight: '18px',
                        }}
                        variant='body1'
                    >
                        <FormattedMessage id={'Полная дотация'} />
                    </Typography>
                </Button>
                <Button
                    onClick={() => {
                        setFullfinanceSource(!fullfinanceSource)
                        onSearch()
                    }}
                    sx={{
                        width: 'auto',
                        height: '40px',
                        padding: '0 8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '4px',
                        fontFamily: 'PT Root UI',
                        fontSize: '15px',
                        fontWeight: 700,
                        lineHeight: '18px',
                        textAlign: 'center',
                        backgroundColor: fullfinanceSource ? null : '#F6F6F6',
                        boxShadow: fullfinanceSource ? null : '0px 0px 4px 0px #00000026',
                    }}
                >
                    <img src={fullfinanceSource ? PartialDotationGray : PartialDotation} alt={''}></img>
                    <Typography
                        sx={{
                            fontFamily: 'PT Root UI',
                            fontSize: '15px',
                            color: fullfinanceSource ? '#5F5F5F' : '#000',
                            fontWeight: 700,
                            lineHeight: '18px',
                            whiteSpace: 'nowrap',
                        }}
                        variant={'body1'}
                    >
                        <FormattedMessage id={'Частичная дотация'} />
                    </Typography>
                </Button>
            </Box>

            <NewDataSettingsFilters
                store={dataSettingsStore}
                filterStore={dataSettingsStore.filterStore}
                openDataSettingsDrawer={openCountDrawer}
            />
            <Box sx={{ ...classes.infoPanel, gridTemplateColumns: '2fr 1fr' }}>
                <Box
                    sx={{
                        ...classes.wrapperBase,
                        ...classes.infoPanel,
                    }}
                >
                    <AdditionalHorizontalPlateItem
                        text={'Входящий остаток'}
                        belowText={fullfinanceSource ? '(Полная дотация)' : '(Частичная дотация)'}
                        status={'initialCount'}
                        color={colorNumb}
                        dotationIcon={dotationIcon}
                        number={
                            dataSettingsStore.getCurrentInitialCountInfo().count ?? '-'
                        }
                        store={dataSettingsStore}
                        roleLabelAE={isDataCustomizer}
                        noIcon={fullfinanceSource ? OpeningBalanceBlue : OpeningBalanceGreen}
                        onClickAction={openInitialCountDrawer}
                    />
                    <AdditionalHorizontalPlateItem
                        text={'Установки по введенным данным'}
                        belowText={fullfinanceSource ? '(Полная дотация)' : '(Частичная дотация)'}
                        status={'countOD'}
                        color={colorNumb}
                        dotationIcon={dotationIcon}
                        number={dataSettingsStore.commonData.count}
                        store={dataSettingsStore}
                        roleLabelAE={isDataCustomizer}
                        noIcon={fullfinanceSource ? SettingsBasedOnEnteredDataBlue : SettingsBasedOnEnteredDataGreen}
                    />
                    <AdditionalHorizontalPlateItem
                        text={'Установки по Оперативным данным'}
                        belowText={fullfinanceSource ? '(Полная дотация)' : '(Частичная дотация)'}
                        status={'editableCount'}
                        color={colorNumb}
                        dotationIcon={dotationIcon}
                        number={
                            Number(dataSettingsStore.commonData.count) +
                            Number(dataSettingsStore.commonData.initialCount)
                        }
                        store={dataSettingsStore}
                        roleLabelAE={isDataCustomizer}
                        noIcon={fullfinanceSource ? SettingsBasedOnLiveDataBlue : SettingsBasedOnLiveDataGreen}
                    />
                </Box>
                {/* <Box>
                    <Box sx={classes.wrapperBase}>
                    </Box>
                </Box> */}
            </Box>
            <DataSettingsTable
                store={dataSettingsStore}
                filterStore={dataSettingsStore.filterStore}
                onRowClick={(e) => openDrawer(e)}
            />
            <DataSettingsDrawer store={dataSettingsStore} authorsStore={authorsStore} />
        </Box>
    )
}

export default observer(DataSettings)
