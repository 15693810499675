const customTooltipStyles = (theme) => ({
    tooltipBackdrop: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        zIndex: 10000,
        background: 'rgba(0, 0, 0, 0.25)',
    },
    tooltipContainer: {
        position: 'relative',
        top: '100%',
        height: '50vh',
        width: '100vw',
        background: theme.palette.gray.light,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '8px 16px 32px',
        borderRadius: '16px 16px 0 0',
        boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.25)',
        transition: 'all 0.3s',
    },
    tooltipTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '24px',
        width: '100%',
    },
    tooltipTitle: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#0E1C2C',
    },
    tooltipContent: {
        // overflowY: 'scroll',
        overflowY: 'auto',
        width: '100%',
    },
})

export default customTooltipStyles
