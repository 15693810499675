import {makeAutoObservable} from "mobx"
import {getUploadPhotoSettings} from "../services/ApiService"
import {getComp} from "../utils/utils/DI"
import {gState} from "../utils/controllers/GlobalStateController"
import {isAdmin, isDealer} from "../services/AccountService"

class PhotoSettingsStore {
    constructor() {
        const {allOrderStatuses, allDataCheckingStatuses} = getComp("StatusesStore")
        this._photoSettings = {}
        this._order = {}
        this._newStatus = 0
        this._statuses = allOrderStatuses
        this._checkingStatuses = allDataCheckingStatuses
        this._roles = gState["roles"]
        makeAutoObservable(this)
    }

    load = () => {
        getUploadPhotoSettings().then((res) => {
            this._photoSettings = res.reduce((objectWithSettings, settingItem) => {
                objectWithSettings[settingItem.key] = settingItem
                return objectWithSettings
            }, {})
        })
    }

    set setNewStatus(status) {
        this._newStatus = status
    }

    setOrder = (order) => {
        this._order = order
    }

    _checkOrderStatus = (checkStatus) => {
        return this._order.status === checkStatus
    }

    get photoPermission() {
        let defaultSettings = {
            oneOfCollectionNotEmpty: false,
        }
        for (const collection in this._photoSettings) {
            if (this._order.images && this._order.images[collection]) {
                defaultSettings[`isEmpty${collection.charAt(0).toUpperCase() + collection.slice(1)}`] = this._order.images[collection].length === 0
                defaultSettings.oneOfCollectionNotEmpty = !defaultSettings.oneOfCollectionNotEmpty ? this._order.images[collection].length !== 0 : true
            }
        }

        switch (this._newStatus) {
            case this._statuses.installed:
                if (!this._order.dataCheckingStatus) {
                    defaultSettings = {
                        ...defaultSettings,
                        ...this._photoSettings.install,
                    }
                    break
                }
                break

            case this._statuses.impossible:
                if (this._order.status === this._statuses.accepted) {
                    defaultSettings.isImposibleButtonDisabled = this._photoSettings["accepted-to-impossible-dealer-action"].optional
                        ? false
                        : (this._order.images.impossible.length < this._photoSettings["accepted-to-impossible-dealer-action"].minLimitPerLoad
                            || this._order.images.impossible.length > this._photoSettings["accepted-to-impossible-dealer-action"].maxLimitPerLoad)

                    defaultSettings = {
                        ...defaultSettings,
                        ...this._photoSettings["accepted-to-impossible-dealer-action"],
                    }
                    break
                }

                if (this._order.status === this._statuses.working) {
                    defaultSettings.isImposibleButtonDisabled = this._photoSettings["working-to-impossible-dealer-action"].optional
                        ? false
                        : (this._order.images.impossible.length < this._photoSettings["working-to-impossible-dealer-action"].minLimitPerLoad
                            || this._order.images.impossible.length > this._photoSettings["working-to-impossible-dealer-action"].maxLimitPerLoad)

                    defaultSettings = {
                        ...defaultSettings,
                        ...this._photoSettings["working-to-impossible-dealer-action"],
                    }
                    break
                }

                break

            case this._statuses.uncompleted:
                defaultSettings.isUncomplitedButtonDisabled = this._photoSettings.uncompleted.optional
                    ? false
                    : (this._order.images.uncompleted.length < this._photoSettings.uncompleted.minLimitPerLoad
                        || this._order.images.uncompleted.length > this._photoSettings.uncompleted.maxLimitPerLoad)

                defaultSettings = {
                    ...defaultSettings,
                    ...this._photoSettings.uncompleted,
                }
                break

            default:
                if (this._order.dataCheckingStatus === this._checkingStatuses.needClarification) {
                    defaultSettings.isClarifyButtonDisabled = this._photoSettings.clarify.optional
                        ? false
                        : (this._order.images.clarify.length < this._photoSettings.clarify.minLimitPerLoad
                            || this._order.images.clarify.length > this._photoSettings.clarify.maxLimitPerLoad)

                    defaultSettings = {
                        ...defaultSettings,
                        ...this._photoSettings.clarify,
                    }
                }
        }

        switch (this._order.status) {
            case this._statuses.working:
                const isWorking = this._checkOrderStatus(this._statuses.working)
                defaultSettings.isInstallButtonDisabled = this._photoSettings.install.opional
                    ? false
                    : (this._order.images.install.length < this._photoSettings.install.minLimitPerLoad
                        || this._order.images.install.length > this._photoSettings.install.maxLimitPerLoad)
                return {
                    isWorking,
                    ...this._photoSettings.install,
                    ...defaultSettings,
                }

            case this._statuses.installed:
                const isInstalled = this._checkOrderStatus(this._statuses.installed)
                return {
                    isInstalled,
                    isForDelete: isInstalled && (isAdmin() || isDealer()),
                    ...defaultSettings,
                }

            case this._statuses.impossible:
                const isImpossible = this._checkOrderStatus(this._statuses.impossible)
                return {
                    isImpossible,
                    isForDelete: isImpossible && isAdmin(),
                    ...defaultSettings,
                }

            case this._statuses.uncompleted:
                const isUncompleted = this._checkOrderStatus(this._statuses.uncompleted)
                return {
                    isUncompleted,
                    isForDelete: isUncompleted && isAdmin(),
                    ...defaultSettings,
                }

            case this._statuses.clarifying:
                const isClarifying = this._checkOrderStatus(this._statuses.clarifying)
                return {
                    isClarifying,
                    isForDelete: isClarifying && (isAdmin() || isDealer()),
                    ...defaultSettings,
                }

            default:
                return defaultSettings
        }
    }
}

export default PhotoSettingsStore