import { makeAutoObservable } from 'mobx'
import moment from 'moment'
import { getStatisticsInstallationSubsidies } from '../../../services/ApiService'

class StatisticsInstallationSubsidiesStore {
    activeTab = '1'
    rows = []
    stateRequest = 'Созданные'
    stateRequestVue = ''
    territory = ''
    territoryVue = ''
    district = ''
    districtVue = ''
    monthVue = ''
    yearVue = ''
    monthlyConfirmedSum = 0
    monthlyCreatedSum = 0
    monthlySum = 0
    totalRow = []

    constructor() {
        const currentDate = new Date()
        this.month = currentDate.getMonth() + 1
        this.year = currentDate.getFullYear()
        makeAutoObservable(this)
    }

    setActiveTab = (tab) => {
        this.activeTab = tab
    }

    getDaysArray = (month, year) => {
        const daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth()
        const daysArray = []

        for (let day = 1; day <= daysInMonth; day++) {
            daysArray.push(moment(`${year}-${month}-${day}`, 'YYYY-MM-DD').format('DD.MM'))
        }
        return this.paginateDays(daysArray)
    }
    paginateDays = (daysArray) => {
        const firstHalf = daysArray.slice(0, 15)
        const secondHalf = daysArray.slice(15)

        return [firstHalf, secondHalf]
    }
    addServiceProps = (rows) => {
        this.rows = rows.map((row) => {
            return {
                ...row,
                districts: row.districts?.map((district) => {
                    return {
                        ...district,
                        open: false,
                    }
                }),
            }
        })
    }
    setOpen = (name) => {
        this.rows = this.rows.map((row) => {
            // Проверяем и переключаем open только для конкретного district
            row.districts = row.districts?.map((district) => {
                if (district.name === name) {
                    district.open = !district.open
                }
                return district
            })

            return row
        })
    }

    transformData = (rawData) => {
        function transformTerritory(territoryName, territoryData) {
            const transformed = {
                name: territoryName,
                days: territoryData.days || {},
                districts: [],
            }

            for (const districtName in territoryData.districts) {
                const districtData = territoryData.districts[districtName]
                const district = {
                    name: districtName,
                    days: districtData.days || {},
                    localities: [],
                }

                for (const localityName in districtData.localities) {
                    const localityData = districtData.localities[localityName]
                    district.localities.push({
                        name: localityName,
                        days: localityData.days || {},
                    })
                }

                transformed.districts.push(district)
            }

            return transformed
        }

        const result = []

        for (const territoryName in rawData.territories) {
            const territoryData = rawData.territories[territoryName]
            result.push(transformTerritory(territoryName, territoryData))
        }

        return result
    }

    getRows = () => {
        const params = {
            FinancialSourceId: this.activeTab,
            Month: this.month,
            Year: this.year,
            Territory: this.territory,
            District: this.district,
        }
        const filteredParams = Object.keys(params).reduce((acc, key) => {
            if (params[key]) {
                acc[key] = params[key]
            }
            return acc
        }, {})

        getStatisticsInstallationSubsidies(filteredParams).then((res) => {
            
            this.monthlyConfirmedSum = res.days.monthly?.confirmedSum
            this.monthlyCreatedSum = res.days.monthly?.createdSum
            this.monthlySum = (res.days.monthly?.createdSum || '') + (res.days.monthly?.confirmedSum || '')
            this.totalRow = res.days
            this.addServiceProps(this.transformData(res))

            this.monthVue = this.month
            this.yearVue = this.year
            this.territoryVue = this.territory
            this.districtVue = this.district
            this.stateRequestVue = this.stateRequest
        })
    }
    clear = () => {
        const currentDate = new Date()
        this.month = currentDate.getMonth() + 1
        this.year = currentDate.getFullYear()
        this.territory = ''
        this.district = ''
        this.stateRequest = 'Созданные'
        this.getRows()
    }

    setStateRequest = (value) => {
        this.stateRequest = value
    }
    setMonth = (value) => {
        this.month = value
    }

    setYear = (value) => {
        this.year = value
    }

    setTerritory = (value) => {
        this.territory = value
    }
    setDistrict = (value) => {
        this.district = value
    }
}

export const store = new StatisticsInstallationSubsidiesStore()
