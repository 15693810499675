import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Font,
} from "@react-pdf/renderer"
import React from "react"

export function ParagraphZero({styles}){

    const paragraphData = [
        {
            value: "Активация Услуги",
            text: "определенная последовательность действий Абонентом и/или третьих лиц, результатом которой является предоставление доступа к Услуге."
        },
        {
            value: "Абонент",
            text: "пользователь услугами связи для целей телевизионного вещания, с которым у Оператора заключен Договор и с выделением для этого уникального кода идентификации."
        },
        {
            value: "Личный счет",
            text: "персональный аналитический счет Абонента в системе регистрации и контроля Абонента, позволяющий учитывать информацию о поступлении и списании Платежей за Услуги, а также о балансе счета. Личный счет носит технологический характер, не имеет статуса расчетного или банковского счета."
        },
        {
            value: "Пользователь",
            text: "любое физическое лицо, не заключившее Договор с Оператором, но способное получать Услуги посредством использования Приёмного оборудования."
        },
        {
            value: "Приёмное оборудование",
            text: "совокупность технических устройств в виде приёмного оборудования, включая программное обеспечение, позволяющее Абоненту получать Услуги."
        },
        {
            value: "Регистрация Абонента",
            text: "определенная Оператором последовательность действий, необходимых для начала получения Абонентом Услуг, выполняемых Абонентом либо Дилером по поручению и от имени Абонента в целях персонификации Абонента в системе регистрации и контроля Абонентов."
        },
        {
            value: "Смарт-карта (Карта)",
            text: "пластиковая карта со встроенной микросхемой, предназначенная для первичного подключения Приёмного оборудования Абонента к сети Оператора в целях Активации Услуг. Абонент обязан хранить Смарт- карту и документы, сопровождающие приобретение Смарт-карты в течение всего срока оказания Услуг. Смарт-карта является ЭВМ и содержит компьютерную информацию."
        },
        {
            value: "Сайт",
            text: "веб-сайт Оператора в информационно-телекоммуникационной сети «Интернет», размещенный по адресу rusmirtv.ru на котором размещена информация об Операторе, Услугах, Тарифах, теле- и/или радиоканалах, специальных предложениях и т.д."
        },
        {
            value: "Тарифы",
            text: "совокупность ценовых условий, на которых Оператор предлагает Абоненту пользоваться одной или несколькими Услугами. Информация о действующих Тарифах размещена на Сайте."
        },
        {
            value: "ID",
            text: "идентификационный номер Абонента в системе регистрации и учета Абонентов."
        },
        {
            value: "Услуги",
            text: "оказываемые Оператором Абоненту услуги по вещанию (распространению) теле-, радиоканалов посредством искусственного спутника Земли «Экспресс АТ1» на территории Луганской Народной Республики, Донецкой Народной Республики, Запорожской области, Херсонской области, республики Крым и города Севастополя."
        },
        {
            value: "Средства информирования Абонента",
            text: "сайт Оператора связи в информационно-телекоммуникационной сети Интернет (rusmirtv.ru), и/или личный кабинет Абонента, и/или другие источники, указанные на сайте Оператора."
        },
    ]
    return(
        <View style={styles.paragraph}>
            <Text style={styles.title}>
                0. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ.
            </Text>
            {paragraphData.map((item) => (
                    <Text style={styles.text}>
                        <Text style={[styles.text, styles.textBold]}>
                            {item.value} &mdash;
                        </Text> {item.text}
                    </Text>
                )
            )}
        </View>
    )
}