import DataLoaderStore from "../utils/stores/DataLoaderStore"
import {getDealerInfo} from "../services/ApiService"


class DealerContractStore extends DataLoaderStore {
    constructor() {
        super({action: getDealerInfo})
    }
}

export default DealerContractStore