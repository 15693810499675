import React from 'react'
import { TableRow } from '@mui/material'
import { observer } from 'mobx-react'
import SummaryStatisticsDealersStoreInstance from '../../store/SummaryStatisticsDealersStore'
import { BodyTableCell } from '../BodyTableCell/BodyTableCell'
import { getComp } from '../../../../utils/utils/DI'

export const TableBodyRow = observer(({ columns, row, index }) => {
    const dealersStore = getComp('DealersStore')
    return (
        <TableRow
            sx={{
                backgroundColor: index % 2 === 0 ? 'rgba(217, 217, 217, 0.2)' : 'rgba(254, 254, 254, 1)',
            }}
            key={index}
        >
            {columns.map((column) => {
                const { propName } = column
                return (
                    <BodyTableCell key={column.label}  row={row} column={column}>
                        {propName === 'dealerId'
                            ? dealersStore.getDealer(row[propName])?.displayName
                            : propName === 'averageProcessingTimeHours'
                            ? (Math.round(row[propName] * 10) / 10).toFixed(1)
                            : row[propName]}
                    </BodyTableCell>
                )
            })}
        </TableRow>
    )
})
