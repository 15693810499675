import React from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { Box } from '@mui/material'
import { store } from '../../store/EditorStore'
import { observer } from 'mobx-react'
import { CustomList } from '../CustomList/CustomList'
import { useUpdateEffect } from 'src/hooks/useUpdateEffect'
import { SearchForm } from '../SearchForm/SearchForm'

export const SelectAllTransferList = observer(() => {
    const {
        territory,
        whiteList,
        allList,
        updateList,
        handleCancel,
        handleLeft,
        handleRight,
        handleAllLeft,
        handleAllRight,
        clearLists,
        disabledBtnLeft,
        disabledBtnRight,
        disabledBtnSave,
        disabledBtnCancel,
    } = store

    useUpdateEffect(() => {
        if (allList.length || whiteList.length) {
            clearLists()
        }
    }, [territory])

    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <SearchForm />
                </Box>

                <Grid container spacing={1} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Grid item>
                        <CustomList title={'Населенные пункты'} />
                    </Grid>
                    <Grid item>
                        <Grid container direction='column' sx={{ alignItems: 'center' }}>
                            {/* <Button
                            sx={{ my: 0.5 }}
                            variant='outlined'
                            size='small'
                            onClick={handleAllRight}
                            disabled={allList.length === whiteList.length}
                            aria-label='move all right'
                        >
                            ≫
                        </Button> */}
                            <Button
                                variant='outlined'
                                size='small'
                                sx={{
                                    my: 0.5,
                                    variant: 'outlined',
                                  
                                    size: 'small',
                                    fontWeight: '400',
                                    color: 'primary', // Устанавливает начальный цвет текста
                                    '&:hover': {
                                        backgroundColor: 'primary.main', // Меняет цвет фона на основной цвет темы (обычно синий)
                                        color: 'white', // Меняет цвет текста на белый
                                    },
                                }}
                                onClick={handleRight}
                                disabled={disabledBtnRight()}
                                aria-label='move selected right'
                            >
                                &gt;
                            </Button>
                            <Button
                                sx={{
                                    my: 0.5,
                                    variant: 'outlined',
                                  
                                    size: 'small',
                                    fontWeight: '400',
                                    color: 'primary', // Устанавливает начальный цвет текста
                                    '&:hover': {
                                        backgroundColor: 'primary.main', // Меняет цвет фона на основной цвет темы (обычно синий)
                                        color: 'white', // Меняет цвет текста на белый
                                    },
                                }}
                                variant='outlined'
                                size='small'
                                onClick={handleLeft}
                                disabled={disabledBtnLeft()}
                                aria-label='move selected left'
                            >
                                &lt;
                            </Button>
                            {/* <Button
                            sx={{ my: 0.5 }}
                            variant='outlined'
                            size='small'
                            onClick={handleAllLeft}
                            disabled={whiteList.length === 0}
                            aria-label='move all left'
                        >
                            ≪
                        </Button> */}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <CustomList title={'Дотационные населенные пункты'} white={true} />{' '}
                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    marginLeft: '60px',
                    paddingTop: '60px',
                }}
            >
                <Button
                    onClick={updateList}
                    disabled={disabledBtnSave}
                    variant='contained'
                    sx={{ width: '200px', marginLeft: 'auto', whiteSpace: 'nowrap' }}
                >
                    Сохранить изменения
                </Button>
                <Button
                    onClick={handleCancel}
                    disabled={disabledBtnCancel}
                    variant='outlined'
                    sx={{ width: '200px', marginLeft: 'auto' }}
                >
                    Отмена
                </Button>
            </Box>
        </Box>
    )
})
