import React, {useState, useEffect, useRef, useCallback} from "react"
import "./style/PhotoSliderCss.css"
import {Box} from "@mui/material"
import CarouselIndicators from "./CarouselIndicators"
import CarouselButton from "./CarouselButton"
import {getStyles} from "../../utils/utils/commonUtils"
import carouselStyles from "./style/carouselStyle"
import SlideImage from "./SlideImage"
import classNames from "classnames"

const Carousel = ({
    autoPlay = false,
    activeSlideDuration = 3000, //how long a slide will be displayed
    actionMode = "hover", //swipe or hover
    indicatorsColor = "#000",
    alignIndicators = "center", //center, left, right
    alignCaption = "center", //center, left, right
    useRightLeftTriangles = false,
    leftTriangleColor = "#000",
    customBtn,
    rightTriangleColor = "#000",
    slides = [],
    initialIndex = 0,
    zoom = false,
    setZoom,
    moveMode,
    setMoveMode,
    coords,
    setCoords,
    setPhotoToDownloadIndex
}) => {
    const [activeIndex, setActiveIndex] = useState(initialIndex)
    const [nextActiveIndex, setNextActiveIndex] = useState(0)
    const [activeIndexClasses, setActiveIndexClasses] = useState(["active-slide"])
    const [nextActiveIndexClasses, setNextActiveIndexClasses] = useState([])
    const [disablePrevNext, setDisablePrevNext] = useState(false)
    const [xCoordinate, setXCoordinate] = useState(null)
    // use it to set the indicator position based on the coming (alignIndicators) prop
    const [indicatorPosition, setIndicatorPosition] = useState("position-center")
    /*will be used to reset classes after animating the transition from a slide to another
          (it has to be equal to the animation duration in the css
          classes [enter-to-left, exit-to-left, enter-to-right, exit-to-right])*/
    const animationDuration = useRef(null)
    // will be used to autoplay the carousel
    const autoSlide = useRef(null)
    // used to detect slider direction when clicking the buttons to change slides
    const direction = useRef("to-left")
    const classes = getStyles(carouselStyles)
    const [mouseDown, setMouseDown] = useState(false)
    const [clickCoords, setClickCoords] = useState({x: 0, y: 0})
    const [interactionMode, setInteractionMode] = useState(actionMode)

    useEffect(() => {
        if (alignIndicators === "right") {
            setIndicatorPosition("position-right")
        } else if (alignIndicators === "left") {
            setIndicatorPosition("position-left")
        }
    }, [alignIndicators])

    useEffect(() => {
        if (!zoom) {
            setMoveMode(false)
            setTimeout(() => {
                setCoords({x: 50, y: 50})
            }, 600)
        }
    }, [zoom])

    useEffect(() => {
        animationDuration.current = 550
    }, [])

    function handleTouchStart(event) {
        if (event.touches.length === 1) {
            setInteractionMode("swipe")
        }
        if (event.touches.length > 1) {
            setZoom(true)
            setInteractionMode("hover")
            event.preventDefault()
        }
    }

    useEffect(() => {
        document.addEventListener("touchstart", handleTouchStart, {passive: false})

        return () => {
            document.removeEventListener("touchstart", handleTouchStart)
        }
    }, [])

    // show the next slide in the view port based on the direction
    const animateSliding = useCallback(() => {
        let newActiveIndexClasses = []
        let newNextActiveIndexClasses = []

        // attach the following classes if the user click the next button
        if (direction.current === "to-left") {
            newActiveIndexClasses.push("active-slide", "exit-to-left")
            newNextActiveIndexClasses.push("active-slide", "next-active-slide", "enter-to-left")
        } else {
            // attach the following classes if the user click the prev button
            newActiveIndexClasses.push("active-slide", "exit-to-right")
            newNextActiveIndexClasses.push("active-slide", "next-active-slide", "enter-to-right")
        }

        setActiveIndexClasses(newActiveIndexClasses)
        setNextActiveIndexClasses(newNextActiveIndexClasses)
    }, [])

    const setActiveSlide = (nextActiveI) => {
        setActiveIndex(nextActiveI)
        setPhotoToDownloadIndex(nextActiveI)
        setActiveIndexClasses(["active-slide"])
        setNextActiveIndexClasses([])
        setDisablePrevNext(false)
    }

    // used to restart auto sliding when user click prev, next button or on the carousel indicator
    const restartAutoSliding = useCallback(
        (nextAcIn) => {
            setNextActiveIndex(nextAcIn)
            setDisablePrevNext(true)

            // attach the required classes to animate the transition between slides
            animateSliding()

            let startId = null
            // reset classes and enable prev & next btns after the animation duration
            startId = setTimeout(() => {
                setActiveSlide(nextAcIn)
                clearInterval(startId)
            }, animationDuration.current)

            // restart auto sliding
            autoSlide.current = autoPlay
                ? setInterval(() => {
                      //stop auto sliding (so that when user click the next button we can reset the timer for auto sliding)
                      stopAutoSliding()

                      // set direction to left because slide is coming from the right side to the view port
                      direction.current = "to-left"

                      // set the next active index
                      let nextActiveI = activeIndex + 1

                      // if we reach the last slide reset the next active index to 0
                      if (nextActiveI === slides.length) {
                          nextActiveI = 0
                      }

                      // restart auto sliding
                      restartAutoSliding(nextActiveI)
                  }, activeSlideDuration)
                : null
        },
        [(animateSliding, activeSlideDuration, activeIndex, autoPlay, slides.length)]
    )

    const nextSlide = useCallback(() => {
        setZoom(false)
        //stop auto sliding (so that when user click the next button we can reset the timer for auto sliding)
        stopAutoSliding()

        // set direction to left because slide is coming from the right side to the view port
        direction.current = "to-left"

        // set the next active index
        let nextActiveI = activeIndex + 1

        // if we reach the last slide reset the next active index to 0
        if (nextActiveI === slides.length) {
            nextActiveI = 0
        }

        // restart auto sliding
        restartAutoSliding(nextActiveI)
    }, [activeIndex, slides.length, restartAutoSliding])

    const startAutoSliding = useCallback(() => {
        autoSlide.current = autoPlay ? setInterval(nextSlide, activeSlideDuration) : null
    }, [autoPlay, activeSlideDuration, nextSlide])

    const stopAutoSliding = () => {
        clearInterval(autoSlide.current)
    }

    useEffect(() => {
        startAutoSliding()
        return () => stopAutoSliding()
    }, [startAutoSliding])

    // used to unify the touch and click cases
    const unify = (e) => (e.changedTouches ? e.changedTouches[0] : e)

    // get and set the x coordinate
    const getSetXCoordinate = (e) => setXCoordinate(unify(e).clientX)

    // move the slide based on the swipe direction
    const moveSlide = (e) => {
        if (interactionMode === "swipe") {
            if (xCoordinate || xCoordinate === 0) {
                const dx = unify(e).clientX - xCoordinate
                const s = Math.sign(dx)

                if (s < 0) {
                    nextSlide()
                } else if (s > 0) {
                    prevSlide()
                }
            }
        }
    }

    const prevSlide = () => {
        setZoom(false)
        //stop auto sliding (so that when user click the prev button we can reset the timer for auto sliding)
        stopAutoSliding()

        // set direction to right because slide is coming from the left side to the view port
        direction.current = "to-right"

        // set the next active index
        let nextActiveI = activeIndex - 1

        // if we are at the first slide set the next active index to the last slide
        if (nextActiveI < 0) {
            nextActiveI = slides.length - 1
        }

        // restart auto sliding
        restartAutoSliding(nextActiveI)
    }

    const onCarouselIndicator = (index) => {
        //stop auto sliding
        stopAutoSliding()

        // set the next active index
        let nextActiveI = index

        // set the direction of the carousel based on the clicked indicator index
        if (nextActiveI < activeIndex) {
            direction.current = "to-right"
        } else {
            direction.current = "to-left"
        }

        // restart auto sliding
        restartAutoSliding(nextActiveI)
    }

    return (
        <Box sx={classes.wrapper}>
            <CarouselIndicators
                position={indicatorPosition}
                nextActiveIndex={nextActiveIndex}
                indicatorsColor={indicatorsColor}
                clickHandler={onCarouselIndicator}
                slides={slides}
                initialIndex={initialIndex}
                setZoom={setZoom}
            />
            {/*(onMouseDown & onTouchStart) & (onMouseUp & onTouchEnd) used to detect the motion direction*/}
            {/*(onMouseMove & onTouchMove) used to prevent edge from navigating the
                 opposite motion direction (also sometimes on chrome)*/}
            <Box
                // style={{touchAction: "none"}}
                id="carousel-viewport"
                sx={{
                    ...classes.slide,
                    cursor: moveMode && mouseDown ? "grabbing" : moveMode ? "grab" : null
                }}
                onMouseDown={(e) => {
                    setMouseDown(true)
                    setInteractionMode("swipe")
                    setClickCoords({
                        x: e.clientX,
                        y: e.clientY
                    })
                    if (moveMode && interactionMode !== "swipe") {
                        return
                    }
                    getSetXCoordinate(e)
                }}
                onMouseMove={(e) => {
                    if (mouseDown && moveMode) {
                        setClickCoords({
                            x: e.clientX,
                            y: e.clientY
                        })

                        let offsetX = (e.clientX - clickCoords.x) / 10
                        let offsetY = (e.clientY - clickCoords.y) / 10

                        let x = (coords.x -= offsetX)
                        let y = (coords.y -= offsetY)

                        if (coords.x < 0) {
                            x = 0
                        }
                        if (coords.y < 0) {
                            y = 0
                        }
                        if (coords.x > 100) {
                            x = 100
                        }
                        if (coords.y > 100) {
                            y = 100
                        }

                        setCoords({
                            x: x,
                            y: y
                        })
                        return
                    }
                    if (interactionMode !== "swipe") {
                        return
                    }
                    e.preventDefault()
                }}
                onMouseUp={(e) => {
                    setMouseDown(false)
                    if (moveMode) {
                        return
                    }
                    if (disablePrevNext || interactionMode !== "swipe" || zoom) {
                        return
                    }
                    moveSlide(e)
                }}
                onMouseOut={() => {
                    setMouseDown(false)
                }}
                onTouchStart={(e) => {
                    setMouseDown(true)

                    setClickCoords({
                        x: e.touches[0].clientX,
                        y: e.touches[0].clientY
                    })
                    if (interactionMode !== "swipe") {
                        return
                    }
                    getSetXCoordinate(e)
                }}
                onTouchMove={(e) => {
                    if (mouseDown && moveMode) {
                        let offsetX = (e.touches[0].clientX - clickCoords.x) / 10
                        let offsetY = (e.touches[0].clientY - clickCoords.y) / 10

                        setClickCoords({
                            x: e.touches[0].clientX,
                            y: e.touches[0].clientY
                        })

                        let x = (coords.x -= offsetX)
                        let y = (coords.y -= offsetY)

                        if (coords.x < 0) {
                            x = 0
                        }
                        if (coords.y < 0) {
                            y = 0
                        }
                        if (coords.x > 100) {
                            x = 100
                        }
                        if (coords.y > 100) {
                            y = 100
                        }

                        setCoords({
                            x: x,
                            y: y
                        })
                        return
                    }

                    if (interactionMode !== "swipe") {
                        return
                    }
                }}
                onTouchEnd={(e) => {
                    setMouseDown(false)

                    if (disablePrevNext || interactionMode !== "swipe" || zoom) {
                        return
                    }
                    moveSlide(e)
                }}
            >
                {slides.map((el, i) => {
                    let activeImg = i === activeIndex
                    let classes = classNames("carousel-slide", {
                        [activeIndexClasses.join(" ")]: i === activeIndex,
                        [nextActiveIndexClasses.join(" ")]: i === nextActiveIndex,
                        swipe: interactionMode === "swipe"
                    })

                    return (
                        <Box
                            id={`slide-square-${i}`}
                            key={i}
                            className={classes}
                            // the following events to pause the auto slide on hover
                            onMouseEnter={() => {
                                if (interactionMode !== "hover") {
                                    return
                                }
                                stopAutoSliding()
                            }}
                            onMouseLeave={() => {
                                if (interactionMode !== "hover") {
                                    return
                                }
                                startAutoSliding()
                            }}
                        >
                            <SlideImage imageSrc={el} zoom={zoom} active={activeImg} coords={coords} i={i} />
                        </Box>
                    )
                })}
                <CarouselButton
                    useTriangle={useRightLeftTriangles}
                    color={leftTriangleColor}
                    disabled={disablePrevNext}
                    clickHandler={() => {
                        slides.length !== 1 && prevSlide()
                    }}
                    customBtn={customBtn}
                    isLeftIcon
                />
                <CarouselButton
                    useTriangle={useRightLeftTriangles}
                    color={rightTriangleColor}
                    disabled={disablePrevNext}
                    clickHandler={() => {
                        slides.length !== 1 && nextSlide()
                    }}
                    customBtn={customBtn}
                />
            </Box>
        </Box>
    )
}

export default Carousel
