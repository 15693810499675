import { observable } from "mobx"

const themeColors = observable({
    primary: {
        main: "rgb(22,104,185)",
        light: "rgb(21, 101, 192)",
        dark: "#1f3c5c",
    },
    secondary: {
        main: "#EBF4FF",
        light: "#EBF4FF",
        dark: "#EBF4FF",
    },
    blue: {
        appBar: "#115293",
        active: "#1199FF",
        background: "#183D59",
        textMenuItem: "#03487E",
        hover: "rgba(93, 175, 239, 0.2)",
        mainMenu: "rgba(24, 61, 89, 0.94)",
        menuHover: "#0871C3",
        menuSelected: "rgba(93, 175, 239, 0.20)",
    },
    gray: {
        logo: "#5B5B5B",
        light: "#F6F6F6",
        semilight: "#DBDBDB",
        veryLightGray: "#EBE8E8",
        main: "#A5A5A5",
        semidark: "#4D4D4D",
        dark: "#242424",
    },
    basic: {
        black: "#000000",
        white: "#FFFFFF",
    },
    success: {
        main: "#28BA10",
        dark: "#198B06",
        light: "#ACFF9E",
    },
    error: {
        main: "#EA2020",
    },
    background: {
        white: "#FFFFFF",
        whiteSecondary: "#F5F5F5",
        black: "#000000",
        lightGray: "#EAEAEA",
        grayMain: "#D9D9D9",
        grayLight: "#F6F6F6",
    },
    text: {
        black: "#000000",
        white: "#EDF1F5",
        blueLight: "#CBE3FF",
        grayMain: "#979797",
        graySemidark: "#5F5F5F",
        grayDark: "#242424",
        grayLight: "rgba(217, 217, 217, 0.5)",
    },
})

export default themeColors
