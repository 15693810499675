import FormStore from "../../../utils/stores/FormStore"
import {action, makeObservable, observable} from "mobx"
import {signIn, signUp} from "../../../services/AccountService"
import moment from "moment"

const initState = {
    login: "",
    password: "",
    inviteId: ""
}

const validateData = {
    login: {required: true},
    password: {required: true},
    inviteId: {
        validators: [
            (value, store) => {
                if (store.invite && !value) {
                    return "Code required"
                }
                return false
            }
        ]
    }
}

class LoginStore extends FormStore {
    invite = false

    constructor({signIn}) {
        super({
            initState,
            validateData
        })
        this.signIn = signIn
        makeObservable(this, {
            invite: observable,
            toggleInvite: action
        })
    }

    toggleInvite = () => {
        this.invite = !this.invite
    }

    handleEnterPress = (e) => {
        e.code === "Enter" && this.data.login && this.data.password ? this.login() : null
    }

    login = () => {
        const timeOffset = moment().utcOffset()
        let {login, password, inviteId} = this.data
        login = login.trim() //Development #25506
        this.signIn({login, password, inviteId, timeOffset})
    }
}

export default LoginStore
