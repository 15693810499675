// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("PT-Root-UI_Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("PT-Root-UI_Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("PT-Root-UI_Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("PT-Root-UI_Bold.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\r\n    font-family: 'PT Root UI';\r\n    font-weight: 300;\r\n    font-style: normal;\r\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2');\r\n}\r\n\r\n@font-face {\r\n    font-family: 'PT Root UI';\r\n    font-weight: 400;\r\n    font-style: normal;\r\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff2');\r\n}\r\n\r\n@font-face {\r\n    font-family: 'PT Root UI';\r\n    font-weight: 500;\r\n    font-style: normal;\r\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff2');\r\n}\r\n\r\n@font-face {\r\n    font-family: 'PT Root UI';\r\n    font-weight: 600;\r\n    font-style: normal;\r\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff2');\r\n}", "",{"version":3,"sources":["webpack://./src/assets/fonts/PTRootUi/PTRootUI.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;IAClB,4DAAkD;AACtD;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;IAClB,4DAAoD;AACxD;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;IAClB,4DAAmD;AACvD;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;IAClB,4DAAiD;AACrD","sourcesContent":["@font-face {\r\n    font-family: 'PT Root UI';\r\n    font-weight: 300;\r\n    font-style: normal;\r\n    src: url('PT-Root-UI_Light.woff2') format('woff2');\r\n}\r\n\r\n@font-face {\r\n    font-family: 'PT Root UI';\r\n    font-weight: 400;\r\n    font-style: normal;\r\n    src: url('PT-Root-UI_Regular.woff2') format('woff2');\r\n}\r\n\r\n@font-face {\r\n    font-family: 'PT Root UI';\r\n    font-weight: 500;\r\n    font-style: normal;\r\n    src: url('PT-Root-UI_Medium.woff2') format('woff2');\r\n}\r\n\r\n@font-face {\r\n    font-family: 'PT Root UI';\r\n    font-weight: 600;\r\n    font-style: normal;\r\n    src: url('PT-Root-UI_Bold.woff2') format('woff2');\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
