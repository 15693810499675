import React, {createContext, useContext, useMemo} from "react"
import {useNavigate} from "react-router-dom"
import {useLocalStorage} from "../hooks/localStorage"
import {gState, rmGState, setGState} from "src/utils/controllers/GlobalStateController"
import moment from "moment"
import {authorizationLogout, authorize, logoutApi, signInRequest, signUpRequest} from "src/services/ApiService"
import {closeModalFn, openModalFn} from "src/utils/controllers/ModalController"

export const AuthContext = createContext(null)

export function AuthProvider({children}) {
    const [isLoggedIn, setIsLoggedIn] = useLocalStorage("isLoggedIn", false)
    const navigate = useNavigate()
    setGState("navigate", navigate)

    function login({login, password, inviteId, timeOffset}) {
        return inviteId
            ? signUpRequest({
                  login,
                  password,
                  inviteId
              }).then(() => {
                  signUpRequest({login, password, timeOffset}).then(() => {
                      setIsLoggedIn(true)
                      navigate("/requests")
                  })
              })
            : signInRequest({
                  login,
                  password,
                  returnurl: "/",
                  TimeOffset: timeOffset
              }).then(() => {
                  authorize().then((res) => {
                      setIsLoggedIn(true)
                      navigate("/requests")
                  })
              })
    }

    function logout() {
        openModalFn["progress-backdrop"]()
        return authorizationLogout()
            .then(() => {
                logoutApi().then(() => {
                    setIsLoggedIn(false)
                    rmGState("roles")
                    navigate("/login", {replace: true})
                })
            })
            .finally(() => {
                localStorage.clear()
                closeModalFn["progress-backdrop"]()
            })
    }

    const value = useMemo(
        () => ({
            isLoggedIn,
            login,
            logout,
            setIsLoggedIn
        }),
        [isLoggedIn]
    )

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext)
}
