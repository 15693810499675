import { observer } from 'mobx-react'
import { ToggleBlock } from './ToggleBlock/ToggleBlock'
import { CountRequestCards } from './CountRequestsCards/CountRequestCards'
import { Box } from '@mui/material'
import { TableSummaryStatisticsDealers } from './TableSummaryStatisticsDealers/TableSummaryStatisticsDealers'
import React, { useEffect, useState } from 'react'
import { SummaryStatisticsDealersFilters } from './SummaryStatisticsDealersFilters/SummaryStatistycsDealersFilters'
import summaryStatisticsDealersStoreInstance from './store/SummaryStatisticsDealersStore'
import SelectOrdersStore from '../../stores/SelectOrdersStore'
import OrdersStore from '../../stores/OrdersStore'
import RequestsFilterStore from '../RequestsManagement/stores/RequestsFilterStore'
import ParamsFilterStore from './store/ParamsFilterStore'
import { useUpdateEffect } from 'src/hooks/useUpdateEffect'
import { openModalFn } from '../../utils/controllers/ModalController'
import RequestDetailsDrawer from 'src/views/RequestsManagement/components/RequestDetailsDrawer/RequestDetailsDrawer'
import TransactionConfirmation from '../../components/TransactionConfirmation/TransactionConfirmation'
import RequestsTableOptimized from '../RequestsManagement/components/RequestsTableOptimized/RequestsTableOptimized'
import ReportsStore from '../Reports/stores/ReportsStore'
import ReportOrderDetailsStore from '../Reports/stores/ReportOrderDetailsStore'
import ReportOrderDetailsDrawer from '../Reports/components/ReportOrderDetailsDrawer/ReportOrderDetailsDrawer'
import RequestsManagementStore from 'src/stores/RequestsManagementStore'

export const SummaryStatisticsDealers = observer(() => {
    const { request, closeRequest } = summaryStatisticsDealersStoreInstance
    const [requestsManagement] = useState(new RequestsManagementStore())
    const [filterStore] = useState(new RequestsFilterStore())
    const [ordersStore] = useState(new OrdersStore())
    const [selectOrdersStore] = useState(new SelectOrdersStore({ from: 'reports', ordersStore: ordersStore }))
    const [reportsStore] = useState(() => new ReportsStore())
    const { openReportDetails, isReportDetailsOpen, selectedReportId, closeReportDetails } = reportsStore
    const { reportOrderDetailsStore } = requestsManagement

    const { params } = ParamsFilterStore

    useUpdateEffect(() => {
        if (request) {
            ordersStore.reLoad({
                page: 0,
                size: 100,
                ...params,
            })
        }
    }, [request])

    useEffect(() => {
        return () => {
            closeRequest()
        }
    }, [])

    const handleClick = (order) => {
        if (order.data.dataCheckingStatus === 2) {
            reportOrderDetailsStore.openOrderDetails(order)
            return
        } else {
            openModalFn['request-details']({ id: order.data.id })
        }
    }
   

    if (request) {
        return (
            <Box>
                <RequestsTableOptimized
                    filter
                    filterStore={filterStore}
                    selectOrdersStore={selectOrdersStore}
                    ordersStore={ordersStore}
                    onRowClick={handleClick}
                    selectAllMode
                />
                <RequestDetailsDrawer filterStore={filterStore} ordersStore={ordersStore} />
                <ReportOrderDetailsDrawer
                    store={reportOrderDetailsStore}
                    ordersStore={ordersStore}
                    fromMainPage
                    openReportDetails={openReportDetails}
                    isReportDetailsOpen={isReportDetailsOpen}
                />
                <TransactionConfirmation />
            </Box>
        )
    }
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <ToggleBlock />
            <SummaryStatisticsDealersFilters />
            <CountRequestCards />
            <TableSummaryStatisticsDealers />
        </Box>
    )
})
