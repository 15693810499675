import { Button } from '@mui/material'
import React from 'react'

const SendToServiceCenterBtn = ({ action, disabled }) => {
    const handleAction = () => {
        action()
    }
    return (
        <Button onClick={handleAction} fullWidth variant='contained' disabled={disabled}>
            Направить в сервис-центр
        </Button>
    )
}

export default SendToServiceCenterBtn
