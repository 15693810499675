import React from "react"
import {useIntl} from "react-intl"
import CustomAccordion from "../../../../components/customElements/RequestDataAccordions/utils/CustomAccordion"
import {Button, TextField, Typography} from "@mui/material"
import DrawerInfoElement from "./DrawerInfoElement"
import {observer} from "mobx-react"
import {getStyles} from "../../../../utils/utils/commonUtils"
import dataSettingsStyles from "../../styles/dataSettingsStyles"

const InitialCountAccordion = ({store}) => {
    const {
        onEdit = true,
        initialValueEditMode,
        toggleInitialValueEditMode,
        handleEditInitialCount,
        tableRowData,
        showInitialCount,
        setCount,
        countInput,
        disableSaveBtn
    } = store

    const classes = getStyles(dataSettingsStyles)
    const intl = useIntl()

    if (tableRowData) {
        return null
    }
    if (showInitialCount) {
        return (
            <CustomAccordion
                summary={intl.formatMessage({id: "Входящий остаток"})}
                defaultOpenState={true}
                onEdit={onEdit ? () => toggleInitialValueEditMode(!initialValueEditMode) : null}
            >
                <DrawerInfoElement subtitle={"Дата"} info={"Период до внесения данных"} />
                {initialValueEditMode ? (
                    <>
                        <TextField
                            sx={{width: "100%", marginTop: "16px"}}
                            type="number"
                            value={countInput}
                            onChange={setCount}
                        />

                        <Button
                            disabled={disableSaveBtn}
                            onClick={handleEditInitialCount}
                            sx={{width: "100%", textTransform: "uppercase", marginTop: "40px"}}
                            variant="contained"
                        >
                            сохранить изменения
                        </Button>
                    </>
                ) : (
                    <>
                        <DrawerInfoElement
                            subtitle={"Количество установок входящего остатка"}
                            info={
                                store.getCurrentInitialCountInfo().count
                                    ? store.getCurrentInitialCountInfo().count
                                    : "-"
                            }
                        />
                        <DrawerInfoElement
                            subtitle={"Дата внесения изменений"}
                            info={
                                store.getCurrentInitialCountInfo().count
                                    ? store.getCurrentInitialCountInfo().updatedAt
                                    : "-"
                            }
                        />
                        <DrawerInfoElement
                            subtitle={"Автор"}
                            info={
                                store.getCurrentInitialCountInfo().author
                                    ? store.getCurrentInitialCountInfo().author
                                    : "-"
                            }
                        />
                    </>
                )}

                <Typography sx={classes.warningText}>
                    Внимание! Данные входящего остатка вносятся один раз перед началом ввода оперативных данных за
                    каждый день и не должны редактироваться в дальнейшем, кроме исключительных случаев.
                </Typography>
            </CustomAccordion>
        )
    }
}

export default observer(InitialCountAccordion)
