import React from "react"
import {getStyles} from "../../utils/utils/commonUtils"
import photoGalleryDialogStyles from "./styles/photoGalleryDialogStyles"
import {closeModalFn} from "../../utils/controllers/ModalController"
import cross from "../../assets/images/common/cross_dark.svg"
import {Box, IconButton, Typography} from "@mui/material"
const CustomTopPanel = ({collectionName, collectionLength}) => {
    const classes = getStyles(photoGalleryDialogStyles)
    return (
        <Box sx={classes.topPanel}>
            <Box sx={{display: "flex"}}>
                {collectionName && collectionLength && (
                    <Typography sx={classes.topPanelText}>
                        {collectionName} ({collectionLength})
                    </Typography>
                )}
            </Box>

            <IconButton
                sx={classes.closeBtn}
                onClick={() => {
                    closeModalFn["photo-gallery"]()
                }}
            >
                <img alt="close" src={cross} />
            </IconButton>
        </Box>
    )
}

export default CustomTopPanel
