import { Box, Typography } from "@mui/material";
import React from "react";
import { containerStyle, textStyle } from "./TabNavigationButtonStyles";
import { observer } from "mobx-react";
import { store } from "../../store/StatisticsInstallationSubsidiesStore";



export const TabNavigationButton = observer(({ text, tab, icon, iconGray }) => {
    const { activeTab, setActiveTab } = store;
    const isActive = tab === activeTab;
    const containerBackground = isActive ? "rgba(246, 246, 246, 1)" : null;
    const textColor = isActive ? "rgba(14, 28, 44, 1)" : "rgba(95, 95, 95, 1)";

    const handleClick = () => {
        setActiveTab(tab);
    };

    return (
        <Box
            onClick={handleClick}
            sx={{
                ...containerStyle,
                background: containerBackground,
            }}
        >
            <Box component="img" alt="" src={isActive ? icon : iconGray} />
            <Typography
                sx={{
                    ...textStyle,
                    color: textColor,
                }}
            >
                {text}
            </Typography>
        </Box>
    );
});
