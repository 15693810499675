import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Box, Button, Typography } from '@mui/material'
import { getUserRoles } from '../../services/ApiService'
import { getComp } from '../../utils/utils/DI'
import { DataArray } from '@mui/icons-material'

export const DevHelper = observer(() => {
    const [userRole, setUserRole] = useState('')
    const [statusRequestInstall, setStatusRequestInstall] = useState(false)
    const statusStore = getComp('StatusesStore')
    useEffect(() => {
        getUserRoles().then((res) => {
            const convertText = res.join(', ')
            setUserRole(convertText)
        })
    }, [])

    return (
        <Box sx={{ backgroundColor: 'white', color: 'black', padding: '10px', borderRadius: '8px', maxHeight: '750px' , overflow: 'auto' }}>
            <Box>
                <Typography sx={{ fontWeight: 'bold' }}>Роль текущего пользователя: </Typography>
                <Typography>{userRole}</Typography>
                <Button variant={'outlined'} onClick={() => setStatusRequestInstall(prev => !prev)}>Статусы заявок на установку</Button>
                {statusRequestInstall && statusStore.getStatusesByRole('DEV').map((data) => {
                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: '#f0f0f0',
                                padding: '10px',
                                borderRadius: '8px',
                                marginTop: '10px',
                            }}
                        >
                            <img alt={''} src={data.imageSrc} />
                            <Box ml={1}>
                                <Typography variant={'subtitle2'}>{`${data.name} - ${data.id}`}</Typography>
                            </Box>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
})
