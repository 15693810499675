import React from 'react'
import { observer } from 'mobx-react'
import { Box, Typography } from '@mui/material'
import questionIcon from 'src/assets/images/countRequestCards/questionIcon.svg'
import fullSubsidy from 'src/assets/images/financialSource/fullSubsidy.svg'
import partialSubsidy from 'src/assets/images/financialSource/partialSubsidy.svg'
import fullFree from 'src/assets/images/financialSource/fullFree.svg'
import { store } from '../../store/StatisticsInstallationSubsidiesStore'

import TooltipButton from 'src/components/CustomTooltip/TooltipButton'
import TooltipInfoContent from 'src/components/CustomTooltip/TooltipInfoContent'

export const CountCard = observer(({ icon, title, count, detail, keywords }) => {
    const { activeTab } = store
    
    const typeSubsidy = () => {
        switch (activeTab) {
            case '1':
                return '(Полная дотация)'
            case '2':
                return '(Частичная дотация)'
            case '3':
                return '(За счёт абонента)'
            default:
        }
    }
    const colorCount = () => {
        switch (activeTab) {
            case '1':
                return 'rgba(2, 58, 102, 1)'
            case '2':
                return 'rgba(8, 135, 120, 1)'
            case '3':
                return 'rgba(22, 176, 37, 1)'
            default:
        }
    }

    const typeSubsidyIcon = () => {
        switch (activeTab) {
            case '1':
                return fullSubsidy
            case '2':
                return partialSubsidy
            case '3':
                return fullFree
            default:
        }
    }

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '8px 24px 8px 24px',
                borderRadius: '6px',
                backgroundColor: 'rgba(255, 255, 255, 1)',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    gap: '4px',
                    marginBottom: '8px',
                }}
            >
                <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                    <Box component='img' src={icon} alt='Иконка счётчика' />
                    <Typography sx={{ fontWeight: '400', fontSize: '12px', whiteSpace: 'pre' }}>{title}</Typography>
                </Box>
                <Typography sx={{ fontWeight: '400', fontSize: '12px', whiteSpace: 'pre', textAlign: 'center' }}>
                    {typeSubsidy()}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '4px', alignItems: 'center' }}>
                <Box component='img' src={typeSubsidyIcon()} alt='Иконка типа дотации' />
                <Typography sx={{ fontWeight: '500', fontSize: '36px', color: colorCount() }}>{count}</Typography>
                <Box
                    sx={{
                        position: 'relative',
                        cursor: 'pointer',
                    }}
                >
                    {true && (
                        <TooltipButton
                            content={<TooltipInfoContent title={title} data={detail[activeTab]} keywords={keywords} />}
                            title={title}
                            titleIcon={'blue'}
                            placement={'bottom'}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    )
})
