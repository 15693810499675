import React, { useEffect, useState } from 'react'
import ServiceFilters from './components/Filter/ServiceFilters'
import ServiceTable from './components/ServiceTable'
import { Provider, observer } from 'mobx-react'
import ServiceStore from './stores/ServiceStore'
import { disposeModal, regModal } from '../../utils/controllers/ModalController'

import ServiceSelectOrdersStore from './stores/ServiceSelectOrdersStore'
import { useRequestManagementContext } from '../RequestsManagement/context/withRequestManagmentContext'
import ReportsStore from '../Reports/stores/ReportsStore'
import RequestsManagementStore from '../../stores/RequestsManagementStore'

const Service = () => {
    const [serviceStore] = useState(() => new ServiceStore())
    const [serviceSelectOrdersStore] = useState(() => new ServiceSelectOrdersStore({ serviceStore: serviceStore }))


    const [requestsManagement] = useState(new RequestsManagementStore())    // эти 4 строки нужны для открытия дровера заявки на установку
    const { filterStore, ordersStore, operatorStore, openRequestDetails } = requestsManagement
    const [reportsStore] = useState(() => new ReportsStore())
    const { openReportDetails, isReportDetailsOpen } = reportsStore

    useEffect(() => {
        serviceStore.load()
        serviceStore.getTechnicalData()
        serviceStore.getServiceReceiversFunc()
        serviceStore.getUploadServicePhotoSettingsFunc()

        regModal('dealer-assigned-drawer') // дровер назначения дилера
        regModal('create-service-request') // дровер создания заявки
        regModal('assign-dealer-submit-dialog') // диалог подтверждения назначения дилера
        regModal('current-service-data') // место для хранения информации о текущей заявке
        regModal('common-service-drawer') // общий дровер для всех основных статусов
        regModal('on-site-service-drawer') // дровер ремонта на месте(воображаемый статус)
        regModal('intermediate-drawer') // переходный дровер(возврат на доработку, обслуживание невозможно, перевод в дубликат)
        regModal('transfer-to-service-center-drawer') // дровер передачи в сервисный центр

        return () => {
            disposeModal('dealer-assigned-drawer')
            disposeModal('create-service-request')
            disposeModal('assign-dealer-submit-dialog')
            disposeModal('current-service-data')
            disposeModal('common-service-drawer')
            disposeModal('on-site-service-drawer')
            disposeModal('intermediate-drawer')
            disposeModal('transfer-to-service-center-drawer')
        }
    }, [])

    return (
        <Provider serviceStore={serviceStore} serviceSelectOrdersStore={serviceSelectOrdersStore}>
            <ServiceFilters />
            <ServiceTable
                openRequestDetails={openRequestDetails}
                filterStore={filterStore}
                ordersStore={ordersStore}
                operatorStore={operatorStore}
                openReportDetails={openReportDetails}
                isReportDetailsOpen={isReportDetailsOpen}
            />
        </Provider>
    )
}

export default observer(Service)
