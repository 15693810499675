import PagedCollectionStore from '../utils/stores/PagedCollectionStore'
import { getTerritory } from '../services/ApiService'
import { makeObservable, observable } from 'mobx'

class TerritoryStore extends PagedCollectionStore {
    constructor() {
        super({ action: getTerritory })
        this.territories = {}
        this.urls = {
            днр: '/handbook/dnr-full.json',
            лнр: '/handbook/lnr-full.json',
            'запорожская область (зо)': '/handbook/zo-full.json',
            'херсонская область (хо)': '/handbook/ho-full.json',
        }
        this.urlsDev = {
            днр: 'http://10.1.0.228/handbook/dnr-full.json',
            лнр: 'http://10.1.0.228/handbook/lnr-full.json',
            'запорожская область (зо)': 'http://10.1.0.228/handbook/zo-full.json',
            'херсонская область (хо)': 'http://10.1.0.228/handbook/ho-full.json',
        }
        makeObservable(this, { territories: observable, })
    }
  

    
    setData(data) {
        data.forEach((el) => {
            fetch( window.SAT.config.isDev?`${this.urlsDev[el.toLowerCase()]}`:`${window.location.origin}${this.urls[el.toLowerCase()]}`)
                .then((res) => res.json())
                .then((res) => (this.territories[el] = res))
        })
        super.setData(data)
    }
}

export default TerritoryStore
// http://10.1.0.228/handbook/dnr-full.json
// http://10.1.0.228/handbook/lnr-full.json
// http://10.1.0.228/handbook/zo-full.json
// http://10.1.0.228/handbook/ho-full.json
