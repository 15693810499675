import { inject, observer } from 'mobx-react'
import React from 'react'
import RequestsTable from '../../../components/RequestsTable/RequestsTable'
import { isWidthUp } from '@mui/material/Hidden/withWidth'
import { Box } from '@mui/system'
import AssignDealerDrawer from '../../RequestsManagement/components/AssignDealerDrawer'
import AssignDealerSubmitDialog from '../../RequestsManagement/components/AssignDealerSubmitDialog/AssignDealerSubmitDialog'
import CreatingServiceRequestDrawer from './Drawers/CreateServiceDrawer/CreatingServiceRequestDrawer'
import CommonServiceDrawer from './Drawers/CommonServiceDrawer'
import OnSiteServiceDrawer from './Drawers/OnSiteServiceDrawer'
import TransferToServiceCenterDrawer from './Drawers/TransferToServiceCenterDrawer'
import IntermediateDrawer from './Drawers/IntermediateDrawer'
import RequestDetailsDrawer from '../../RequestsManagement/components/RequestDetailsDrawer/RequestDetailsDrawer'

const ServiceTable = ({
    serviceStore,
    serviceSelectOrdersStore,
    width,
    filterStore,
    ordersStore,
    operatorStore,
    openReportDetails,
    isReportDetailsOpen,
    openRequestDetails,
}) => {
    const { serviceFilterStore, serviceDrawersController, currentServiceData, fromDrawer, setFromDrawer } = serviceStore
    const { selectOrderFromDrawer } = serviceSelectOrdersStore
    const rowsOptions = isWidthUp('sm', width) ? [50, 100] : [50, 100]

    const handleOpenRow = (e) => {
            setFromDrawer(true)
            selectOrderFromDrawer(e.data.id)
            serviceDrawersController(e)    
    }

    return (
        <Box sx={{ position: 'relative' }}>
            {/* Таблица заявок. Переиспользуется та же что на странице Управления заявками. По полю isServiceTable меняются некоторые параметры */}
            <RequestsTable
                store={serviceStore}
                filterStore={serviceFilterStore}
                selectOrdersStore={serviceSelectOrdersStore}
                onRowClick={handleOpenRow}
                doublePagination={true}
                selectedTab={serviceStore ? serviceStore.selectedTab : null}
                rowsPerPageOptions={rowsOptions}
                scrollButtons={true}
                isServiceTable={true}
            />
            {/* Дровер создания заявки */}
            <CreatingServiceRequestDrawer />
            {/* Дровер назначения\переназначения дилера\ов */}
            <AssignDealerDrawer
                serviceStore={serviceStore}
                store={serviceSelectOrdersStore}
                title={fromDrawer ? `Заявка №${String(currentServiceData?.id).padStart(6, '0')}` : null}
                fromDrawer={fromDrawer}
            />
            {/* Назначить дилера диалоговое окно */}
            <AssignDealerSubmitDialog selectOrderStore={serviceSelectOrdersStore} />
            {/* Дровер просмотра информации о заявке из таблицы  */}
            <CommonServiceDrawer
                ordersStore={ordersStore}
                openRequestDetails={openRequestDetails}
                serviceSelectOrdersStore={serviceSelectOrdersStore}
            />
            <RequestDetailsDrawer
                serviceStore={serviceStore}
                filterStore={filterStore}
                operatorStore={operatorStore}
                ordersStore={ordersStore}
                openReportDetails={openReportDetails}
                isReportDetailsOpen={isReportDetailsOpen}
            />
            {/* Дровер Ремонт на месте */}
            <OnSiteServiceDrawer serviceStore={serviceStore} ordersStore={ordersStore} serviceSelectOrdersStore={serviceSelectOrdersStore} />
            {/* Дровер перевода в сервисный центр */}
            <TransferToServiceCenterDrawer
            ordersStore={ordersStore}
                serviceStore={serviceStore}
                serviceSelectOrdersStore={serviceSelectOrdersStore}
            />
            {/*Переходный дровер(возврат на доработку, обслуживание невозможно, перевод в дубликат)*/}
            <IntermediateDrawer serviceStore={serviceStore} />
        </Box>
    )
}

export default inject('serviceStore', 'serviceSelectOrdersStore')(observer(ServiceTable))
