import React from 'react'
import { TabNavigation } from './TabNavigation/TabNavigation'
import { observer } from 'mobx-react'
import { Box } from '@mui/material'
import { CardsRequestsCount } from './CardsRequestsCount/CardsRequestsCount'
import { TableStatisticsInstallationSubsidies } from './TableStatisticsInstallationSubsidies/TableStatisticsInstallationSubsidies'
import { StatisticsInstallationsSubsidiesFilters } from './StatisticsInstallationsSubsidiesFilters/StatisticsInstallationsSubsidiesFilters'


export const StatisticsInstallationsSubsidies = observer(() => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <TabNavigation />
            <StatisticsInstallationsSubsidiesFilters />
            <CardsRequestsCount />
            <TableStatisticsInstallationSubsidies />
        </Box>
    )
})
