import FormStore from '../../../utils/stores/FormStore'

const initState = {
    requisition: '',
    reasonId: '',
}

const validateData = {
    reasonId: { required: true },
}

class BaseInputsFormStore extends FormStore {
    constructor() {
        super({ initState, validateData })
        this.showErrors = false
        this.isValid = false
    }
}

export default BaseInputsFormStore
