import React, { useRef, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Box } from '@mui/material'
import { CountCard } from './CountCard/CountCard'

import assignedIcon from 'src/assets/images/countRequestCards/assignedIcon.svg'
import duplicateIcon from 'src/assets/images/countRequestCards/duplicateIcon.svg'
import forRevisionIcon from 'src/assets/images/countRequestCards/forRevisionIcon.svg'
import impossibleIcon from 'src/assets/images/countRequestCards/impossibleIcon.svg'
import installedIcon from 'src/assets/images/countRequestCards/installedIcon.svg'
import timeIcon from 'src/assets/images/countRequestCards/timeIcon.svg'

import summaryStatisticsDealersStoreInstance from '../store/SummaryStatisticsDealersStore'

export const CountRequestCards = observer(() => {
    const { data, monthly, activeToggleBlockItem, subpage, month, year } = summaryStatisticsDealersStoreInstance
    const { today, period, total } = subpage
    const containerRef = useRef(null)
    const monthNames = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ]

    const formatDate = () => {
        const date = new Date()
        const day = String(date.getDate()).padStart(2, '0')
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const year = date.getFullYear()

        return `${day}.${month}.${year}`
    }

    const keywords = ['Назначена', 'Принята', 'Установка', 'Невозможно', 'Дубликат', 'Установлено', 'На доработку']

    

    const cards = [
        {
            title: 'Назначены дилерам',
            propName: 'assigns',
            colorCount: 'rgba(112, 145, 183, 1)',
            icon: assignedIcon,
            detail: {
                today: [
                    `Счётчик, отображающий на текущую дату (${formatDate()}) общее количество назначенных на Дилеров заявок.`,
                    'Значение счётчика обновляется каждые 15 минут.',
                ],
                period: [
                    `Счётчик, отображающий за отчётный период (${
                        monthNames[+month - 1]
                    } ${year}) общее количество назначенных на Дилеров заявок.`,
                    'Значение счётчика обновляется 1 раз в день.',
                ],
                total: [
                    'Счётчик, отображающий суммарно за все время общее количество назначенных на Дилеров заявок.',
                    'Значение счётчика обновляется 1 раз в день.',
                ],
            },
        },
        {
            title: 'Просроченные',
            propName: 'expiredAssigns',
            colorCount: 'rgba(224, 23, 23, 1)',
            icon: assignedIcon,
            detail: {
                today: [
                    `Счётчик, отображающий на текущую дату (${formatDate()}) общее количество заявок в статусах Назначена, Принята, Установка, На доработку с истекшим периодом времени, отведенным на обслуживание заявок Дилерами. `,
                    'Значение счётчика обновляется каждые 15 минут.',
                ],
                period: [
                    `Счётчик, отображающий за отчётный период (${
                        monthNames[month - 1]
                    } ${year}) общее количество заявок, с истекшим периодом времени, отведенным на обслуживание заявок Дилерами  до момента установки оборудования (статус Установлено). `,
                    'Значение счётчика обновляется 1 раз в день.',
                ],
                total: [
                    'Счётчик, отображающий суммарно за все время общее количество заявок, с истекшим периодом времени, отведенным на обслуживание заявок Дилерами до момента установки оборудования (статус Установлено).',
                    'Значение счётчика обновляется 1 раз в день.',
                ],
            },
        },
        {
            title: 'Выполненные установки',
            propName: 'installs',
            colorCount: 'rgba(65, 185, 77, 1)',
            icon: installedIcon,
            detail: {
                today: [
                    `Счётчик, отображающий на текущую дату (${formatDate()}) общее количество заявок, по которым была выполнена установка оборудования в период времени, отведенный на обслуживание заявок Дилерами `,
                    'Значение счётчика обновляется каждые 15 минут.',
                ],
                period: [
                    `Счётчик, отображающий за отчётный период (${
                        monthNames[month - 1]
                    } ${year}) общее количество заявок, по которым была выполнена установка оборудования в период времени, отведенный на обслуживание заявок Дилерами `,
                    'Значение счётчика обновляется 1 раз в день.',
                ],
                total: [
                    'Счётчик, отображающий суммарно за все время общее количество заявок, по которым была выполнена установка оборудования в период времени, отведенный на обслуживание заявок Дилерами',
                    'Значение счётчика обновляется 1 раз в день.',
                ],
            },
        },
        {
            title: 'Установки с просрочкой',
            propName: 'expiredInstalls',
            colorCount: 'rgba(224, 23, 23, 0.6)',
            icon: installedIcon,
            detail: {
                today: [
                    `Счётчик, отображающий на текущую дату (${formatDate()}) общее количество заявок, по которым была выполнена установка оборудования с истекшим периодом времени, отведённым на установку или замену оборудования`,
                    'Значение счётчика обновляется каждые 15 минут.',
                ],
                period: [
                    `Счётчик, отображающий за отчётный период (${
                        monthNames[month - 1]
                    } ${year}) общее количество заявок, по которым была выполнена установка оборудования с истекшим периодом времени, отведённым на установку или замену оборудования `,
                    'Значение счётчика обновляется 1 раз в день.',
                ],
                total: [
                    'Счётчик, отображающий суммарно за все время общее количество заявок, по которым была выполнена установка оборудования с истекшим периодом времени, отведённым на установку или замену оборудования',
                    'Значение счётчика обновляется 1 раз в день.',
                ],
            },
        },
        {
            title: 'На доработку',
            propName: 'improvement',
            colorCount: 'rgba(255, 155, 41, 1)',
            icon: forRevisionIcon,
            detail: {
                today: [
                    `Счётчик, отображающий на текущую дату (${formatDate()}) общее количество заявок, отправленных Дилерами в кол-центр на доработку для дальнейшего уточнения данных по заявкам.`,
                    'Значение счётчика обновляется каждые 15 минут.',
                ],
                period: [
                    `Счётчик, отображающий за отчётный период (${
                        monthNames[month - 1]
                    } ${year}) общее количество заявок, отправленных Дилерами в кол-центр на доработку для дальнейшего уточнения данных по заявкам.`,
                    'Значение счётчика обновляется 1 раз в день.',
                ],
                total: [
                    'Счётчик, отображающий суммарно за все время общее количество заявок, отправленных Дилерами в кол-центр на доработку для дальнейшего уточнения данных по заявкам.',
                    'Значение счётчика обновляется 1 раз в день.',
                ],
            },
        },
        {
            title: 'В Невозможно',
            propName: 'impossible',
            colorCount: 'rgba(165, 165, 165, 1)',
            icon: impossibleIcon,
            detail: {
                today: [
                    `Счётчик, отображающий на текущую дату (${formatDate()}) общее количество заявок, переведённых в статус Невозможно.`,
                    'Значение счётчика обновляется каждые 15 минут.',
                ],
                period: [
                    `Счётчик, отображающий за отчётный период (${
                        monthNames[month - 1]
                    } ${year}) общее количество заявок, переведённых в статус Невозможно.`,
                    'Значение счётчика обновляется 1 раз в день.',
                ],
                total: [
                    'Счётчик, отображающий суммарно за все время общее количество заявок, переведённых в статус Невозможно.',
                    'Значение счётчика обновляется 1 раз в день.',
                ],
            },
        },
        {
            title: 'В Дубликаты',
            propName: 'duplicates',
            colorCount: 'rgba(165, 165, 165, 1)',
            icon: duplicateIcon,
            detail: {
                today: [
                    `Счётчик, отображающий на текущую дату (${formatDate()}) общее количество заявок, переведённых в статус Дубликат.`,
                    'Значение счётчика обновляется каждые 15 минут.',
                ],
                period: [
                    `Счётчик, отображающий за отчётный период (${
                        monthNames[month - 1]
                    } ${year}) общее количество заявок, переведённых в статус Дубликат.`,
                    'Значение счётчика обновляется 1 раз в день.',
                ],
                total: [
                    'Счётчик, отображающий суммарно за все время общее количество заявок, переведённых в статус Дубликат.',
                    'Значение счётчика обновляется 1 раз в день.',
                ],
            },
        },
        {
            title: 'Среднее время (часы)',
            propName: 'averageProcessingTimeHours',
            colorCount: 'rgba(76, 181, 117, 1)',
            icon: timeIcon,
            detail: {
                today: [
                    'Среднее время обслуживания заявок Дилерами от момента назначения заявок на Дилеров (статус заявок Назначена) до завершения обслуживания заявки (статус заявок Установлено).',
                    'Значение счётчика обновляется каждые 15 минут.',
                ],
                period: [
                    'Среднее время обслуживания заявок Дилерами от момента назначения заявок на Дилеров (статус заявок Назначена) до завершения обслуживания заявки (статус заявок Установлено).',
                    'Значение счётчика обновляется 1 раз в день.',
                ],
                total: [
                    'Среднее время обслуживания заявок Дилерами от момента назначения заявок на Дилеров (статус заявок Назначена) до завершения обслуживания заявки (статус заявок Установлено).',
                    'Значение счётчика обновляется 1 раз в день.',
                ],
            },
        },
    ]

    useEffect(() => {
        const container = containerRef.current
        if (container) {
            const handleWheel = (event) => {
                if (event.deltaY !== 0) {
                    event.preventDefault()
                    container.scrollLeft += event.deltaY
                }
            }
            container.addEventListener('wheel', handleWheel)
            return () => {
                container.removeEventListener('wheel', handleWheel)
            }
        }
    }, [])

    return (
        <Box
            ref={containerRef}
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '4px',
                borderRadius: '8px',
                boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.15)',
                padding: '16px',
                overflowX: 'auto',
                overflowY: 'hidden',
                whiteSpace: 'nowrap',
            }}
        >
            {cards.map((card) => {
                const { title, propName, colorCount, icon, detail } = card

                return (
                    <CountCard
                        key={title}
                        title={title}
                        count={
                            monthly && activeToggleBlockItem === period
                                ? propName === 'averageProcessingTimeHours'
                                    ? monthly[propName]
                                        ? (Math.round(monthly[propName] * 10) / 10).toFixed(1)
                                        : null
                                    : monthly[propName]
                                : propName === 'averageProcessingTimeHours'
                                ? data[propName]
                                    ? (Math.round(data[propName] * 10) / 10).toFixed(1)
                                    : null
                                : data[propName]
                        }
                        colorCount={colorCount}
                        icon={icon}
                        detail={detail}
                        keywords={keywords}
                    />
                )
            })}
        </Box>
    )
})
