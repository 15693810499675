import React, {useMemo} from "react"
import {createTheme, ThemeProvider} from "@mui/material"
import {observer} from "mobx-react"
import componentsOverrides from "./componentsOverrides"
import typographySettings from "./typographySettings"
import themeColors from "./themeColors"

export const ColorModeContext = React.createContext({
    toggle: () => {}
})

function SatThemeProvider({children}) {
    const theme = useMemo(() => {
        return createTheme({
            components: componentsOverrides,
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 608, // меньше sm - мобильная версия
                    md: 1280,
                    lg: 1440, // меньше lg - планшетная версия
                    xl: 1680
                }
            },
            palette: themeColors,
            typography: typographySettings,
            fontFamily: ["'PT Root UI'", "'Exo 2'", "sans-serif"].join(",")
        })
    }, [])

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default observer(SatThemeProvider)
