
import React, { useEffect, useRef } from 'react'
export const useUpdateEffect = function (effectCallback, deps = [])  {
    const isFirstMount = useRef(false)
    
    useEffect(() => {
      return () => {
        isFirstMount.current = false
      }
    }, [])
    useEffect(() => {
      // Не выполняйте effectcallback для первого раза
      if (!isFirstMount.current) {
        isFirstMount.current = true
      } else {
        return effectCallback()
      }
    }, deps)
  }