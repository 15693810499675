import { makeAutoObservable } from 'mobx'
import { getComp } from '../../../utils/utils/DI'
import { updateWhiteList } from '../../../services/ApiService'
import { openModalFn } from '../../../utils/controllers/ModalController'
import { notifySuccess } from '../../../services/ToastifyService/ToastifyService'

class Management {
    territory = ''
    district = ''
    territoryId = ''
    districtId = ''
    whiteList = []
    allList = []
    disabledBtnSave = true
    disabledBtnCancel = true

    constructor() {
        this.territoryStore = getComp('TerritoryStore')
        makeAutoObservable(this)
    }

    resetStore = () => {
        this.territory = ''
        this.district = ''
        this.territoryId = ''
        this.districtId = ''
        this.whiteList = []
        this.allList = []
        this.disabledBtnSave = true
        this.disabledBtnCancel = true
    }

    disabledBtnLeft = () => this.whiteList.length === 0 || !this.whiteList.some((el) => el.checked)

    disabledBtnRight = () => !this.allList.some((el) => el.checked)

    getDistricts = () =>
        this.territoryStore.territories[this.territory]?.Territory?.Districts?.map((el) => el.Name) || []

    setTerritory = (value) => {
        this.disabledBtnSave = true
        this.disabledBtnCancel = true
        this.district = ''
        this.territory = value
    }

    setDistrict = (value) => {
        this.disabledBtnSave = true
        this.disabledBtnCancel = true
        this.district = value
        const territoryData = this.territoryStore.territories[this.territory]?.Territory
        const districtData = territoryData?.Districts?.find((district) => district.Name === this.district)

        this.districtId = districtData?.Id || ''
        this.territoryId = territoryData?.Id || ''

        this.getLocalitiesWithCategories()
    }

    getLocalitiesWithCategories = () => {
        const districtData = this.territoryStore.territories[this.territory]?.Territory?.Districts?.find(
            (district) => district.Name === this.district
        )

        if (!districtData) return this.clearLists()

        const allList = districtData.Localities.map(this.addCheckProps)
        const whiteList = districtData.Localities.filter((locality) =>
            locality.AllowedFinancialSourceIds?.includes(1)
        ).map(this.addCheckProps)

        this.whiteList = whiteList
        this.allList = allList
    }

    addCheckProps = (item) => ({ ...item, checked: false })

    toggleCheck = (white, id) => {
        const list = white ? this.whiteList : this.allList
        list.forEach((el) => {
            if (el.Id === id) {
                el.checked = !el.checked
            }
        })

        if (white) {
            this.allList.forEach((el) => (el.checked = false))
        } else {
            this.whiteList.forEach((el) => (el.checked = false))
        }
    }

    toggleCheckAll = (white) => {
        const list = white ? this.whiteList : this.allList
        const listTwo = white ? this.allList : this.whiteList
        const filteredList = list.filter((el) => !el.isWhitelisted)
        const filteredListTwo = listTwo.filter((el) => !el.isWhitelisted)

        if (filteredList.every((el) => el.checked)) {
            filteredList.forEach((el) => (el.checked = false))
        } else {
            filteredList.forEach((el) => (el.checked = true))
            filteredListTwo.forEach((el) => (el.checked = false))
        }
    }

    isWhitelistedItems = () => {
        this.allList.forEach((el) => {
            el.isWhitelisted = this.whiteList.some((item) => item.Id === el.Id)
        })
    }

    handleAllLeft = () => {
        this.disabledBtnSave = false
        this.disabledBtnCancel = false
        this.whiteList = []
    }

    handleAllRight = () => {
        this.disabledBtnSave = false
        this.disabledBtnCancel = false
        this.whiteList = this.allList.map(this.addCheckProps)
        this.whiteList.forEach((el) => (el.isWhitelisted = false))
    }

    handleLeft = () => {
        this.disabledBtnSave = false
        this.disabledBtnCancel = false
        this.whiteList = this.whiteList.filter((el) => !el.checked)
        this.allList.forEach((el) => (el.checked = false))
    }

    handleRight = () => {
        this.disabledBtnSave = false
        this.disabledBtnCancel = false
        const selected = this.allList.filter((el) => el.checked).map(this.addCheckProps)
        this.whiteList = [...this.whiteList, ...selected]
        this.clearChecked()
    }

    clearLists = () => {
        this.whiteList = []
        this.allList = []
    }

    clearChecked = () => {
        this.allList.forEach((el) => (el.checked = false))
        this.whiteList.forEach((el) => (el.checked = false))
    }

    updateList = () => {
        const param = {
            territoryId: this.territoryId,
            districtId: this.districtId,
            mergedList: this.createMergedList(),
        }

        const actionFunction = (param) => {
            updateWhiteList(param).then(() => {
                this.disabledBtnSave = true
                this.disabledBtnCancel = true
                this.territoryStore.reLoad()
                notifySuccess('Изменения успешно сохранены.')
            })
        }

        openModalFn['transaction-confirmation']({
            text1: 'Подтвердить редактирование справочников населенных пунктов?',
            action: () => actionFunction(param),
            callback: () => this.handleCancel(),
            processText: 'Выполняется сохранение изменений.',
        })
    }

    handleCancel = () => {
        this.setDistrict(this.district)
    }

    createMergedList = () => this.allList.map((el) => ({ id: el.Id, isWhitelisted: el.isWhitelisted }))

    deepClone = (obj) => JSON.parse(JSON.stringify(obj))
}

export const store = new Management()
