import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { addOrder, addOrderByDealer, addOrderByAdmin } from '../../../services/ApiService'
import { closeModalFn } from '../../../utils/controllers/ModalController'
import AddRequestFormStore from '../stores/AddRequestFormStore'
import moment from 'moment'
import { getComp } from '../../../utils/utils/DI'
import { gState } from '../../../utils/controllers/GlobalStateController'
import { isAdmin, isAdminType2, isAdministration, isDealer, isDealerOperator, isOperator } from '../../../services/AccountService'
import Box from '@mui/material/Box'
import FormInput from '../../customElements/FormInput'
import Button from '@mui/material/Button'
import { FormattedMessage } from 'react-intl'
import MultipleSelect from '../../customElements/MultipleSelect/MultipleSelect'
import { notifySuccess } from '../../../services/ToastifyService/ToastifyService'
import { placeNames } from 'src/helpers/globalVariables'
import CustomAccordion from '../../customElements/RequestDataAccordions/utils/CustomAccordion'
import AdminAddFormStore from '../stores/AdminAddFormStore'
import AddAdminForm from './AddAdminForm'
import BaseInputsFormStore from '../stores/BaseInputsFormStore'
import newRequestIcon from '../../../assets/images/orderStatuses/new.svg'
import uploadByAdminIcon from '../../../assets/images/orderStatuses/uploadByAdmin.svg'
import InputField from '../../customElements/InputField/InputField'
import FinancialSourceAccordio from '../../customElements/RequestDataAccordions/FinancialSourceAccordio'

function AddRequestForm({ filterStore, ordersStore }) {
    const intl = gState['intl']
    const { shownSenders, defaultSenders } = getComp('SendersStore')
    const territoryStore = getComp('TerritoryStore')
    const categoriesStore = getComp('CategoriesStore')
    const { reasons } = getComp('InstallationReasonStore')
    const [formStore] = useState(() => new AddRequestFormStore())
    const [adminAddFormStore] = useState(() => new AdminAddFormStore())
    const [baseInputsFormStore] = useState(() => new BaseInputsFormStore())

    // задает значение даты в поле
    // меняет стили обязательных полей
    useEffect(() => {
        formStore.showErrors = !baseInputsFormStore.data['reasonId'] ? false : true
        formStore.data['created'] = !baseInputsFormStore.data['reasonId'] ? '' : moment().format('DD-MM-YYYY,HH:mm')

        adminAddFormStore.showErrors = !baseInputsFormStore.data['reasonId'] ? false : true
    }, [baseInputsFormStore.data['reasonId']])

    useEffect(() => {
        baseInputsFormStore.showErrors = baseInputsFormStore.data['requisition'] ? true : false
    }, [baseInputsFormStore.data['requisition']])

    const [disabled, setDisabled] = useState(false)

    

    let firstInput = [
        {
            propName: 'reasonId',
            validName: 'reasonId',
            label: intl.formatMessage({ id: 'Причина обращения' }),
            items: reasons,
            type: 'select',
        },
        {
            propName: 'requisition',
            validName: 'requisition',
            label: intl.formatMessage({ id: 'Заявка' }),
            items: [
                { key: 'Новая заявка', value: '1', icon: <img src={newRequestIcon} /> },
                {
                    key: 'Добавлена администратором',
                    value: '2',
                    icon: <img src={uploadByAdminIcon} />,
                },
            ],
            type: 'select',
        },
    ]

    const [topInputs, setTopInputs] = useState(firstInput)

    useEffect(() => {
        if (!isAdminType2()) {
            firstInput = firstInput.filter((item) => item.propName !== 'requisition')
            setTopInputs(firstInput)
        }
    }, [])

    const editData = [
        {
            propName: 'surname',
            validName: 'surname',
            label: intl.formatMessage({ id: 'Фамилия' }),
            type: 'text',
            capitalizeFirstLetter: true,
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        {
            propName: 'name',
            validName: 'name',
            label: intl.formatMessage({ id: 'Имя' }),
            type: 'text',
            capitalizeFirstLetter: true,
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        {
            propName: 'patronymic',
            validName: 'patronymic',
            label: intl.formatMessage({ id: 'Отчество' }),
            type: 'text',
            capitalizeFirstLetter: true,
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        {
            propName: 'phone',
            mask:"+7 (999)-999-99-99",
            label: intl.formatMessage({ id: 'Телефон' }),
            type: 'text',
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },

        {
            propName: 'territory',
            validName: 'territory',
            label: intl.formatMessage({ id: 'Регион' }),
            items: territoryStore.collection,
            type: 'select',
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        {
            propName: 'district',
            validName: 'district',
            label: intl.formatMessage({ id: 'Округ' }),
            autocompleteOptions: { territory: formStore.data['territory'], type: 'district' },
            readOnly: formStore.data.territory === '',
            disabled: formStore.data.territory === '',
            territorySelect: true,
            type: 'autocomplete',
        },
        {
            propName: 'locality',
            validName: 'locality',
            label: intl.formatMessage({ id: 'Населенный пункт' }),
            territorySelect: true,
            autocompleteOptions: {
                territory: formStore.data['territory'],
                district: formStore.data['district'],
                type: 'locality',
                fin: formStore.data['financialSourceId'],
            },
            readOnly: formStore.data.district === '',
            disabled: formStore.data.district === '',
            type: 'autocomplete',
        },
        {
            propName: 'addressType',
            validName: 'addressType',
            label: intl.formatMessage({ id: 'Топоним (улица / переулок и т.д.)' }),
            type: 'autocomplete',
            items: placeNames,
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        {
            propName: 'address',
            validName: 'address',
            label: intl.formatMessage({ id: 'Наименование топонима (улицы / переулка и т.д.)' }),
            type: 'text',
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        {
            propName: 'house',
            validName: 'house',
            label: intl.formatMessage({ id: 'Дом' }),
            type: 'text',
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        {
            propName: 'building',
            validName: 'building',
            label: intl.formatMessage({ id: 'Корпус' }),
            type: 'text',
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        {
            propName: 'apartment',
            validName: 'apartment',
            label: intl.formatMessage({ id: 'Квартира' }),
            type: 'text',
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        ...(isDealerOperator()
            ? []
            : [
                  {
                      propName: 'created',
                      validName: 'created',
                      label: intl.formatMessage({ id: 'Дата обращения' }),
                      mask: '99-99-9999, 99:99',
                      placeholder: !baseInputsFormStore.data['reasonId'] ? null : 'DD-MM-YYYY, HH:mm',
                      type: 'text',
                      disabled: baseInputsFormStore.data['reasonId'] ? false : true,
                  },
              ]),
        // {propName: 'preferentialCategory', label: intl.formatMessage({id: 'Льготная категория'})},
        {
            propName: 'categories',
            validName: 'categories',
            label: intl.formatMessage({ id: 'Льготные категории' }),
            items: categoriesStore.collection.map((el) => {
                return { key: el.name, value: el.id }
            }),
            multiple: true,
            type: 'multiple',
            onClear: formStore.onClearMultiple,
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        {
            propName: 'senderId',
            validName: 'senderId',
            label: intl.formatMessage({ id: 'Источник данных о заявке' }),
            items: shownSenders.map((el) => ({ key: el.name, value: el.id })),
            defaultValue:
                defaultSenders && shownSenders.find((sender) => sender.id === defaultSenders) ? defaultSenders : '',
            type: 'select',
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        {
            propName: 'additionalInfo',
            validName: 'additionalInfo',
            label: 'Дополнительная информация / краткое описание причины обращения',
            type: 'text',
            // styles: { background: 'red', minHeight: '75px' },
            multiline: true,
            rows: 5,
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },

        {
            propName: 'hasDuplicates',
            validName: 'hasDuplicates',
            label: intl.formatMessage({ id: 'Повторное обращение' }),
            type: 'checkbox',
            visible: isAdmin() || isDealerOperator() || isOperator() || isAdministration(),
            disabled: baseInputsFormStore.data['reasonId'] ? false : true,
        },
        // {
        //     propName: 'categories',
        //     label: intl.formatMessage({id: 'Список льготных категорий'}),
        //     items: categoriesStore.collection.map(el => ({key: el.id, value: el.name})),
        //     defaultValue: categoriesStore.collection[0]? categoriesStore.collection[0].id : '',
        // }
    ]

    function normalizePhoneNumber(phoneNumber) {
        return phoneNumber.replace(/\D/g, '');
    }

    const handleAccept = (priority) => {
        setDisabled(true)
        const data = {}

        priority === null ? (data['priority'] = null) : (data['priority'] = +priority)

        Object.keys(formStore.data).forEach((key) => {
            if (!!formStore.data[key] || key === 'reasonId') {
                data[key] =
                    key === 'senderId'
                        ? Number(formStore.data[key])
                        : key === 'created'
                        ? moment(formStore.data[key], 'DD-MM-YYYY,HH:mm').local(true).format()
                        : key === 'categories'
                        ? formStore.data['categories'].length
                            ? formStore.data['categories']
                            : [7]
                        : key === 'reasonId'
                        ? +baseInputsFormStore.data['reasonId']
                        : key === 'hasDuplicates'
                        ? formStore.data['hasDuplicates']
                        : key === 'financialSourceId'
                        ? Number(formStore.data[key])
                        : key === 'phone'? normalizePhoneNumber(formStore.data[key]): String(formStore.data[key])
            }
        })

        // если в селекте выбрано "Добавлена администратором" то вызывается специальный метод для добавления заявки
        if (+baseInputsFormStore.data['requisition'] === 2) {
            const params = {}
            Object.keys(adminAddFormStore.data).forEach((key) => {
                // проверка на резонАйди отдельно потому что этот параметр лежит в формСторе.
                // без этой проверки поле резонАйДи здесь будет пропущено и не попадет в парметры запроса
                if (!!adminAddFormStore.data[key] || key === 'reasonId') {
                    params[key] =
                        key === 'senderId'
                            ? Number(adminAddFormStore.data[key])
                            : key === 'created'
                            ? moment(adminAddFormStore.data[key], 'DD-MM-YYYY,HH:mm').local(true).format()
                            : key === 'installed'
                            ? moment(adminAddFormStore.data[key], 'DD-MM-YYYY,HH:mm').local(true).format()
                            : key === 'categories'
                            ? adminAddFormStore.data['categories'].length
                                ? adminAddFormStore.data['categories']
                                : [7]
                            : key === 'reasonId'
                            ? +baseInputsFormStore.data['reasonId']
                            : String(adminAddFormStore.data[key])
                }
            })

            addOrderByAdmin(params)
                .then(() => {
                    ordersStore.reLoad({ page: 0, size: ordersStore.pageSize })
                    closeModalFn['add-request']()
                    filterStore.clear()
                    setDisabled(false)
                    notifySuccess(intl.formatMessage({ id: 'Заявка создана' }))
                })
                .catch(() => {
                    setDisabled(false)
                })
            return
        }

        if (isDealerOperator()) {
            addOrderByDealer(data)
                .then(() => {
                    ordersStore.reLoad({ page: 0, size: ordersStore.pageSize })
                    closeModalFn['add-request']()
                    filterStore.clear()
                    setDisabled(false)
                    notifySuccess(intl.formatMessage({ id: 'Заявка создана' }))
                })
                .catch(() => {
                    setDisabled(false)
                })
        } else {
            addOrder(data)
                .then(() => {
                    ordersStore.reLoad({ page: 0, size: ordersStore.pageSize })
                    closeModalFn['add-request']()
                    filterStore.clear()
                    setDisabled(false)
                    notifySuccess(intl.formatMessage({ id: 'Заявка создана' }))
                })
                .catch(() => {
                    setDisabled(false)
                })
        }
    }
    const [value, setValue] = useState(null)

    return (
        <Box
            mt={2}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
            }}
        >
            <Box>
                {topInputs.map((el, i) => {
                    const {
                        propName,
                        label,
                        items,
                        mask,
                        defaultValue,
                        autocompleteOptions,
                        multiple,
                        readOnly,
                        disabled,
                        type,
                        visible,
                        territorySelect,
                        onClear,
                        capitalizeFirstLetter,
                        validName
                    } = el

                    return (
                        <Box key={i} sx={{ marginBottom: '12px', marginTop: '12px' }} px={2}>
                            {multiple ? (
                                <MultipleSelect
                                    items={items}
                                    value={baseInputsFormStore.data.categories}
                                    onChange={baseInputsFormStore.onChangeMultiple}
                                    label={label}
                                    propName={propName}
                                    readOnly={readOnly}
                                    disabled={disabled}
                                    onClear={onClear}
                                    validName={validName}
                                />
                            ) : (
                                <Box mt={i ? 1.5 : 0} key={i + ''}>
                                    <FormInput
                                        api={baseInputsFormStore}
                                        type={type}
                                        autocompleteOptions={autocompleteOptions}
                                        items={!!items ? items : null}
                                        propName={propName}
                                        validName={validName}
                                        fullWidth
                                        defaultValue={defaultValue}
                                        mask={mask}
                                        label={label}
                                        withoutErrorText
                                        readOnly={readOnly}
                                        disabled={disabled}
                                        visible={visible}
                                        territorySelect={territorySelect}
                                        capitalizeFirstLetter={capitalizeFirstLetter}
                                        
                                    />
                                </Box>
                            )}
                        </Box>
                    )
                })}

                {baseInputsFormStore.data['requisition'] && +baseInputsFormStore.data['requisition'] === 2 ? (
                    <AddAdminForm adminAddFormStore={adminAddFormStore} baseInputsFormStore={baseInputsFormStore} />
                ) : (baseInputsFormStore.data['requisition'] && +baseInputsFormStore.data['requisition'] === 1) ||
                  !isAdminType2() ? (
                    <CustomAccordion
                        summary={'Данные абонента'}
                        // defaultOpenState={formStore.data['reasonId'] && formStore.data['reasonId']}
                        defaultOpenState={true}
                        preventHandleOpen={true}
                    >
                        {editData.map((el, i) => {
                            const {
                                propName,
                                label,
                                items,
                                mask,
                                defaultValue,
                                autocompleteOptions,
                                multiple,
                                readOnly,
                                disabled,
                                type,
                                visible,
                                territorySelect,
                                onClear,
                                capitalizeFirstLetter,
                                styles,
                                multiline,
                                validName,
                                rows,
                            } = el

                            return (
                                <Box mt={i ? 1.5 : 0} key={i + 'createOrderInput'} sx={{ marginTop: '12px' }}>
                                    {multiple ? (
                                        <MultipleSelect
                                            items={items}
                                            value={formStore.data.categories}
                                            onChange={formStore.onChangeMultiple}
                                            label={label}
                                            propName={propName}
                                            readOnly={readOnly}
                                            disabled={disabled}
                                            onClear={onClear}
                                            validName={validName}
                                        />
                                    ) : (
                                        <Box mt={i ? 1.5 : 0} key={i + ''}>
                                            <FormInput
                                                api={formStore}
                                                type={type}
                                                autocompleteOptions={autocompleteOptions}
                                                items={!!items ? items : null}
                                                propName={propName}
                                                validName={validName}
                                                fullWidth
                                                defaultValue={defaultValue}
                                                mask={mask}
                                                label={label}
                                                withoutErrorText
                                                readOnly={readOnly}
                                                disabled={disabled}
                                                visible={visible}
                                                territorySelect={territorySelect}
                                                capitalizeFirstLetter={capitalizeFirstLetter}
                                                styles={styles}
                                                multiline={multiline}
                                            />
                                        </Box>
                                    )}
                                </Box>
                            )
                        })}
                    </CustomAccordion>
                ) : null}
                {isAdminType2() ? (
                    +baseInputsFormStore.data['requisition'] === 1 && (
                        <FinancialSourceAccordio
                            api={formStore}
                            setFinancialSourceId={formStore.setFinancialSourceId}
                            createRequest
                            baseInputsFormStore={baseInputsFormStore}
                            addReq
                        />
                    )
                ) : (
                    <FinancialSourceAccordio
                        api={formStore}
                        setFinancialSourceId={formStore.setFinancialSourceId}
                        createRequest
                        baseInputsFormStore={baseInputsFormStore}
                        addReq
                    />
                )}
                {isAdminType2() ? (
                    baseInputsFormStore.data['requisition'] && (  isAdmin()? null :
                        <CustomAccordion
                            summary={intl.formatMessage({ id: 'Приоритет заявки' })}
                            defaultOpenState={true}
                        >
                            <InputField
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                label={intl.formatMessage({ id: 'Приоритет' })}
                                type={'select'}
                                disabled={baseInputsFormStore.data['reasonId'] ? false : true}
                                items={[
                                    { value: undefined, key: 'Без приоритета' },
                                    { value: '0', key: '0' },
                                    { value: '1', key: '1' },
                                    { value: '2', key: '2' },
                                    { value: '3', key: '3' },
                                    { value: '4', key: '4' },
                                    { value: '5', key: '5' },
                                ]}
                                fullWidth
                            />
                        </CustomAccordion>
                    )
                ) : isAdministration() || isAdmin() || isDealer()  || isOperator()? null : (
                    <CustomAccordion summary={intl.formatMessage({ id: 'Приоритет заявки' })} defaultOpenState={true}>
                        <InputField
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            label={intl.formatMessage({ id: 'Приоритет' })}
                            type={'select'}
                            disabled={baseInputsFormStore.data['reasonId'] ? false : true}
                            items={[
                                { value: undefined, key: 'Без приоритета' },
                                { value: '0', key: '0' },
                                { value: '1', key: '1' },
                                { value: '2', key: '2' },
                                { value: '3', key: '3' },
                                { value: '4', key: '4' },
                                { value: '5', key: '5' },
                            ]}
                            fullWidth
                        />
                    </CustomAccordion>
                )}
            </Box>
            <Box px={2}>
                <Button
                    color={'primary'}
                    variant={'contained'}
                    onClick={() => handleAccept(value)}
                    sx={{ margin: '24px 0 0 0' }}
                    fullWidth
                    disabled={
                        +baseInputsFormStore.data['requisition'] === 2
                            ? !adminAddFormStore.isValid || disabled
                            : !formStore.isValid || disabled
                    }
                >
                    <FormattedMessage id={'Создать заявку на установку'} />
                </Button>
                <Button
                    color={'primary'}
                    variant={'outlined'}
                    onClick={() => closeModalFn['add-request']()}
                    fullWidth
                    sx={{ margin: '8px 0 24px 0' }}
                >
                    <FormattedMessage id={'Отмена'} />
                </Button>
            </Box>
        </Box>
    )
}

export default observer(AddRequestForm)
