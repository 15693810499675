import React from 'react'
import { closeModalFn, isModalOpen } from '../../../../utils/controllers/ModalController'
import RightDrawer from '../../../../components/customElements/RightDrawer/RightDrawer'
import { Box, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import StatusInfoAndEdit from './DrawersCommonComponents/StatusInfoAndEdit'
import AssignedDealer from '../../../../components/customElements/RequestDataAccordions/AssignedDealer'
import { isAdmin, isDealer } from '../../../../services/AccountService'
import InformationAccordion from './DrawersCommonComponents/InformationAccordion'
import SubscriberData from './DrawersCommonComponents/SubscriberData'
import { inject, observer } from 'mobx-react'
import SendToServiceCenterBtn from './DrawersCommonComponents/Buttons/SendToServiceCenterBtn'
import FullEquipmentDataAccordion from './DrawersCommonComponents/FullEquipmentDataAccordion'
import ServiceInformationAccordion from './DrawersCommonComponents/ServiceInformationAccordion'
import CommentsAccordion from './DrawersCommonComponents/CommentsAccordion'

const TransferToServiceCenterDrawer = ({ serviceStore, ordersStore, serviceSelectOrdersStore }) => {
    const { toggleAssignDealerDrawer, clearSelectOrders } = serviceSelectOrdersStore
    const {
        setShowInfoDrawer,
        clearCheckedSeviceOrder,
        onCloseDrawer,
        currentServiceData,
        sendToDiagnosticFunc,
        serviceInformationAccordionFormStore,
        uploadServicePhotoSettings,
    } = serviceStore

    const handleCloseInfoDrawer = () => {
        clearCheckedSeviceOrder()
        onCloseDrawer()
        setShowInfoDrawer(null)
        clearSelectOrders()
        closeModalFn['transfer-to-service-center-drawer']()
        serviceInformationAccordionFormStore.clear()
    }

    // настройка дизейбла кнопки в зависимости от полученных настроек загрузки фотографий с сервера
    let isDisabled
    const photosListLength = serviceInformationAccordionFormStore.data.files?.length || 0
    const settings = uploadServicePhotoSettings?.find((item) => item.key === 'MaintenanceCompleted') || {}
    if (settings) {
        const { maxLimitPerLoad, minLimitPerLoad, optional } = settings
        isDisabled = optional ? false : photosListLength < minLimitPerLoad || photosListLength > maxLimitPerLoad
    }

    if (currentServiceData) {
        return (
            <RightDrawer
                open={isModalOpen['transfer-to-service-center-drawer']}
                onClose={handleCloseInfoDrawer}
                titleComp={
                    <Box className={'fx-col'}>
                        <Typography variant={'subtitle1'} color={'common.white'}>
                            <FormattedMessage
                                id={`Заявка на обслуживание №${String(currentServiceData.id).padStart(6, '0')}`}
                            />
                        </Typography>
                    </Box>
                }
            >
                {/* В этом дровере статус 6.3 захардкожен */}
                <StatusInfoAndEdit
                    ordersStore={ordersStore}
                    currentServiceData={currentServiceData}
                    hardCodeStatus={6.106}
                />
                {isAdmin() && (
                    <AssignedDealer
                        request={currentServiceData}
                        defaultOpenState={true}
                        onEdit={
                            [1, 2].includes(currentServiceData.status) ? () => toggleAssignDealerDrawer(true) : null
                        }
                    />
                )}
                <FullEquipmentDataAccordion isEditable={true} />
                {!isDealer() && <InformationAccordion currentServiceData={currentServiceData} />}
                <SubscriberData currentServiceData={currentServiceData} />
                <ServiceInformationAccordion
                    serviceStore={serviceStore}
                    type={2} // 1  первый тип в дровере ремонта на месте. второй тип для передачи в сервисный центр
                />
                <CommentsAccordion currentServiceData={currentServiceData} />
                <Box
                    sx={{
                        margin: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        paddingBottom: '32px',
                    }}
                >
                    <SendToServiceCenterBtn
                        currentServiceData={currentServiceData}
                        action={sendToDiagnosticFunc}
                        disabled={
                            isAdmin() || isDealer()
                                ? serviceInformationAccordionFormStore.isValid
                                    ? false
                                    : true
                                : !isDisabled && serviceInformationAccordionFormStore.isValid
                                ? false
                                : true
                        }
                    />
                </Box>
            </RightDrawer>
        )
    }
}

export default inject('serviceStore')(observer(TransferToServiceCenterDrawer))
