import React from 'react'
import StatisticsSubsidiesFilters from './components/StatisticsSubsidiesFilters'
import StatisticsSubsidiesTable from './components/StatisticsSubsidiesTable'

function StatisticsSubsidies() {
    return (
        <>
            <StatisticsSubsidiesFilters />
            <StatisticsSubsidiesTable />
        </>
    )
}

export default StatisticsSubsidies
