import { Box, Typography } from '@mui/material'
import React from 'react'

const NotFound = () => {
    return (
        <Box>
            <Typography variant='h1'>Страница не найдена</Typography>
        </Box>
    )
}

export default NotFound
