import { Box } from '@mui/material'
import { observer } from 'mobx-react'
import React, { FC } from 'react'
import { CountCard } from './CountCard/CountCard'
import { store } from '../store/StatisticsInstallationSubsidiesStore'
import OpeningBalanceBlue from 'src/assets/images/EnteringOperationalData/OpeningBalanceBlue.svg'
import SettingsBasedOnEnteredDataBlue from 'src/assets/images/EnteringOperationalData/SettingsBasedOnEnteredDataBlue.svg'
import SettingsBasedOnLiveDataBlue from 'src/assets/images/EnteringOperationalData/SettingsBasedOnLiveDataBlue.svg'
import OpeningBalanceGreen from 'src/assets/images/EnteringOperationalData/OpeningBalanceGreen.svg'
import SettingsBasedOnEnteredDataGreen from 'src/assets/images/EnteringOperationalData/SettingsBasedOnEnteredDataGreen.svg'
import SettingsBasedOnLiveDataGreen from 'src/assets/images/EnteringOperationalData/SettingsBasedOnLiveDataGreen.svg'
import OpeningBalanceGreenLight from 'src/assets/images/EnteringOperationalData/OpeningBalanceGreenLight.svg'
import SettingsBasedOnEnteredDataGreenLight from 'src/assets/images/EnteringOperationalData/SettingsBasedOnEnteredDataGreenLight.svg'
import SettingsBasedOnLiveDataGreenLight from 'src/assets/images/EnteringOperationalData/SettingsBasedOnLiveDataGreenLight.svg'

export const CardsRequestsCount = observer(() => {
    const { activeTab, monthlyCreatedSum, monthlyConfirmedSum, monthlySum, monthVue, yearVue } = store

    const openingBalance = () => {
        switch (activeTab) {
            case '1':
                return OpeningBalanceBlue
            case '2':
                return OpeningBalanceGreen
            case '3':
                return OpeningBalanceGreenLight
            default:
        }
    }
    const settingsBasedOnEnteredData = () => {
        switch (activeTab) {
            case '1':
                return SettingsBasedOnEnteredDataBlue
            case '2':
                return SettingsBasedOnEnteredDataGreen
            case '3':
                return SettingsBasedOnEnteredDataGreenLight
            default:
        }
    }
    const settingsBasedOnLiveData = () => {
        switch (activeTab) {
            case '1':
                return SettingsBasedOnLiveDataBlue
            case '2':
                return SettingsBasedOnLiveDataGreen
            case '3':
                return SettingsBasedOnLiveDataGreenLight
            default:
        }
    }
    const monthNames = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ]

    const keywords = ['полная дотация', 'частичная дотация', 'за счёт Абонента', 'полную дотацию', 'частичную дотацию','2022', '2023','2024','2025', ...monthNames]

    const cards = [
        {
            title: `Созданные за ${monthNames[monthVue - 1]} ${yearVue} `,
            count: monthlyCreatedSum,
            colorCount: 'rgba(112, 145, 183, 1)',
            icon: openingBalance(),
            detail: {
                1: [
                    `Счётчик, отображающий общее количество заявок, созданных с типом оплаты полная дотация за ${
                        monthNames[monthVue - 1]
                    } ${yearVue}.`,
                ],
                2: [
                    `Счётчик, отображающий общее количество заявок, созданных с типом оплаты  частичная дотация  за ${
                        monthNames[monthVue - 1]
                    } ${yearVue}.`,
                ],
                3: [
                    `Счётчик, отображающий общее количество заявок, созданных с типом оплаты за счёт Абонента за ${
                        monthNames[monthVue - 1]
                    } ${yearVue}.`,
                ],
            },
        },
        {
            title: `Подтвержденные за ${monthNames[monthVue - 1]} ${yearVue} `,
            count: monthlyConfirmedSum,
            colorCount: 'rgba(224, 23, 23, 1)',
            icon: settingsBasedOnEnteredData(),
            detail: {
                1: [
                    `Счётчик, отображающий общее количество подтвержденных заявок с типом оплаты полная дотация  за ${
                        monthNames[monthVue - 1]
                    } ${yearVue}.`,
                    `Подтвержденные заявки – заявки, которым был изменен тип оплаты на полную дотацию, и раннее созданные заявки без типа оплаты, которым был поставлен тип оплаты полная дотация.`,
                ],

                2: [
                    `Счётчик, отображающий общее количество подтвержденных заявок с типом оплаты частичная дотация  за ${
                        monthNames[monthVue - 1]
                    } ${yearVue}.`,
                    `Подтвержденные заявки – заявки, которым был изменен тип оплаты на частичную дотацию, и раннее созданные заявки без типа оплаты, которым был поставлен тип оплаты частичная дотация.`,
                ],
                3: [
                    `Счётчик, отображающий общее количество подтвержденных заявок с типом оплаты за счёт Абонента за ${
                        monthNames[monthVue - 1]
                    } ${yearVue}.`,
                    `Подтвержденные заявки – заявки, которым был изменен тип оплаты на за счёт Абонента, и раннее созданные заявки без типа оплаты, которым был поставлен тип оплаты за счёт Абонента.`,
                ],
            },
        },
        {
            title: `Создано и Подтверждено за ${monthNames[monthVue - 1]} ${yearVue} `,
            count: monthlySum,
            colorCount: 'rgba(65, 185, 77, 1)',
            icon: settingsBasedOnLiveData(),
            detail: {
                1: [
                    `Счётчик, отображающий общее количество созданных и подтвержденных заявок с типом оплаты полная дотация за ${
                        monthNames[monthVue - 1]
                    } ${yearVue}.`,
                ],

                2: [
                    `Счётчик, отображающий общее количество созданных и подтвержденных заявок с типом оплаты частичная  за ${
                        monthNames[monthVue - 1]
                    } ${yearVue}.`,
                ],

                3: [
                    `Счётчик, отображающий общее количество созданных и подтвержденных заявок с типом оплаты за счёт Абонента за ${
                        monthNames[monthVue - 1]
                    } ${yearVue}.`,
                ],
            },
        },
    ]

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '16px',
                borderRadius: '8px',
                boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.15)',
                padding: '16px',
                overflowX: 'auto',
                overflowY: 'hidden',
                whiteSpace: 'nowrap',
            }}
        >
            {cards.map(({ title, subtitle, count, colorCount, icon, detail }) => {
                return (
                    <CountCard
                        key={title}
                        title={title}
                        subtitle={subtitle}
                        count={count}
                        colorCount={colorCount}
                        icon={icon}
                        detail={detail}
                        keywords={keywords}
                    />
                )
            })}
        </Box>
    )
})
