import {Text,View} from "@react-pdf/renderer"
import React from "react"

export function ParagraphTwo({styles}){

    return(
        <View style={styles.paragraph}>
            <Text style={styles.title}>
                2. ПРЕДМЕТ ДОГОВОРА.
            </Text>
            <Text style={styles.text}>
                2.1. Предметом настоящего Договора является предоставление Оператором Абоненту услуг по вещанию (распространению) теле-, радиоканалов посредством искусственных спутников Земли (далее — Услуги), а также иных услуг, прямо предусмотренных условиями Договора.
            </Text>
            <Text style={styles.text}>
                2.2. При заключении настоящего Договора Абоненту предоставляется доступ к Услуге, Приёмное оборудования модели __________________________, а также доставка, установка и настройка Приёмного оборудования в месте нахождения Абонента.
            </Text>
            <Text style={styles.text}>
                2.3. Оператор оказывает Услуги на основании лицензии ______________________________________________________________
            </Text>
        </View>
    )
}