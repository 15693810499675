import React, { useMemo } from 'react'
import { observer } from 'mobx-react'
import Drawer from '@mui/material/Drawer'
import { css } from '@emotion/css'
import { getStyles } from '../../utils/utils/commonUtils'
import mainMenuStyles from './styles/mainMenuStyles'
import MainMenuItem from './components/MainMenuItem'
import Box from '@mui/material/Box'
import {
    isAdmin,
    isAdministration,
    isDataCustomizer,
    isDealer,
    isLooker,
    isOperator,
} from '../../services/AccountService'
import { gState } from '../../utils/controllers/GlobalStateController'
import { getComp } from '../../utils/utils/DI'
import Typography from '@mui/material/Typography'
import { Divider, IconButton, useTheme } from '@mui/material'
import oldLogo from '../../assets/images/layout/oldLogo.svg'
import withWidth from '@mui/material/Hidden/withWidth'
import RThreeColorLogo from '../../assets/images/layout/RThreeColorLogo.svg'
import cross_light from '../../assets/images/common/cross_light.svg'
import arrowLeft from '../../assets/images/common/arrowLeft.svg'
import commonStyles from '../../styles/commonStyles'

function MainMenu({ open, toggleMenu, routesData }) {
    const theme = useTheme()
    const intl = gState['intl']
    const classes = { ...getStyles(mainMenuStyles), ...getStyles(commonStyles) }

    const { versionData } = getComp('ProjectVersionStore')

    const mainData = () => {
        return routesData.filter((el) => el.visible())
    }

    const memoVersion = useMemo(() => {
        return versionData
    }, [versionData])

    return (
        <Drawer
            name={'menu'}
            variant={'temporary'}
            anchor={'left'}
            open={!!open}
            onClose={toggleMenu}
            disableScrollLock={true}
            classes={{
                paper: css(classes.menuDrawer),
                root: css({
                    position: 'relative',
                    overflow: 'hidden',
                }),
            }}
            sx={{
                zIndex: '5000',
                height: '100%',
            }}
            slotProps={{
                backdrop: { sx: { background: 'transparent' } },
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', height: '48px' }}>
                <Box sx={classes.closeMenuIconWrapper}>
                    <IconButton
                        sx={{
                            ...classes.commonBtn,
                            ...classes.adminBtn,
                        }}
                        onClick={toggleMenu}
                    >
                        <img
                            draggable='false'
                            className={css(classes.closeMenuIcon)}
                            alt='back arrow'
                            src={arrowLeft}
                        ></img>
                    </IconButton>
                </Box>

                {isAdmin() ? (
                    <Box sx={classes.adminIconWrapper}>
                        <img draggable='false' alt='logo' src={RThreeColorLogo} />
                        <Typography sx={classes.adminIconText}>
                            {intl.formatMessage({ id: 'Русский мир тв' })}
                        </Typography>
                    </Box>
                ) : (
                    <Box py={'16px'} pl={'10px'} height={'59px'}>
                        <img draggable='false' alt={'logo'} src={oldLogo} />
                    </Box>
                )}
            </Box>

            <Divider sx={{ borderColor: 'rgba(203, 227, 255, 0.10)' }} />
            <Box className={'fx-col'} sx={{ gap: '10px', marginTop: '24px' }}>
                {mainData().map((el) => {
                    const { name, route, onClick } = el
                    return (
                        <>
                            {name === 'Выход' ? <Divider sx={{ borderColor: 'rgba(203, 227, 255, 0.10)' }} /> : null}
                            {name === 'Заявки на обслуживание' ? (
                                <Divider sx={{ borderColor: 'rgba(203, 227, 255, 0.10)' }} />
                            ) : null}
                            <Box key={name} sx={{ width: '100%', height: '100%' }}>
                                <MainMenuItem name={name} route={route} handleClick={onClick} toggleMenu={toggleMenu} />
                            </Box>

                            {name === 'Статистика по отчётам дилеров' && isDataCustomizer() && !isLooker() ? (
                                <Divider sx={{ borderColor: 'rgba(203, 227, 255, 0.10)' }} />
                            ) : null}
                            {name === 'Инфографика' ? (
                                <Divider sx={{ borderColor: 'rgba(203, 227, 255, 0.10)' }} />
                            ) : null}
                            {name === 'Заявки на установку' && isAdmin() && !isLooker() ? (
                                <Divider sx={{ borderColor: 'rgba(203, 227, 255, 0.10)' }} />
                            ) : null}
                            {name === 'Загрузка файла дилера' && !isOperator() && !isAdministration() ? (
                                <Divider sx={{ borderColor: 'rgba(203, 227, 255, 0.10)' }} />
                            ) : null}
                            {name === 'Заявки на обслуживание' && !isAdministration() ? (
                                <Divider sx={{ borderColor: 'rgba(203, 227, 255, 0.10)' }} />
                            ) : null}
                        </>
                    )
                })}
            </Box>

            {versionData && (
                <Box
                    sx={{
                        marginTop: 'auto',
                        color: theme.palette.text.grayLight,
                        padding: '4px 64px 8px 64px',
                        display: 'flex',
                        cursor: 'default',
                        gap: '4px',
                    }}
                >
                    <Typography sx={classes.versionText}>{intl.formatMessage({ id: 'Версия системы' })}</Typography>
                    <Typography
                        sx={{ ...classes.versionText, cursor: 'pointer' }}
                        onClick={() => {
                            if (navigator.clipboard) {
                                navigator.clipboard.writeText(memoVersion.trim())
                            }
                        }}
                    >
                        {memoVersion}
                    </Typography>
                </Box>
            )}
        </Drawer>
    )
}

export default withWidth()(observer(MainMenu))
