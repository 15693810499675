import { makeAutoObservable, reaction } from 'mobx'
import { replaceRequest, softUploadOrders, uploadOrders } from '../../../services/ApiService'
import { getComp } from '../../../utils/utils/DI'
import { closeModalFn, isModalOpen, openModalFn } from '../../../utils/controllers/ModalController'
import moment from 'moment'
import { gState } from '../../../utils/controllers/GlobalStateController'

class FileUploadStore {
    file
    totalInserted = 0
    totalDuplicated = 0
    totalErrors = 0
    totalCollisions = 0
    totalUpdated = 0
    updatedRequests = []
    insertedRequests = []
    duplicatedRequests = []
    errorRequests = []
    collisions = []
    collisionsFromFile = []
    collisionsProps = []
    mergeData
    selectedTable = 0
    isMergePopupOpen = false
    dealerId = null
    senderId = null
    page = 0
    pageSize = 10
    rewriteSenderId = false
    isNewFileFormatSelected = false

    constructor() {
        this.intl = gState['intl']
        makeAutoObservable(this)

        reaction(
            () => isModalOpen['add-request'],
            () => {
                if (!isModalOpen['add-request']) {
                    this.reactionClick()
                }
            }
        )
    }

    clear = () => {
        this.totalInserted = 0
        this.totalDuplicated = 0
        this.totalErrors = 0
        this.totalCollisions = 0
        this.totalUpdated = 0
        this.updatedRequests = []
        this.insertedRequests = []
        this.duplicatedRequests = []
        this.errorRequests = []
        this.collisions = []
        this.collisionsFromFile = []
        this.collisionsProps = []
        this.selectedTable = 0
        this.isMergePopupOpen = false
        this.page = 0
        this.pageSize = 10
        this.isNewFileFormatSelected = false
        this.reactionClick()
    }

    reactionClick = () => {
        this.setDealerId()
        this.setSenderId()
        this.setFile(null)
        this.rewriteSenderId = false
    }

    handlePageChange = (e, number) => {
        this.page = number
    }
    setRewriteSenderId = () => {
        this.rewriteSenderId = !this.rewriteSenderId
    }
    onPageSizeChange = (e) => {
        this.pageSize = e.target.value
    }

    toggleSelectedFileFormat = () => {
        this.isNewFileFormatSelected = !this.isNewFileFormatSelected
    }

    setFile = (file) => {
        if (file) {
            file.filename = file.name
        }
        this.file = file
    }
    setSelectedTable = (number) => {
        this.handlePageChange({}, 0)
        this.selectedTable = number
    }
    setDealerId = (e) => {
        this.dealerId = e ? e.target.value : null
    }
    setSenderId = (e) => {
        this.senderId = e ? e.target.value : null
    }

    get selectedCollection() {
        const collection =
            this.selectedTable === 0
                ? this.insertedRequests
                : this.selectedTable === 1
                ? this.duplicatedRequests
                : this.selectedTable === 2
                ? this.updatedRequests
                : this.selectedTable === 3
                ? this.errorRequests
                : this.collisionsFromFile
        return collection
    }

    replaceRequest = () => {
        openModalFn['progress-backdrop']()
        const newObj = Object.keys(this.mergeData.fromFile).reduce((acc, key) => {
            if (key === 'orderId') {
                return acc
            } else {
                acc[key] = this.mergeData.fromFile[key]
            }
            return acc
        }, {})
        replaceRequest(this.mergeData.fromDb.orderId, newObj).then(
            () => {
                this.collisions = this.collisions.filter((el) => el !== this.mergeData)
                this.collisionsFromFile = this.collisionsFromFile.filter(
                    (el) => el.phone !== this.mergeData.fromDb.phone
                )
                closeModalFn['progress-backdrop']()
                this.page = 0
                this.totalCollisions = this.collisions.length
                this.toggleMergePopup()
            },
            () => closeModalFn['progress-backdrop']()
        )
    }

    toggleMergePopup = (params) => {
        if (params && params.data) {
            this.mergeData = this.collisions.find((el) => el.fromFile.row === params.data.row)
            const collisionsProps = []
            Object.keys(this.mergeData.fromDb).forEach((key) => {
                if (this.mergeData.fromDb[key] !== this.mergeData.fromFile[key] && key !== 'phone') {
                    collisionsProps.push(key)
                }
            })
            this.collisionsProps = collisionsProps
        } else {
            this.mergeData = null
        }
        this.isMergePopupOpen = !this.isMergePopupOpen
    }

    loadFile = () => {
        const sortedKeys = [
            'row',
            'surname',
            'name',
            'patronymic',
            'phone',
            'territory',
            'district',
            'locality',
            'addressType',
            'address',
            'house',
            'building',
            'apartment',
            'reason',
            'preferentialCategory',
            'date',
            'time',
            'additionalInfo',
            'equipmentId',
        ]

        const data = [
            [
                '№ п/п',
                this.intl.formatMessage({ id: 'Фамилия' }),
                this.intl.formatMessage({ id: 'Имя' }),
                this.intl.formatMessage({ id: 'Отчество' }),
                this.intl.formatMessage({ id: 'Контактный телефон' }),
                this.intl.formatMessage({ id: 'Территория' }),
                this.intl.formatMessage({ id: 'Округ' }),
                this.intl.formatMessage({ id: 'Населенный пункт' }),
                this.intl.formatMessage({ id: 'Улица/квартал' }),
                this.intl.formatMessage({ id: 'Наименование Улицы/Квартала' }),
                this.intl.formatMessage({ id: 'Дом' }),
                this.intl.formatMessage({ id: 'Корпус' }),
                this.intl.formatMessage({ id: 'Квартира' }),
                this.intl.formatMessage({ id: 'Установка/замена' }),
                this.intl.formatMessage({ id: 'Льготная категория' }),
                this.intl.formatMessage({ id: 'Дата обращения' }),
                this.intl.formatMessage({ id: 'Время обращения' }),
                this.intl.formatMessage({ id: 'Дополнительный комментарий' }),
                this.intl.formatMessage({ id: 'Оборудование' }),
            ],
        ]

        this.selectedCollection.forEach((request, i) => {
            data.push(sortedKeys.map((key) => request[key] || ''))
        })

        const blobData = data.map((el) => el.join(window.SAT.config.csvParams.divider)).join('\n')
        const encodeData = new TextEncoder('utf-16be').encode(blobData)
        const blob = new File(
            ['\uFEFF', encodeData],
            this.selectedTable === 2
                ? this.intl.formatMessage({ id: 'Ошибки' })
                : this.intl.formatMessage({ id: 'Конфликты' }) + ' ' + `(${moment().format('DD.MM.YYYY, HH:mm')})`,
            { type: `text/csv;${window.SAT.config.csvParams.charset}` }
        )

        const link = window.URL.createObjectURL(blob)
        window.location = link
    }

    upload = () => {
        openModalFn['progress-backdrop']()
        const queryParams = {}
        if (this.dealerId) {
            queryParams.dealerId = this.dealerId
        }
        if (this.senderId) {
            queryParams.senderId = this.senderId
        }
        if (this.rewriteSenderId) {
            queryParams.rewriteSenderId = this.rewriteSenderId
        }

        ;(this.isNewFileFormatSelected ? softUploadOrders : uploadOrders)({ file: this.file }, queryParams).then(
            (res) => {
                this.dealerId = null
                this.senderId = null
                this.page = 0
                const { successfullyUploaded, uploadedBefore, unsuccessfullyUploaded, collissions, updated } = res

                this.totalCollisions = collissions.length
                this.collisions = collissions

                this.totalInserted = successfullyUploaded.length
                this.insertedRequests = successfullyUploaded

                this.totalDuplicated = uploadedBefore.length
                this.duplicatedRequests = uploadedBefore

                this.totalErrors = unsuccessfullyUploaded.length
                this.errorRequests = unsuccessfullyUploaded

                this.totalUpdated = updated.length
                this.updatedRequests = updated

                if (this.totalErrors) {
                    this.setSelectedTable(3)
                } else if (this.totalCollisions) {
                    this.setSelectedTable(4)
                }
                if (this.totalCollisions) {
                    this.collisionsFromFile = this.collisions.map((el, index) => {
                        return el.fromFile
                    })
                }
                closeModalFn['add-request']()
                closeModalFn['progress-backdrop']()
            },
            () => closeModalFn['progress-backdrop']()
        )
    }
}

export default FileUploadStore
