import { makeAutoObservable } from 'mobx'
import { orderFinancialSourcesStats } from '../../../services/ApiService'
import { closeModalFn, openModalFn } from '../../../utils/controllers/ModalController'

class StatisticsSubsidiesStore {
    rows = []
    isLoading = true
    constructor() {
        makeAutoObservable(this)
    }

    setRows = (rows) => {
        this.rows = rows
    }

    getRows = (param) => {
        openModalFn['progress-backdrop']()
        orderFinancialSourcesStats(param)
            .then((res) => {
                this.rows = res
                this.isLoading = false
            })
            .then(() => {
                closeModalFn['progress-backdrop']()
            })
    }
}

const todoStore = new StatisticsSubsidiesStore()
export default todoStore
