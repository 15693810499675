import React from "react"
import carouselDefaultIcon from "../../assets/images/slider/carouselDefaultButton.svg"
const CarouselButton = ({useTriangle, color, disabled, clickHandler, customBtn, isLeftIcon}) => {
    return (
        <>
            {customBtn ? (
                <div
                    disabled={disabled}
                    onClick={clickHandler}
                    style={{
                        position: "absolute",
                        left: isLeftIcon ? "0" : "",
                        right: isLeftIcon ? "" : "0",
                        transform: isLeftIcon ? "" : "rotate(180deg)"
                    }}
                >
                    {customBtn}
                </div>
            ) : (
                <div className={`carousel-${isLeftIcon ? "left" : "right"}-arrow carousel-control`}>
                    <div
                        className={useTriangle ? `${isLeftIcon ? "left" : "right"}-triangle` : ""}
                        style={{
                            ...(isLeftIcon
                                ? {borderLeftColor: useTriangle ? color : ""}
                                : {borderRightColor: useTriangle ? color : ""})
                        }}
                    />{" "}
                    <button
                        className={!useTriangle ? `padding-${isLeftIcon ? "left" : "right"}-15` : ""}
                        disabled={disabled}
                        onClick={clickHandler}
                    >
                        <img src={carouselDefaultIcon} alt="prev" />
                    </button>
                </div>
            )}
        </>
    )
}

export default CarouselButton
