import Typography from '@mui/material/Typography'
import React, { useMemo } from 'react'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import TooltipButton from 'src/components/CustomTooltip/TooltipButton'
import AppbarInfoContent from 'src/components/CustomTooltip/TooltipInfoContent'
import { isAdministration, isDealer, isOperator } from '../../../services/AccountService'
function AppbarTitle({ title }) {
    const theme = useTheme()
    const infoData = {
        Инфографика: [
            'Страница представляет из себя набор виджетов, иллюстрирующих основные данные по поступающим заявкам и выполненным установкам по различным срезам.',
            'Для более подробной информации обратитесь к справке по каждому отдельному виджету.',
        ],
        'Заявки на установку': [
            'Это основная административная страница системы, которая позволяет осуществлять поиск среди всех заявок в любой момент их жизненного цикла в системе.',
            'Страница содержит панель поиска и таблицу заявок, в которой отображаются результаты поиска.',
            'Если параметры поиска не заданы, система по умолчанию выводит все зарегистрированные заявки.',
            'Клик по строке заявки в таблице позволяет просмотреть всю информацию по заявке и по стадиям её обслуживания.',
            'Администратор может отслеживать заявки, назначать их на дилеров, контролировать выполнение, помечать повторяющиеся заявки как дубликаты и многое другое.',
        ],
        'Проверка отчётов': [
            'Это административная страница, на которой Администратор выполняет проверку отчётов, создаваемых дилерами за каждый отчётный период. Страница содержит панель поиска и таблицу найденных дилерских отчётов, удовлетворяющих параметрам поиска.',
            'Клик на строке таблицы позволяет просматривать список заявок в отчёте, информацию о самом отчёте, а также детальную информацию о каждой заявке.',
            'Администратор может направить заявку на уточнение, подтвердить, убрать из отчёта. А так же принять или расформировать отчёт.',
        ],
        'Статистика заявок по добавлению': [
            'Эта страница содержит служебный отчёт по количеству заявок, поступающих в систему, а также показывает Пользователя и дату регистрации заявок в системе.',
            'По типу добавления заявки различаются:',
            '“Интерфейс системы CRM” - если заявка добавлена Пользователем (Сотрудником офиса, Сотрудником районной администрации, оператором колл-центра или дилером).',
            '“Форма сайта” - если заявка была оформлена самим заявителем через сайт поставщика услуг и система автоматически распознала информацию о заявке.',
            '“Файл” - если заявка была прогружена в систему через файл специального формата.',
        ],
        'Статистика статусов заявок': [
            'Эта страница содержит внутренний служебный отчёт по количеству заявок, изменивших своё состояние (статус) в процессе своего жизненного цикла с момента регистрации в системе.',
            'Отчёт носит справочный характер и необходим для визуализации данных на странице “Инфографика”.',
        ],
        'Статистика заявок по регионам': [
            'Эта страница содержит служебный отчёт по количеству заявок, зарегистрированных в системе, в разрезе регионов и позволяет количественно оценить распределение заявок по регионам.',
            'Отчёт носит справочный характер и необходим для визуализации данных на странице “Инфографика”.',
        ],
        'Статистика установок по регионам': [
            'Эта страница содержит служебный отчёт по количеству установок оборудования (обслуженных заявок) в системе в разрезе регионов и позволяет количественно оценить распределение установок по регионам.',
            'Отчёт носит справочный характер и необходим для визуализации данных на странице “Инфографика”.',
        ],
        'Статистика по отчётам дилеров': [
            'Эта страница позволяет получить  внутренний служебный отчёт по количеству установок оборудования (обслуженных заявок) в разрезе  дилеров и получить количества обслуженных ими заявок за отчётный период.',
            'Также страница показывает распределение уже обслуженных заявок по дилерским отчётам.',
        ],
    }
    const content = useMemo(() => infoData[title], [title])

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
                sx={{
                    fontSize: '20px',
                    fontWeight: '500',
                    color: theme.palette.text.semidarkGray,
                    marginRight: '8px',
                    [theme.breakpoints.down('md')]: {
                        fontSize: '16px',
                    },
                    [theme.breakpoints.down('sm')]: {
                        fontSize: isDealer() ? '14px' : '16px',
                    },
                }}
            >
                {title}
            </Typography>
            {content && !isDealer() && !isOperator() && !isAdministration() ? (
                <TooltipButton
                    content={<AppbarInfoContent data={content} title={title} />}
                    iconColor={'blue'}
                    titleIcon={'blue'}
                    title={title}
                />
            ) : null}
        </Box>
    )
}

export default AppbarTitle
