import React from "react"
import {Font, StyleSheet,View,Page,Text} from "@react-pdf/renderer"
import PTRootUI from "src/assets/fonts/Arimo/Arimo-Regular.ttf"
import PTRootUIBold from "src/assets/fonts/Arimo/Arimo-Bold.ttf"
import {agreementPDFStyles} from "src/views/Agreements/components/AgreementPDF/agreementPDFStyles"
import {convertId} from "src/helpers/ordersUtiles"

Font.register({
    family: "PT Root UI",
    fonts: [
        {src: PTRootUI},
        {src: PTRootUIBold, fontWeight: 700}
    ]
})


const styles = StyleSheet.create(agreementPDFStyles())

function InventoryPDF({orders,fileName}){

    const columns = [
        {
            title: "Имя файла документа",
            width: "50%",
            content: fileName
        },
        {
            title: "Заявок в документе",
            width: "25%",
            content: orders.length
        },
        {
            title: "Страниц в документе",
            width: "25%",
            content: orders.length * 2 + 2
        },
    ]

    return(
        <>
            <Page size="A4" style={styles.page}>
                <View style={{marginBottom: "16px"}}>
                    <View style={[styles.tableRow, {marginTop: 20}]}>
                        {columns.map((column, index) => {
                            return (<View style={[styles.tableHeaderCell, {
                                width: column.width, borderLeft: !index && "0.65pt solid #000",
                                borderRight: "0.65pt solid #000"
                            }]}>
                                <Text style={styles.text}>{column.title}</Text>
                            </View>)
                        })
                        }
                    </View>
                    {
                        <View wrap={false} style={[styles.tableRow, {borderBottom: "0.65pt solid #000"}]}>
                            {columns.map((column, index) => <View style={[styles.tableCell, {
                                    width: column.width, borderLeft: !index && `0.65pt solid #000`,
                                    borderRight: "0.65pt solid #000"
                                }]}>
                                    <Text style={styles.text}>{column.content}</Text>
                                </View>
                            )}
                        </View>
                    }
                </View>


                <View style={styles.inventoryTextContainer}>
                    <Text style={[styles.text, styles.textBold]}>
                        Перечень номеров заявок для автоматически сформированных Договоров
                    </Text>
                </View>
                <View style={styles.inventoryTable}>
                    {orders.map((order, i) => {
                        return(
                            <View style={styles.inventoryRow}>
                                <Text style={styles.text}>
                                    {++i}
                                </Text>
                                <Text style={styles.footerText}>
                                    {convertId(order.id)}
                                </Text>
                            </View>
                        )
                    })}
                </View>
            </Page>
            <Page size="A4" style={styles.page}>
            </Page>
        </>
    )
}

export default InventoryPDF