import React, {useEffect} from "react"
import IntlProvider from "react-intl/lib/src/components/provider"
import {createIntl} from "react-intl"
import {setGState} from "../utils/controllers/GlobalStateController"

const SatIntlProvider = ({children}) => {
    const intlConfig = {
        key: "ru-RU",
        locale: "ru-RU",
        messages: [],
        onError: () => {
        }
    }

    useEffect(() => {
        let intl = createIntl(intlConfig)
        setGState("intl", intl)
    })

    return (
        <IntlProvider
            {...intlConfig}
        >
            {children}
        </IntlProvider>
    )
}

export default SatIntlProvider