import moment from "moment"
import FormStore from "../../../utils/stores/FormStore"

const initState = {
    dealer: {
        displayName: "Все дилеры",
        subjectId: "00000000-0000-0000-0000-000000000001"
    },
    reportMonth: moment().format("MM"),
    reportYear: moment().format("YYYY")
}

const validateData = {
    dealer: {required: true}
}

class DataSettingsFilterStore extends FormStore {
    constructor() {
        super({initState, validateData})
    }

    clear = () => {
        this.setData({
            dealer: "",
            reportMonth: "",
            reportYear: ""
        })
        this.clearSelectedFilters()
    }
}

export default DataSettingsFilterStore
