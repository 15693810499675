import {makeAutoObservable} from "mobx"
import {getInstallationReasons} from "../services/ApiService"

class InstallationReasonStore {
    constructor() {
        this.allInstallationReasons = []
        this.availableReasons = []
        this.reasons = []
        makeAutoObservable(this)
    }

    load = () => {
        getInstallationReasons().then((res) => {
            this.allInstallationReasons = res
            this.availableReasons = res.filter((reason) => !reason.isHidden)
            this.reasons = this.availableReasons.map((reason) => ({key: reason.name, value: +reason.id}))
        })
    }

    getInstallationReason = (orderReasonId) => {
        this.allInstallationReasons.find((reason) => reason.id === orderReasonId)
    }

    getInstallationReasonName = (orderReasonId) => {
        const reason = this.allInstallationReasons.find((reason) => reason.id === orderReasonId)
        if (reason?.name === "Замена") {
            return reason?.name + " (Горыныч)"
        }
        return reason?.name ? reason.name : ""
    }

    getInstallationReasonId = (orderReasonId) => {
        const reason = this.allInstallationReasons.find((reason) => reason.id === orderReasonId)
        return reason?.id ? reason.id : ""
    }
}

export default InstallationReasonStore
