import React from 'react'
import { observer } from 'mobx-react'
import { Box, Checkbox, Typography } from '@mui/material'
import { store } from '../../store/EditorStore'

export const HeaderList = observer(({ all, selected, available, white, items, children }) => {
    const { toggleCheckAll } = store

    const checkedAll = items.filter((item) => !item.isWhitelisted).length && items.filter((item) => !item.isWhitelisted).every((item) => item.checked)

    const indeterminate = items.filter((item) => !item.isWhitelisted).some((item) => item.checked) && !checkedAll
    // `${selected}/${available} выбрано ${all} всего н.п.`}
    return (
        <Box sx={{ borderBottom: '1px solid #ddd' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: '4px 16px' }}>
                <Checkbox
                    checked={checkedAll}
                    indeterminate={indeterminate}
                    disabled={!available}
                    onClick={() => toggleCheckAll(white)}
                    tabIndex={-1}
                    disableRipple
                />
                <Box>
                    <Typography
                        variant='h6'
                        sx={{
                            fontWeight: '500',
                            color: 'rgb(169, 169, 169)',
                            textAlign: 'left',
                            padding: '0px 10px',

                            position: 'sticky',
                            top: 0,
                            backgroundColor: '#fff',
                            zIndex: 1,
                        }}
                    >
                        {children}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            color: 'rgb(169, 169, 169)',
                            textAlign: 'left',
                            padding: '0px 10px',
                        }}
                    >
                        {white
                            ? `${selected}/${available} выбрано`
                            : `${selected}/${available} выбрано`}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
})
