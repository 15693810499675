import {Text, View} from "@react-pdf/renderer"
import {formatName} from "src/helpers/ordersUtiles"
import React from "react"

export function FirstParagraph({styles,name,operatorName}) {

    return(
        <View style={styles.paragraph}>
            <Text style={styles.text}>
                <Text style={styles.textBold}>
                    Общество с ограниченной ответственностью «Русский Мир»
                </Text> (ООО «Русский Мир»), в лице {operatorName} , действующего на основании доверенности от 01.06.2023 № 2, именуемое в дальнейшем «
                <Text style={styles.textBold}>
                    Оператор
                </Text>», с одной стороны, и гражданин <Text style={styles.textUnderline}>{name}</Text>, c
                другой стороны, именуемый в дальнейшем «
                <Text style={styles.textBold}>
                    Абонент
                </Text>», далее совместно именуемые как «Стороны», заключили настоящий договор (далее – «Договор») о следующем:
            </Text>
        </View>
    )
}