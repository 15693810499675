import React, {useEffect} from "react"
import CustomTable from "../../../utils/components/CustomTable/CustomTable"
import {useIntl} from "react-intl"
import {observer} from "mobx-react"

const DataSettingsTable = ({store, onRowClick}) => {
    const intl = useIntl()
    const {
        getTableData,
        pageSize,
        pageNumber,
        onPageChange,
        onPageSizeChange,
        selectedCollection,
        totalSize,
        isLoading,
        onSearch
    } = store

    useEffect(() => {
        onSearch()
    }, [pageSize, pageNumber])

    const columns = [
        {
            field: "date",
            headerName: intl.formatMessage({id: "Дата"}),
            renderCellValue: (params) => params.promiseDate,
            minWidth: "180px"
        },
        {
            field: "dealerId",
            headerName: intl.formatMessage({id: "Дилер"}),
            renderCellValue: (params) => params.dealer,
            headerStart: true,
            minWidth: "190px",
            justifyContent: "flex-start"
        },
        {
            field: "editableCountOD",
            headerName: intl.formatMessage({id: "Количество установок ОД"}),
            minWidth: "130px",
            renderCellValue: (params) => params.count
        },
        {
            field: "lastChangeAuthor",
            headerName: intl.formatMessage({id: "Автор последних изменений"}),
            minWidth: "130px",
            renderCellValue: (params) => params.author,
            headerStart: true,
            justifyContent: "flex-start"
        },
        {
            field: "updatedAt",
            headerName: intl.formatMessage({id: "Дата последних изменений"}),
            minWidth: "130px",
            renderCellValue: (params) => params.updatedAt
        }
    ]

    return (
        <CustomTable
            rows={selectedCollection}
            columns={columns}
            pageSize={pageSize}
            page={pageNumber}
            total={totalSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onRowClick={onRowClick}
            isLoading={isLoading}
        />
    )
}

export default observer(DataSettingsTable)
