const mainMenuItemStyles = (theme) => ({
    menuDrawer: {
        '&.MuiPaper-root': {
            background: theme.palette.blue.mainMenu,
            width: '420px',
            borderRight: 'none',
            boxSizing: 'border-box',
            backdropFilter: 'blur(5px)',
            boxShadow: ' 4px 0px 6px 0px rgba(0, 0, 0, 0.25)',

            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
    },
    adminIconWrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: '16px 0 16px 19px',
        height: '59px',
        textTransform: 'uppercase',
        color: '#FFF',
    },

    closeMenuIconWrapper: {
        paddingLeft: '16px',
        display: 'flex',
        alignItems: 'center',
    },

    closeMenuIcon: {
        cursor: 'pointer',
    },

    adminIconText: {
        marginLeft: '16px',
        fontWeight: 500,
        fontSize: '20px',
        cursor: 'default',
        color: theme.palette.text.blueLight,
    },
    versionText: { fontSize: '14px', fontWeight: 500 },
})

export default mainMenuItemStyles
