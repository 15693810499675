import React from 'react'
import ReactDOM from 'react-dom/client'
import { configure } from 'mobx'
import { Provider as MobxProvider } from 'mobx-react'
import '../src/utils/styles/styles.css'
import '../src/styles/styles.css'
import '../src/stores/storeInit'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers'
import SatIntlProvider from './services/SatIntlProvider'
import SatThemeProvider from './services/ThemeProvider/SatThemeProvider'
import 'react-toastify/dist/ReactToastify.css'
import MyToastifyContainer from './services/ToastifyService/MyToastifyContainer'
import App from 'src/App'
import { AuthProvider } from 'src/services/AuthProvider'

configure({
    enforceActions: 'never',
})

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <SatIntlProvider>
        <MyToastifyContainer />
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <SatThemeProvider>
                <MobxProvider>
                    <App />
                </MobxProvider>
            </SatThemeProvider>
        </LocalizationProvider>
    </SatIntlProvider>
)
