const dateAndTimeStyles = (theme, length) => ({
    wrapper: {
        display: "flex",
        alignItems: "end",
        height: "100%",
        gap: "10px",
        paddingBottom: "13px",
        color: "#CBE3FF",
        [theme.breakpoints.down(1170)]: {
            display: "none"
        },
        position: "absolute",
        top: 0,
        left: "50%",
        transform: "translateX(-50%)"
    },
    text: {
        fontSize: "20px",
        fontWeight: "500"
    },

    colon: {
        animationDuration: "2s",
        animationName: "blink",
        animationIterationCount: "infinite",

        "@keyframes blink": {
            0: {
                opacity: 1
            },
            "50%": {
                opacity: 0.3
            },
            "100%": {
                opacity: 1
            }
        }
    }
})

export default dateAndTimeStyles
