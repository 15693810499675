import {Box} from "@mui/material"
import React, {useEffect} from "react"
import "./style/PhotoSliderCss.css"
import {getStyles} from "../../utils/utils/commonUtils"
import carouselStyles from "./style/carouselStyle"
import classNames from "classnames"
const CarouselIndicators = ({nextActiveIndex, clickHandler, slides, initialIndex, setZoom}) => {
    const classes = getStyles(carouselStyles)

    useEffect(() => {
        showThisSlide(initialIndex, nextActiveIndex)
    }, [])

    useEffect(() => {
        const targetElement = document.getElementById(`carousel-indicators-slide-${nextActiveIndex}`)
        targetElement.classList.add("active")
        targetElement.scrollIntoView({behavior: "smooth", block: "center"})
    }, [nextActiveIndex])

    const showThisSlide = (i, nextActiveIndex) => {
        setZoom(false)
        slides.length !== 1 && i !== nextActiveIndex && clickHandler(i)
    }

    return (
        <Box sx={classes.indicatorsWrapper}>
            {slides.map((el, i) => {
                return (
                    <img
                        id={`carousel-indicators-slide-${i}`}
                        key={i}
                        className={classNames({
                            active: i === nextActiveIndex
                        })}
                        style={classes.indicatorsImg}
                        alt={`slide number ${i}`}
                        src={el}
                        onClick={(e) => showThisSlide(i, nextActiveIndex)}
                    />
                )
            })}
        </Box>
    )
}

export default CarouselIndicators
