import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import Box from '@mui/material/Box'

import menu from '../../assets/images/common/menu.svg'
import { isAdmin,  } from '../../services/AccountService'
import { getStyles } from '../../utils/utils/commonUtils'
import commonStyles from '../../styles/commonStyles'
import withWidth, { isWidthUp } from '@mui/material/Hidden/withWidth'
import { useIntl } from 'react-intl'
import { Button, Typography, useTheme } from '@mui/material'
import exitIcon from '../../assets/images/layout/exitWhite.svg'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import DateAndTime from '../DateAndTime/DateAndTime'
import logo from '../../assets/images/layout/spelled_logo.svg'
import adminLogo from '../../assets/images/layout/RThreeColorLogo.svg'
import layoutStyles from '../Layout/styles/layoutStyles'
import { gState } from '../../utils/controllers/GlobalStateController'
import avatarToAdmin from '../../assets/images/layout/avatarToAdmin.svg'
import AppbarUserInfo from './AppbarUserInfo'
import AppbarTitle from 'src/components/Appbar/AppbarTitle/AppbarTitle'
import { useLocation } from 'react-router-dom'
import { useAuth } from 'src/services/AuthProvider'


import { DevHelper } from '../DevHelper/DevHelper'
import { Portal } from '@mui/material'
function Appbar({ toggleMenu, width, routesData }) {
    
    const location = useLocation()
    const { logout } = useAuth()
    const classes = { ...getStyles(commonStyles), ...getStyles(layoutStyles) }
   
    
    
    const routeObj = routesData.find((el) => el.route && location.pathname === el.route)
    const title = routeObj ? routeObj.name : ''
    const [dev, setDev] = useState(false)
    const [tools, setTools] = useState(false)

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key.toLowerCase() === 'y') {
                event.preventDefault()
                setDev((prev) => !prev)
                setTools(false)
            }
        }
        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [dev, tools])

    return (
        <AppBar component={'header'} sx={classes.appbar}>
            <Box className={'aln-center'} sx={{ gap: '15px' }}>
                <IconButton onClick={toggleMenu} sx={classes.adminBtn}>
                    <img draggable='false' alt={'burger icon'} src={menu} />
                </IconButton>

                {isWidthUp('sm', width) && gState['roles'] && (
                    <Box ml={1.5} sx={{ display: 'flex', alignItems: 'center' }}>
                        {isAdmin() && <img draggable='false' alt={'logo'} src={adminLogo} />}
                    </Box>
                )}
                <AppbarTitle title={title} />
                {dev ? (
                    <Portal>
                        <Box
                            sx={{
                                position: 'fixed',
                                top: '4px', 
                                left: '435px', 
                                zIndex: 2147483647, 
                            }}
                        >
                            <Button
                                sx={{
                                    color: tools ? '#00ff16' : '#fff',
                                    textShadow: tools ? '0px 0px 5px #00ff16' : 'none',
                                }}
                                onClick={() => setTools((prev) => !prev)}
                                variant='contained'
                            >
                                DEV
                            </Button>
                            {tools ? (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '55px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        padding: '8px',
                                        width: 'auto',
                                        whiteSpace: 'nowrap',
                                        minHeight: '200px',
                                        borderRadius: '8px',
                                        backgroundColor: '#d7d7d7',
                                    }}
                                >
                                    <Typography sx={{ fontSize: '14px', color: '#757575', textAlign: 'center' }}>
                                        Для выхода нажмите Ctrl + Y
                                    </Typography>
                                    <DevHelper />
                                </Box>
                            ) : null}
                        </Box>
                    </Portal>
                ) : null}
            </Box>
            <DateAndTime />
            <Box className={'aln-center'}>
                <Box sx={{ ...classes.adminAccInfo }}>
                    <img draggable='false' alt={'avatarToAdmin'} src={avatarToAdmin} />

                    <AppbarUserInfo />
                </Box>
                {/*TODO все закоменнченое - это выпадающее меню, может понадобится для мобилки*/}
                {/*<Box*/}
                {/*    ml={4}*/}
                {/*    onClick={() => {*/}
                {/*        setIsOpenUserMenu(() => !isOpenUserMenu)*/}
                {/*    }}*/}
                {/*    sx={{*/}
                {/*        borderRadius: '50%',*/}
                {/*        display: 'flex',*/}
                {/*        alignItems: 'center',*/}
                {/*        justifyContent: 'center',*/}
                {/*        cursor: 'pointer',*/}
                {/*        position: 'relative',*/}
                {/*        '&:hover': {*/}
                {/*            background: theme.palette.blue.active*/}
                {/*        }*/}
                {/*    }}*/}
                {/*    ref={userMenuRef}*/}
                {/*>*/}
                {/*    <img alt={''} src={arrow} className={'pointer'}/>*/}
                {/*    <SelectMenu*/}
                {/*        buttonRef={userMenuRef}*/}
                {/*        isOpen={isOpenUserMenu}*/}
                {/*        close={() => {*/}
                {/*            setIsOpenUserMenu(false)*/}
                {/*        }}*/}
                {/*        menuItems={[*/}
                {/*            {*/}
                {/*                name: intl.formatMessage({id: 'Выход'}),*/}
                {/*                onClick: signOut,*/}
                {/*                startAdornment: <img src={exitIcon} alt={''}/>*/}
                {/*            }*/}
                {/*        ]}*/}
                {/*        menuStyles={{*/}
                {/*            right:'0',*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</Box>*/}
                {/* </>
                )} */}
                <IconButton sx={classes.adminBtn} onClick={logout}>
                    <img draggable='false' alt={'exit'} src={exitIcon} />
                </IconButton>
            </Box>
        </AppBar>
    )
}

export default withWidth()(observer(Appbar))
