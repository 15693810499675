import fxStyles from 'src/utils/styles/fxStyles'
import { isAdmin } from 'src/services/AccountService'
import { isDealer, isOperator } from '../../../services/AccountService'
import { checkMobileDevice } from '../../../utils/utils/commonUtils'

const layoutStyles = (theme) => {
    return {
        userInfo: {
            [theme.breakpoints.down(480)]: {
                display: 'none',
            },
        },
        appbar: {
            background: 'linear-gradient(180deg, #023A66 -29.17%, #0871C3 377.08%)',
            height: 'var(--appbar-height)',
            boxShadow: 'none',
            flexDirection: 'row',
            padding: '0 16px',
            position: 'sticky',
            cursor: 'default',
            ...fxStyles.jstSB,
            ...fxStyles.alnCenter,
            padding: '0 16px !important',

            [theme.breakpoints.down('sm')]: {
                padding: '0 8px',
            },
        },
        topButton: {
            display: 'block',
            position: 'fixed',
            zIndex: 2,
            bottom: '80px',
            right: '-80px',
            minWidth: '0 !important',
            padding: '0 !important',
            margin: '0 !important',
            borderRadius: '50% !important',
            width: '48px',
            height: '48px',
            backgroundColor: 'rgba(22,104,185, 0.15)',
            transition: 'all 0.3s ease-in-out',
        },
        topButtonShow: {
            right: '16px',
        },
        topButtonImage: {
            width: '48px',
            height: '48px',
            transform: 'rotate(180deg)',
        },
        contentContainer: {
            backgroundColor: theme.palette.background.lightGray,
            minHeight: 'calc(100vh - var(--appbar-height) - var(--footer-height))',
            padding: '32px 72px',
            [theme.breakpoints.down('lg')]: {
                padding: '32px 32px 0 32px',
            },
            [theme.breakpoints.down('1024')]: {
                padding: '16px 16px 0 16px',
            },
            [theme.breakpoints.down('744')]: {
                padding: '8px 8px 0 8px',
            },
            [theme.breakpoints.down('sm')]: {
                padding: '8px 8px 0 8px',
                minHeight: 'calc(100vh - var(--appbar-height))',
            },
        },
    }
}

export default layoutStyles
