import React, {useCallback} from "react"
import Chip from "../../../components/Chips/Chip"
import {observer} from "mobx-react"
import DatesStore from "../../../stores/DatesStore"

function DataSettingsFilterChip({propName, value, onClick}) {
    const {getMonthName} = new DatesStore()

    const setChipAction = useCallback(() => {
        return onClick ? () => onClick(propName, value) : null
    }, [value])

    const setValue = useCallback(() => {
        switch (propName) {
            case "dealer": {
                return value.displayName
            }

            case "reportMonth": {
                return getMonthName(value, false, true)
            }

            case "reportYear": {
                return value
            }
        }
    }, [value])

    return <Chip action={setChipAction()} value={setValue()} />
}

export default observer(DataSettingsFilterChip)
