import React from "react";
import { TabNavigationButton } from "./TabNavigationButton/TabNavigationButton";
import { Box } from "@mui/material";
import fullSubsidy from "src/assets/images/financialSource/fullSubsidy.svg";
import fullSubsidyGray from "src/assets/images/financialSource/fullSubsidyGray.svg";
import partialSubsidy from "src/assets/images/financialSource/partialSubsidy.svg";
import partialSubsidyGray from "src/assets/images/financialSource/partialSubsidyGray.svg";
import fullFree from "src/assets/images/financialSource/fullFree.svg";
import fullFreeGray from "src/assets/images/financialSource/fullFreeGray.svg";
import { observer } from "mobx-react";

export const TabNavigation = observer(() => {
    const buttons = [
        {
            tab: "1",
            text: "ПОЛНАЯ ДОТАЦИЯ",
            icon: fullSubsidy,
            iconGray: fullSubsidyGray,
        },
        {
            tab: "2",
            text: "ЧАСТИЧНАЯ ДОТАЦИЯ",
            icon: partialSubsidy,
            iconGray: partialSubsidyGray,
        },
        {
            tab: "3",
            text: "ЗА СЧЁТ АБОНЕНТА",
            icon: fullFree,
            iconGray: fullFreeGray,
        },
    ];
    return (
        <Box sx={{ display: "flex", gap: "4px" }}>
            {buttons.map(({ text, icon, iconGray, tab }) => {
                return <TabNavigationButton key={tab} text={text} tab={tab} icon={icon} iconGray={iconGray} />;
            })}
        </Box>
    );
});
