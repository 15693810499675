import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Box, Typography } from '@mui/material'
import questionIcon from 'src/assets/images/countRequestCards/questionIcon.svg'

import TooltipButton from 'src/components/CustomTooltip/TooltipButton'
import TooltipInfoContent from 'src/components/CustomTooltip/TooltipInfoContent'

import SummaryStatisticsDealersStoreInstance from '../../store/SummaryStatisticsDealersStore'

export const CountCard = observer(({ icon, title, count, detail, colorCount, keywords }) => {
    const { activeToggleBlockItem, openRequest } = SummaryStatisticsDealersStoreInstance

    return (
        <Box
            sx={{
                width: '100%',
                alignItems: 'center',
                padding: '8px 24px 8px 24px',
                borderRadius: '6px',
                backgroundColor: 'rgba(255, 255, 255, 1)',
            }}
            key={title}
        >
            <Box sx={{ display: 'flex', gap: '4px', marginBottom: '8px' }}>
                <Box component='img' src={icon} alt='Иконка счётчика' />
                <Typography sx={{ fontWeight: '400', fontSize: '12px', whiteSpace: 'pre' }}>{title}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '4px', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: '500', fontSize: '36px', color: colorCount }}>{count}</Typography>

                <Box
                    sx={{
                        position: 'relative',
                        cursor: 'pointer',
                    }}
                >
                    {true && (
                        <TooltipButton
                            content={
                                <TooltipInfoContent
                                    title={title}
                                    data={detail[activeToggleBlockItem]}
                                    keywords={keywords}
                                />
                            }
                            title={title}
                            titleIcon={'blue'}
                            placement={'bottom'}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    )
})
