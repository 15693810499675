import {Text, View} from "@react-pdf/renderer"
import {formatAddress, formatName} from "src/helpers/ordersUtiles"
import React from "react"

export function ParagraphSix({styles, operatorName, operatorPosition, order}) {
    const {territory, equipmentId, name, surname, phone, patronymic} = order

    return (
        <View style={styles.paragraph}>
            <Text style={styles.title}>
                6. РЕКВИЗИТЫ И ПОДПИСИ СТОРОН.
            </Text>
            <View style={styles.footerContainer}>
                <View style={styles.footerOperatorContent}>
                    <View style={styles.footerContentTitleRow}>
                        <Text style={styles.title}>
                            Оператор:
                        </Text>
                    </View>
                    <View style={styles.footerColumn}>
                        <View>
                            <Text style={[styles.footerText, {marginBottom: "4px"}]}>
                                Общество с ограниченной ответственностью «Русский Мир» (ООО «Русский Мир»)
                            </Text>
                            <Text style={styles.footerText}>
                                Адрес: Россия, Луганская Народная Республика, 291007 г. Луганск, ул. Ленина, 36А,
                                помещение 21
                            </Text>
                            <Text style={styles.footerText}>
                                ОГРН 1229400000136
                            </Text>
                            <Text style={styles.footerText}>
                                ИНН/КПП 9403000056/940201001
                            </Text>
                            <Text style={styles.footerText}>
                                Банковские реквизиты:
                            </Text>
                            <Text style={styles.footerText}>
                                К/с 3010181 0000 0000 00920
                            </Text>
                            <Text style={styles.footerText}>
                                Р/с 40702810906000083357
                            </Text>
                            <Text style={styles.footerText}>
                                Банк Ст-Петербургский ф-л ПАО «Промсвязьбанк»
                            </Text>
                            <Text style={styles.footerText}>
                                БИК 044030920
                            </Text>
                            <Text style={styles.footerText}>
                                Е-mail: info@rusmirtv.ru
                            </Text>
                        </View>

                        <View style={{position: "relative"}}>
                            <View style={{marginBottom: "16px"}}>
                                <Text style={styles.footerText}>
                                    {operatorPosition}
                                </Text>
                            </View>
                            <View style={styles.footerSubscriberRow}>
                                <View style={styles.footerValueRow}>
                                    <Text style={styles.footerText}>
                                        {" "}
                                    </Text>
                                </View>
                                <Text style={styles.footerText}>
                                    {operatorName}
                                </Text>
                            </View>
                            <View style={{position: "absolute", bottom: "-4px", left: "40%"}}>
                                <Text style={styles.footerText}>
                                    м.п.
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={styles.footerSubscriberContent}>
                    <View style={styles.footerContentTitleRow}>
                        <Text style={styles.title}>
                            Абонент:
                        </Text>
                    </View>
                    <View style={styles.footerColumn}>
                        <View>
                            <View style={styles.footerSubscriberRow}>
                                <Text style={styles.text}>
                                    ID
                                </Text>
                                <View style={styles.footerValueRow}>
                                    <Text style={[styles.text, styles.textBold]}>
                                        {equipmentId}
                                    </Text>
                                </View>
                            </View>

                            <View style={styles.footerSubscriberRow}>
                                <Text style={styles.text}>
                                    Фамилия
                                </Text>
                                <View style={styles.footerValueRow}>
                                    <Text style={[styles.text, styles.textBold]}>
                                        {surname}
                                    </Text>
                                </View>
                            </View>

                            <View style={styles.footerSubscriberRow}>
                                <Text style={styles.text}>
                                    Имя
                                </Text>
                                <View style={styles.footerValueRow}>
                                    <Text style={[styles.text, styles.textBold]}>
                                        {name}
                                    </Text>
                                </View>
                            </View>

                            <View style={styles.footerSubscriberRow}>
                                <Text style={styles.text}>
                                    Отчество (при наличии)
                                </Text>
                                <View style={styles.footerValueRow}>
                                    <Text style={[styles.text, styles.textBold]}>
                                        {patronymic}
                                    </Text>
                                </View>
                            </View>

                            <View>
                                <View style={styles.footerSubscriberRow}>
                                    <Text style={styles.text}>
                                        Паспорт
                                    </Text>
                                    <View style={styles.footerValueRow}>
                                        <Text style={[styles.text, styles.textBold]}>
                                        </Text>
                                    </View>
                                    <Text style={styles.text}>выдан «<Text style={[styles.text, styles.textUnderline]}>         </Text>»<Text style={[styles.text, styles.textUnderline]}>                          </Text>г.</Text>
                                </View>
                            </View>

                            <View style={styles.footerSubscriberRow}>
                                <Text style={styles.text}>
                                    код подразделения
                                </Text>
                                <View style={styles.footerValueRow}>
                                    <Text style={[styles.text, styles.textBold]}>
                                        {" "}
                                    </Text>
                                </View>
                            </View>

                            <View style={styles.footerSubscriberRow}>
                                <Text style={styles.text}>
                                    Дата рождения «<Text style={[styles.text, styles.textUnderline]}>         </Text>»<Text style={[styles.text, styles.textUnderline]}>                          </Text>г.
                                </Text>
                            </View>

                            <View>
                                <View style={styles.footerSubscriberRow}>
                                    <Text style={styles.text}>
                                        Адрес места регистрации
                                    </Text>
                                    <View style={styles.footerValueRow}>
                                        <Text style={[styles.text, styles.textBold]}>
                                        </Text>
                                    </View>
                                </View>
                            </View>

                            <View style={styles.footerSubscriberRow}>
                                <View style={styles.footerValueRow}>
                                    <Text style={[styles.text, styles.textBold]}>
                                        {" "}
                                    </Text>
                                </View>
                            </View>

                            <View>
                                <View style={styles.footerSubscriberRow}>
                                    <Text style={styles.text}>
                                        Адрес места установки приемного оборудования
                                    </Text>
                                    <View style={styles.footerValueRow}>
                                        <Text style={[styles.text, styles.textBold]}>
                                            {" "}
                                        </Text>
                                    </View>
                                </View>
                            </View>

                            <View style={styles.footerSubscriberRow}>
                                <View style={styles.footerValueRow}>
                                    <Text style={[styles.text, styles.textBold]}>
                                        {formatAddress(order) ?? " "}
                                    </Text>
                                </View>
                            </View>

                            <View style={styles.footerSubscriberRow}>
                                <Text style={styles.text}>
                                    Контактный телефон
                                </Text>
                                <View style={styles.footerValueRow}>
                                    <Text style={[styles.text, styles.textBold]}>
                                        {phone}
                                    </Text>
                                </View>
                            </View>

                            <View style={styles.footerSubscriberRow}>
                                <Text style={styles.text}>
                                    E-mail
                                </Text>
                                <View style={styles.footerValueRow}>
                                    <Text style={[styles.text, styles.textBold]}>
                                    </Text>
                                </View>
                            </View>

                        </View>
                        <View>
                            <View style={{marginBottom: "4px"}}>
                                <Text style={[styles.footerText, styles.textBold]}>
                                    Абонент
                                </Text>
                            </View>
                            <View style={styles.footerSubscriberRow}>
                                <View style={styles.footerValueRow}>
                                    <Text style={styles.footerText}>
                                        {" "}
                                    </Text>
                                </View>
                                <Text style={styles.footerText}>
                                    {"/"}
                                </Text>
                                <View style={styles.footerValueRow}>
                                    <Text style={styles.footerText}>
                                        {" "}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}