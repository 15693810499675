import PagedCollectionStore from '../utils/stores/PagedCollectionStore'
import { getCategories } from '../services/ApiService'
import { computed, makeObservable, observable } from 'mobx'

class CategoriesStore extends PagedCollectionStore {
    constructor() {
        super({
            action: getCategories,
        })
        this.categoriesMenuData = []
        makeObservable(this, {
            categoriesMenuData: observable,
            categoryNames: computed,
        })
    }

    postLoad() {
        this.setCategoriesMenuData()
        super.postLoad()
    }

    get categoryNames() {
        return this.collection.map((el) => el.name)
    }

    getInputCategories = (categories) => {
        const result = []
        for (let i = 0; i < categories.length; i++) {
            if (categories[i]) {
                result.push(i)
            }
        }
        return result
    }

    getCategoryName = (id) => {
        const category = this.collection.find((el) => el.id === id)
        return category ? category.name : ''
    }

    formatCategory = (categories) => {
        let resultStr = ''
        categories?.forEach((el, i) => {
            if (el) {
                resultStr += this.categoryNames[i]
                if (i + 1 !== categories.length) {
                    resultStr += ', '
                }
            }
        })
        return resultStr
    }

    getIndexesOfMatchingElements = (categories) => {
        const indexes = []
        this.categoryNames.forEach((item, index) => {
            if (categories.includes(item)) {
                indexes.push(index)
            }
        })
        return indexes
    }

    setCategoriesMenuData = () => {
        this.categoriesMenuData = this.collection.map((preferentialCategory) => {
            return { key: preferentialCategory.name, value: preferentialCategory.id }
        })
    }
}

export default CategoriesStore
