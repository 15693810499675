import {makeAutoObservable} from "mobx"
import {getAuthors} from "../services/ApiService"

class AuthorsStore {
    authors = []

    constructor() {
        makeAutoObservable(this)
    }

    load = () => {
        getAuthors().then((res) => {
            this.authors = res.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1
                }
                return 0
            })
        })
    }

    getName = (guid) => {
        return this.authors.find((author) => author.id === guid)
    }

    getNameReel = (guid) => {
        return this.authors.find((author) => author.id === guid)?.name
    }
}

export default AuthorsStore
