import React, {useEffect, useState} from "react"
import {observer} from "mobx-react"
import FileInput from "../../customElements/FileInput/FileInput"
import FileUploadStore from "../stores/FileUploadStore"
import {css} from "@emotion/css"
import Box from "@mui/material/Box"
import {getStyles} from "../../../utils/utils/commonUtils"
import commonStyles from "../../../styles/commonStyles"
import crossIconDark from "../../../assets/images/common/cross_dark.svg"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import requestsManagementStyles from "../../../views/RequestsManagement/styles/requestsManagementStyles"
import Paper from "@mui/material/Paper"
import Button from "@mui/material/Button"
import {FormattedMessage, useIntl} from "react-intl"
import {getComp} from "../../../utils/utils/DI"
import InputField from "../../customElements/InputField/InputField"
import {modalData} from "../../../utils/controllers/ModalController"
import {Checkbox, Switch} from "@mui/material"

function UploadFileForm() {
    const intl = useIntl()
    const classes = {...getStyles(commonStyles), ...getStyles(requestsManagementStyles)}
    const {
        file, setFile, upload, setDealerId, dealerId, setSenderId, senderId, setRewriteSenderId,
        rewriteSenderId, isNewFileFormatSelected, toggleSelectedFileFormat
    } = getComp("FileUploadStore")
    const {collection} = getComp("DealersStore")
    const {shownSenders} = getComp("SendersStore")

    return (
        <Box>
            {
                modalData["add-request"].dealer ?
                    <Box p={2}>
                        <InputField
                            type={"select"}
                            items={collection.map(el => ({key: el.displayName, value: el.subjectId}))}
                            fullWidth
                            onChange={setDealerId}
                            label={intl.formatMessage({id: "Назначить дилера"})}
                        />
                    </Box> :
                    <Box p={2} className={"fx-col"}>
                        <InputField
                            type={"select"}
                            items={shownSenders.map(el => ({key: el.name, value: el.id}))}
                            fullWidth
                            onChange={setSenderId}
                            label={intl.formatMessage({id: "Установить источник"})}
                        />
                        <Box mt={2} className={"fx-nowrap aln-center"}>
                            <Typography variant={"body1"}>
                                <FormattedMessage id={"Заменить источник заявки"}/>:
                            </Typography>
                            <Checkbox
                                checked={rewriteSenderId}
                                onChange={setRewriteSenderId}
                            />
                        </Box>
                        <Box className={"fx-nowrap aln-center"}>
                            <Typography variant={"body1"}>
                                <FormattedMessage id={"Загрузить файл в новом формате"}/>:
                            </Typography>
                            <Checkbox
                                checked={isNewFileFormatSelected}
                                onChange={toggleSelectedFileFormat}
                            />
                        </Box>
                    </Box>
            }


            {!file ? (
                <Box p={2}>
                    <FileInput
                        file={file}
                        setFile={setFile}
                        fileTypes={["XLSX", "XLS"]}
                        classes={css(classes.fileDropArea)}
                        label={intl.formatMessage({id: "Загрузите или перетащите файл сюда"})}
                    />
                </Box>

            ) : (
                <Box p={2}>
                    <Paper
                        sx={classes.filePaper}
                    >
                        <Typography variant={"subtitle1"}
                                    sx={{maxWidth: "85%", overflow: "hidden", textOverflow: "ellipsis"}}>
                            {file.name}
                        </Typography>
                        <IconButton onClick={() => setFile(null)}>
                            <img alt={""} src={crossIconDark}/>
                        </IconButton>
                    </Paper>
                </Box>
            )}
            {!!file && (
                <Box mt={3} p={2}>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        fullWidth
                        onClick={upload}
                        disabled={modalData["add-request"].dealer ? !dealerId : !senderId}
                    >
                        <FormattedMessage id={"Отправить"}/>
                    </Button>
                </Box>
            )}
        </Box>
    )
}

export default observer(UploadFileForm)