import {makeAutoObservable} from "mobx"
import {getUserInfo} from "../services/ApiService"


class UserInfoStore {
    constructor() {
        this.info = {}
        makeAutoObservable(this)
    }

    load = () => {
        getUserInfo().then((res) => {
            this.info = res
        })
    }

    get userId() {
        return this.info.id || null
    }
}

export default UserInfoStore