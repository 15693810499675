export function agreementPDFStyles() {
    return {
        page: {
            padding: "28px 56px",
            flexDirection: "column",
            backgroundColor: "#FFFFFF",
            position: "relative"
        },
        idBlock: {
            top: "20px",
            left: "56px",
            position: "absolute"
        },
        header: {
            width: "100%",
            display: "flex",
            alignItems: "center"
        },
        subtitleContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "14px 0"
        },
        text: {
            textAlign: "justify",
            fontFamily: "PT Root UI",
            fontSize: 8,
            lineHeight: 1.1,
        },
        textBold: {
            fontWeight: 700
        },
        textUnderline: {
            textDecoration: "underline"
        },
        paragraph: {
            marginBottom: "10px",
            width: "100%"
        },
        title: {
            textTransform: "uppercase",
            fontFamily: "PT Root UI",
            fontSize: 8,
            fontWeight: 700,
            lineHeight: 1
        },
        footerContainer: {
            border: "0.65pt solid #000",
            display: "flex",
            flexDirection: "row",
            height: "280px"
        },
        footerOperatorContent: {
            width: "45%",
            borderRight: "0.65pt solid #000"
        },
        footerSubscriberContent: {
            width: "55%",
        },
        footerContentTitleRow: {
            borderBottom: "0.65pt solid #000",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%"
        },
        footerText: {
            fontFamily: "PT Root UI",
            fontSize: 9,
            lineHeight: 1.25
        },
        footerColumn: {
            height: "100%",
            padding: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        },
        footerSubscriberRow: {
            display: "flex",
            flexWrap: "nowrap",
            flexDirection: "row",
            width: "100%",
            marginBottom: "8px",
        },
        footerValueRow: {
            flexGrow: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            borderBottom: "0.65pt solid #000"
        },
        withUnderlineContainer: {
            display: "flex",
            flexDirection: "row"
        },
        inventoryTable: {
            width: "100%",
            padding: "8px",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            gap: "4px 16px",
            border: "0.65pt solid black",
            maxHeight: "500px"
        },
        inventoryRow: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            gap: "8px",
            width: "25%"
        },
        inventoryTextContainer: {
            marginBottom: "8px"
        },
        tableRow: {
            width: "100%",
            flexDirection: "row"
        },
        tableHeaderCell: {
            padding: "2px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderTop: "1px solid #000",
            borderBottom: "1px solid #000"
        },
        tableCell: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            padding: "2px",
        },
    }
}