import {Text,View} from "@react-pdf/renderer"
import React from "react"

export function ParagraphFour({styles}){

    return(
        <View style={styles.paragraph}>
            <Text style={styles.title}>
                4. ПРАВА И ОБЯЗАННОСТИ СТОРОН.
            </Text>
            <Text style={styles.text}>
                4.1. Абонент и/или Пользователь обязуется получать Услуги от Оператора только для частного, личного, семейного домашнего просмотра, не связанного с осуществлением предпринимательской деятельности.
            </Text>
            <Text style={styles.text}>
                4.2. Абонент обязуется не передавать Карты третьим лицам, не копировать их, не вносить изменения в содержащуюся на них информацию, не осуществлять любое иное несанкционированное использование Карт.
            </Text>
            <Text style={styles.text}>
                4.3. Абонент обязуется предоставить полные, достоверные, актуальные персональные данные и иные сведения в ходе заключения Договора, а также уведомлять Оператора об изменении персональных данных и иных сведений в течение 30 календарных дней с момента наступления изменений.
            </Text>
            <Text style={styles.text}>
                4.4. Абонент обязуется содержать Приёмное оборудование в исправном состоянии, соблюдать правила эксплуатации Приёмного оборудования, использовать актуальное программное обеспечение Приёмного оборудования.
            </Text>
            <Text style={styles.text}>
                4.5. Абонент имеет право на приостановку Услуг на основании его письменного заявления.
            </Text>
            <Text style={styles.text}>
                4.6. Оператор обязуется своевременно информировать Абонента обо всех изменениях условий оказания Услуг в порядке и в сроки, установленные Правилами оказания Услуг.
            </Text>
            <Text style={styles.text}>
                4.7. Оператор обязуется обеспечить возможность Абонента до заключения Договора и начала пользования Услугами ознакомиться с текстом Договора, а также достоверными сведениями о порядке и условиях оказания Услуг, Тарифах, в том числе, но не ограничиваясь, путем размещения информации на Сайте.
            </Text>
            <Text style={styles.text}>
                4.8. Оператор освобождается от ответственности за несоблюдение условий оказания Услуг в случае:
            </Text>
            <Text style={styles.text}>
                4.8.1 если ненадлежащее качество Услуг, вызвано некорректным монтажом и/или настройкой Приёмного оборудования, а также, если технические параметры (свойства) Приёмного оборудования и/или программного обеспечения Приёмного оборудования препятствуют Абоненту пользоваться Услугами;
            </Text>
            <Text style={styles.text}>
                4.8.2 некорректного использования Приёмного оборудования Абонентом либо его неисправности, в т.ч. по причине истечения установленного производителем срока службы Приёмного оборудования;
            </Text>
            <Text style={styles.text}>
                4.8.3 возникновения помех, затрудняющих приём сигнала, связанных с местом и условиями расположения Приёмного оборудования;
            </Text>
            <Text style={styles.text}>
                4.8.4 изменений, искажений, нарушений, перерывов или задержек распространения сигнала, вызванных природными явлениями (включая солнечную активность или неблагоприятные метеорологические условия);
            </Text>
            <Text style={styles.text}>
                4.8.5 иных обстоятельств непреодолимой силы (в том числе, но не ограничиваясь: выход из строя или нарушение нормальной работы искусственных спутников Земли и/или сетей связи, с использованием ресурсов которых Оператор оказывает Услуги, несанкционированный доступ третьих лиц к системам и ресурсам, используемым для оказания Услуг);
            </Text>
            <Text style={styles.text}>
                4.8.6 предоставление Абонентом недостоверных сведений о себе при заключении Договора с Оператором;
            </Text>
            <Text style={styles.text}>
                4.8.7 получения Абонентом Услуг с нарушением норм и требований законодательства Российской Федерации, а также условий Договора;
            </Text>
            <Text style={styles.text}>
                4.8.8 невыполнения или ненадлежащего (в том числе несвоевременного) выполнения Абонентом действий, предусмотренных производителем программного обеспечения, встроенного в Приёмное оборудование или производителем Приёмного оборудования, в части модернизации, обновления программного обеспечения, встроенного в Приёмное оборудование.
            </Text>

        </View>
    )
}