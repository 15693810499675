import {Text, View} from "@react-pdf/renderer"
import React from "react"

export function ParagraphOne({styles}) {
    return (
        <View style={styles.paragraph}>
            <Text style={styles.title}>1. ОСНОВНЫЕ ПОЛОЖЕНИЯ.</Text>
            <Text style={styles.text}>
                1.1. Настоящий Договор является публичным и может быть принят Абонентом не иначе как путем присоединения
                к нему в целом.
            </Text>
            <Text style={styles.text}>
                1.2. Неотъемлемой частью Договора являются Правила оказания услуг, которые постоянно размещены на Сайте
                – rusmirtv.ru.
            </Text>
            <Text style={styles.text}>
                1.3. Договор вступает в силу и становится обязательным для Сторон с момента его подписания Сторонами,
                действует неопределенный срок до момента его расторжения или прекращения оказания соответствующих Услуг
                Оператором.
            </Text>
        </View>
    )
}
