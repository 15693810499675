import React, {useEffect} from "react"
import NewFilters from "../../../components/NewFilters/NewFilters"
import {useIntl} from "react-intl"
import moment from "moment"
import addData from "../../../assets/images/common/addData.svg"
import {observer} from "mobx-react-lite"
import DatesStore from "../../../stores/DatesStore"
import {getComp} from "../../../utils/utils/DI"
import {MenuItem} from "@mui/material"
import DataSettingsFilterChip from "./DataSettingsFilterChip"
import {getYears} from "../../../helpers/getYears"
const NewDataSettingsFilters = ({filterStore, actionButtonProps, openDataSettingsDrawer, store}) => {
    const intl = useIntl()
    const {getMonthName} = new DatesStore()

    useEffect(() => {
        filterStore.setSelectedFilters()
    }, [])

    const filtersData = [
        [
            {
                type: "select",
                propName: "dealer",
                name: intl.formatMessage({id: "Дилеры"}),
                collection: store?.updatedDealers,
                setMenuItem: (data) => <MenuItem value={data}>{data.displayName}</MenuItem>
            },
            [
                {
                    type: "select",
                    propName: "reportMonth",
                    name: intl.formatMessage({id: "Месяц"}),
                    setMenuItem: (data) => <MenuItem value={data}>{getMonthName(data, false, true)}</MenuItem>,
                    collection: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                },
                {
                    type: "select",
                    propName: "reportYear",
                    name: intl.formatMessage({id: "Год"}),
                    placeholder: intl.formatMessage({id: "Год"}),
                    collection: getYears()
                }
            ]
        ]
    ]

    const handleSearch = () => {
        filterStore.setSelectedFilters()
        store.onSearch()
        return Promise.resolve()
    }

    const handleDeleteChip = (propName, multiselectValue) => {
        filterStore.deleteFilter(propName, multiselectValue)
        handleSearch()
    }
    const handleClear = () => {
        filterStore.clear()
        store.onSearch()
    }

    const setChip = (params, withAction) => (
        <DataSettingsFilterChip {...params} onClick={withAction && handleDeleteChip} />
    )
    return (
        <NewFilters
            totalCollectionLength={store.selectedCollection.length}
            filtersStore={filterStore}
            filtersData={filtersData}
            onSearch={handleSearch}
            setChip={setChip}
            actionButtonProps={
                actionButtonProps || {
                    name: intl.formatMessage({id: "Ввести данные"}),
                    onClick: openDataSettingsDrawer,
                    icon: addData
                }
            }
            onClear={handleClear}
            title={intl.formatMessage(
                {
                    id: "Результатаы поиска {orderValue}",
                    defaultMessage: "Результаты поиска {orderValue}"
                },
                {
                    orderValue: store.totalSize
                }
            )}
        />
    )
}

export default observer(NewDataSettingsFilters)
