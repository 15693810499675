import React from 'react'
import StatisticsActivityTable from './components/StatisticsActivityTable'
import StatisticsActivityFilters from './components/StatisticsActivityFilters'

function StatisticsActivity() {
  return (
    <div>
      <StatisticsActivityFilters /> 
      <StatisticsActivityTable />  </div>
  )
}

export default StatisticsActivity