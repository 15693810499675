import React from "react"
import {observer} from "mobx-react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import {getStyles} from "../../../utils/utils/commonUtils"
import mainMenuItemStyles from "../styles/mainMenuItemStyles"

import {useTheme} from "@mui/material"
import {useLocation, useNavigate} from "react-router-dom"

function MainMenuItem({name, route, handleClick, toggleMenu}) {
    const theme = useTheme()
    const navigate = useNavigate()
    const location = useLocation()
    const classes = getStyles(mainMenuItemStyles)

    const active = route === location.pathname ? true : false

    const onPageChange = () => {
        navigate(route)
        toggleMenu()
    }
    return (
        <Box onClick={handleClick || onPageChange} sx={{width: "100%", height: "100%"}}>
            <Typography
                component={"div"}
                color={theme.palette.gray.light}
                sx={{
                    width: "100%",
                    height: "100%",
                    padding: "12px 20px 12px 64px",
                    backgroundColor: active ? theme.palette.blue.menuSelected : "transparent",
                    fontSize: "18px",
                    fontWeight: 400,
                    ...classes.menuLink
                }}
            >
                {name}
            </Typography>
        </Box>
    )
}

export default observer(MainMenuItem)
