import {Box, IconButton, Typography} from "@mui/material"
import React from "react"
import photoGalleryDialogStyles from "./styles/photoGalleryDialogStyles"
import {getStyles} from "../../utils/utils/commonUtils"
import plusLupe from "../../assets/images/photoTools/plusLupe.svg"
import minusLupe from "../../assets/images/photoTools/minusLupe.svg"
import {hand} from "../../assets/images/photoTools/hand.js"
import {isAdmin} from "../../services/AccountService"
import downloadBlue from "../../assets/images/photoTools/downloadBlue.svg"
const PhotoTools = ({setZoom, setMoveMode, moveMode, zoom, downloadFunc}) => {
    const classes = getStyles(photoGalleryDialogStyles)
    return (
        <Box sx={classes.photoTools}>
            <IconButton
                sx={{
                    ...classes.toolWrapper,
                    cursor: zoom ? "default" : "pointer",
                    opacity: zoom ? 0.2 : 1
                }}
                onClick={() => setZoom(true)}
            >
                <img src={plusLupe} alt="plus lupe" />
            </IconButton>

            <IconButton
                sx={{
                    ...classes.toolWrapper,
                    cursor: zoom ? "pointer" : "default",
                    opacity: zoom ? 1 : 0.2
                }}
                onClick={() => setZoom(false)}
            >
                <img src={minusLupe} alt="minus lupe" />
            </IconButton>

            <IconButton
                onClick={() => {
                    if (zoom) {
                        setMoveMode(!moveMode)
                    }
                }}
                sx={{
                    ...classes.toolWrapper,
                    cursor: zoom ? "pointer" : "default",
                    opacity: zoom ? 1 : 0.2,
                    background: moveMode ? "#1199FF" : null,
                    color: moveMode ? "white" : "#1199FF",
                    "&:hover": {
                        background: moveMode ? "#1199FF" : null,
                        color: moveMode ? "white" : "#1199FF"
                    }
                }}
            >
                {hand}
            </IconButton>

            {isAdmin() && (
                <IconButton
                    onClick={downloadFunc}
                    sx={{
                        ...classes.toolWrapper,
                        cursor: "pointer"
                    }}
                >
                    <img alt="download" src={downloadBlue} />
                </IconButton>
            )}
        </Box>
    )
}

export default PhotoTools
